import {useEffect} from 'react'

import {useLocation, useNavigate} from 'react-router-dom'

function Redirect() {
    const history = useNavigate()

    const redirect = useLocation().pathname
    
    useEffect(() => {
        if(redirect){
            history('/login', { state: { redirect: redirect } })
        }else{
            history('/login')
        }
    }, [])
    
    return (
        <div></div>
    )
}

export default Redirect