import { HiOutlineDocumentSearch } from "react-icons/hi"; 
import { AiOutlinePushpin } from "react-icons/ai"; 
import React, {useState, useEffect} from 'react'
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"

import {FaChevronDown, FaFilePdf, FaGripVertical} from 'react-icons/fa'
import {FiTrash2, FiPlus, FiSave, FiMoreVertical} from 'react-icons/fi'
import { BiPlus } from 'react-icons/bi'

import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import {Impressao} from '../services/customPDF'

import api from '../services/api'
import Swal from 'sweetalert2'
import ReactLoading from 'react-loading'

import NoImg from "../assets/sem_foto.png"

import { Container, Row, Col, Table, Button, Dropdown, Form, Modal, FloatingLabel, CloseButton } from 'react-bootstrap'

import { Menu as Backdrop, Divider, ListItemIcon, ListItemText, MenuItem, Typography } from '@mui/material'

import { useTranslation } from 'react-i18next'

function ExportPDF(props) {
    const { t } = useTranslation();

    const [value, setValue] = useState(true); // integer state

    const [idExport, setIdExport] = useState('0')
    const [moduloExport, setModuloExport] = useState(props.modulo)
    const [itemsFromBackend, setItemsFromBackend] = useState(returnList(props.headers))
    const [listItens, setListItens] = useState(props.listItens)
    const [fotosCompl, setFotoCompl] = useState(props.listFotosCompl)

    const [loading, setLoading] = useState(false)

    const [columnsFromBackend, setColumnsFromBackend] = useState({
        ['columns_0']: {
            name: `${t('export.info')}`,
            collumnName:'',
            items: itemsFromBackend
        },
        ['columns_1']: {
            name: `${t('export.column')} 1 ${t('export.on_PDF')}`,
            collumnName:'',
            items: []
        },
        ['columns_2']: {
            name: `${t('export.column')} 2 ${t('export.on_PDF')}`,
            collumnName:'',
            items: []
        },
        ['columns_3']: {
            name: `${t('export.column')} 3 ${t('export.on_PDF')}`,
            collumnName:'',
            items: []
        }
    })
    
    const [columns, setColumns] = useState(columnsFromBackend)
    const [showFtc, setShowFtc] = useState('false')
    const [headerTitle, setHeaderTitle] = useState('')
    const [headerDescription, setHeaderDescription] = useState('')
    const [headerFoto, setHeaderFoto] = useState(NoImg)
    const [orientation, setOrientation] = useState('portrait')
    
    const [signatures, setSignatures] = useState({})

    const [qtdToLoad, setQtdToLoad] = useState(0)
    const [qtdLoaded, setQtdLoaded] = useState(0)
    const [modelList, setModelList] = useState([])
    const [modelIndex, setModelIndex] = useState(0)
    const [removeZeros, setRemoveZeros] = useState(false)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openMenu = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [insertDate, setInsertdate] = useState(false)
    const [insertSelectedfilter, setinsertSelectedFilter] = useState(false)
    const [insertNivel1, setInsertNivel1] = useState(false)
    const [insertNivel2, setInsertNivel2] = useState(false)
    const [insertNivel3, setInsertNivel3] = useState(false)
    const [insertNivel4, setInsertNivel4] = useState(false)
    const [showLines, setShowLines] = useState(false)

    function handleSelectModel(index){
        setModelIndex(index)
        handleClose()
        setHeaderTitle(modelList[index].titulo)
        setHeaderDescription(modelList[index].descricao)
        setHeaderFoto(modelList[index].foto == '' ? NoImg : modelList[index].foto)
        setOrientation(modelList[index].orientacao)
        setShowFtc(modelList[index].show_ftc ? modelList[index].show_ftc : 'false')
        setRemoveZeros(modelList[index]?.remove_zeros ? modelList[index]?.remove_zeros : false)
        setColumns(modelList[index].colunas)
        setSignatures(modelList[index].assinaturas ? modelList[index].assinaturas : {})
    }

    function handleCreateModel(){
        const newModel = {
            titulo: '',
            descricao: '',
            foto: '',
            orientacao: 'portrait',
            show_ftc: 'false',
            remove_zeros: false,
            colunas: columnsFromBackend,
            assinaturas: {}
        }
        setModelList(prevArray => [...prevArray, newModel])
        setHeaderTitle(newModel.titulo)
        setHeaderDescription(newModel.descricao)
        setHeaderFoto(newModel.foto === '' ? NoImg : newModel.foto)
        setOrientation(newModel.orientacao)
        setShowFtc(newModel.show_ftc)
        setRemoveZeros(newModel.remove_zeros)
        setColumns(newModel.colunas)
        setSignatures(newModel.assinaturas)
        setModelIndex(modelList.length)
    }

    function handleSelectDefault(){
        let list = modelList
        const modeldefault = modelList[modelIndex]
        list.splice(modelIndex, 1)
        list.unshift(modeldefault)
        setModelList(list)
        setModelIndex(0)
        saveProps()
    }

    function handleRemoveZeros(lista) {      
      
        if (removeZeros) {
            let index = props.modulo === 'itens' ? 4 : props.modulo.includes('inventarios') ? 2 : null
            if(index){
                const updatedList = lista.map(item => {
                const updatedItem = [...item];
                updatedItem[index] = String(parseInt(item[index]) > 0 ? parseInt(item[index]) : '');
                return updatedItem;
                });
                setListItens(updatedList); 
            }
        } else {
            setListItens(lista);
        }
    }
    

    useEffect(()=>{
        handleRemoveZeros(props.listItens)
    }, [removeZeros])
      
    useEffect(() => {
        getProps()
    }, [])

    function getProps() {
        setLoading(true)
        api.get(`/export_config/${sessionStorage.getItem('idConta')}/${props.modulo}/pdf`).then(
          result => {
            if(result.data.length) {
              setIdExport(result.data[0]._id)
              setModuloExport(result.data[0].modulo)
              const modelo = result.data[0].modelo.length != undefined ? result.data[0].modelo : [result.data[0].modelo]
              setModelList(modelo)
              setHeaderTitle(modelo[modelIndex].titulo)
              setHeaderDescription(modelo[modelIndex].descricao)
              setHeaderFoto(modelo[modelIndex].foto == '' ? NoImg : modelo[modelIndex].foto)
              setOrientation(modelo[modelIndex].orientacao)
              setShowFtc(modelo[modelIndex].show_ftc ? modelo[modelIndex].show_ftc : 'false')
              setRemoveZeros(modelo[modelIndex]?.remove_zeros ? modelo[modelIndex]?.remove_zeros : false)
              setColumns(modelo[modelIndex].colunas)
              setSignatures(modelo[modelIndex].assinaturas ? modelo[modelIndex].assinaturas : {})
            }
            forceUpdate()
            setLoading(false)
          }
        )
    }

    function handleSaveModel(){
        modelList[modelIndex] = {
            titulo: headerTitle,
            descricao: headerDescription,
            foto: headerFoto == NoImg ? '' : headerFoto,
            orientacao: orientation,
            show_ftc: showFtc,
            remove_zeros: removeZeros,
            colunas: columns,
            assinaturas: signatures ? signatures : {}
        }
        saveProps()
    }


    function saveProps() {
    Swal.fire({
        title: "Atenção !",
        text: idExport == '0' ? "Deseja realmente salvar este modelo ?" : "Deseja realmente alterar este modelo ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Sim",
        cancelButtonText: "Não"
    }).then(
        result => {
        if(result.isConfirmed) {
            setLoading(true)
            let option = {
            headers: { 'Content-Type': ['application/json'] }
            }
            let reg = [
            {
                id_conta: sessionStorage.getItem('idConta'),
                tipo: 'pdf',
                modulo: moduloExport,
                modelo: modelList,
            }
            ]
            if(idExport != '0') {
            reg[0]._id = idExport
            }
            api.post('/export_config', reg, option).then(
            result => {
                setIdExport(result.data[0]._id)
                Swal.fire({
                title: "Sucesso !",
                text: 'Modelo foi salvo em sua base',
                icon: 'success',
                showConfirmButton: false,
                timerProgressBar: true,
                timer: '2500'
                })
                setLoading(false)
            },
            result => {
                Swal.fire({
                title: "Ops... !",
                text: 'Algo deu errado tente novamente mais tarde!!',
                icon: 'error',
                showConfirmButton: false,
                timerProgressBar: true,
                timer: '2500'
                })
                setLoading(false)
            }
            )
        }
        }
    )}

    function handleremoveProps() {
        Swal.fire({
            title: "Atenção !",
            text: "Deseja realmente excluir este modelo ?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Sim",
            cancelButtonText: "Não"
        }).then(
            result => {
                if(result.isConfirmed) {
                    const newList = [...modelList]
                    newList.splice(modelIndex, 1)   
                    if(modelList.length === 1){
                        removeProps()
                    }else{
                        let option = { headers: { 'Content-Type': ['application/json'] }}
                        let reg = [{
                            id_conta: sessionStorage.getItem('idConta'),
                            tipo: 'pdf',
                            modulo: moduloExport,
                            modelo: newList,
                        }]
                        if(idExport != '0') {
                            reg[0]._id = idExport
                        }
                        api.post('/export_config', reg, option).then(
                            result => {
                                setIdExport(result.data[0]._id)
                                const modelo = result.data[0].modelo.length != undefined ? result.data[0].modelo : [result.data[0].modelo]
                                setModelList(modelo)
                                setHeaderTitle('')
                                setHeaderDescription('')
                                setHeaderFoto(NoImg)
                                setOrientation('portrait')
                                setShowFtc('false')
                                setColumns(columnsFromBackend)
                                setSignatures({})
                                Swal.fire({
                                title: "Sucesso !",
                                text: 'Modelo foi excluido com sucesso',
                                icon: 'success',
                                showConfirmButton: false,
                                timerProgressBar: true,
                                timer: '2500'
                                })
                                setLoading(false)
                            },
                            result => {
                                Swal.fire({
                                title: "Ops... !",
                                text: 'Algo deu errado tente novamente mais tarde!!',
                                icon: 'error',
                                showConfirmButton: false,
                                timerProgressBar: true,
                                timer: '2500'
                                })
                                setLoading(false)
                            }
                        )
                    }
                }
                
            }
        )
    }

    function removeProps() {
        api.delete(`/export_config/${sessionStorage.getItem('idConta')}/${idExport}`).then(
            result => {
            setIdExport('0')
            setModuloExport(props.modulo)
            setModelList([])
            setHeaderTitle('')
            setHeaderDescription('')
            setHeaderFoto(NoImg)
            setOrientation('portrait')
            setShowFtc('false')
            setColumns(columnsFromBackend)
            setSignatures({})
            forceUpdate()
            Swal.fire({
                title: "Sucesso !",
                text: 'Modelo foi excluido de sua base',
                icon: 'success',
                showConfirmButton: false,
                timerProgressBar: true,
                timer: '2500'
            })
            }
        )
    }

    function forceUpdate(){
        setValue(!value); // update the state to force render
    }

    function returnList(itemsList) {
        return itemsList.map((item, index) => {
            return { id: `item_${index}`, content: item, index }
        })
    }
    
    function compare( a, b ) {
        if ( a.index < b.index ){
            return -1
        }
        if ( a.last_nom > b.last_nom ){
            return 1
        }
        return 0
    }
    
    function addCollumn() {
        let colunas = columns

        let qtd = Object.entries(colunas).length

        colunas[`columns_${qtd}`] = {
            name: `Coluna ${qtd} no PDF`,
            collumnName:'',
            items: []
        }

        setColumns(colunas)
        forceUpdate()
    }

    function removeColumn(column) {
        let colunas = columns

        colunas['columns_0'].items = [...colunas['columns_0'].items, ...colunas[column].items]

        colunas['columns_0'].items.sort(compare)

        delete colunas[column]

        let output = {}
        Object.entries(colunas).map(([columnId, column], index) => {
            output[`columns_${index}`] = {
                name: index == 0 ? `${t('export.info')}` : `Coluna ${index} no PDF`,
                collumnName: column.collumnName,
                items: column.items
            }
        })

        setColumns(output)
        forceUpdate()
    }

    function addSignature() {
        let fields = signatures

        let qtd = Object.entries(fields).length

        fields[`signature_${qtd}`] = {
            signatureName: '',
            signatureExtra: '',
        }

        setSignatures(fields)
        forceUpdate()
    }

    function removeSignature(sig) {
        let assinaturas = signatures

        delete assinaturas[sig]

        let output = {}
        Object.entries(assinaturas).map(([sigId, sig], index) => {
            output[`signature_${index}`] = {
                signatureName: sig.signatureName,
                signatureExtra: sig.signatureExtra,
            }
        })

        setSignatures(output)
        forceUpdate()
    }

    function changeSignatureById(sigId, sigText, type) {
        let assinaturas = signatures

        if(type=='name') {
            assinaturas[sigId].signatureName = sigText
        }else if(type=='extra') {
            assinaturas[sigId].signatureExtra = sigText
        }

        setSignatures(assinaturas)
        forceUpdate()
    }

    function changeColumnById(columnId, columnText) {
        let colunas = columns

        colunas[columnId].collumnName = columnText

        setColumns(colunas)
        forceUpdate()
    }

    function changeImage(file) {
        var reader = new FileReader()
        reader.readAsDataURL(file)

        reader.onloadend = function(e, file) {
            var b64 = this.result;

            setHeaderFoto(b64)
        }
    }
    
    function returnChangeImage(url) {
        var reader = new FileReader()
        reader.re(url)

        return reader.onloadend = function(e, file) {
            return this.result;
        }
    }

    const onDragEnd = (result, columns, setColumns) => {
        if (!result.destination) return;
        const { source, destination } = result;
    
        if (source.droppableId !== destination.droppableId) {
            const sourceColumn = columns[source.droppableId];
            const destColumn = columns[destination.droppableId];
            const sourceItems = [...sourceColumn.items];
            const destItems = [...destColumn.items];
            const [removed] = sourceItems.splice(source.index, 1);
            destItems.splice(destination.index, 0, removed);
            setColumns({
                ...columns,
                [source.droppableId]: {
                ...sourceColumn,
                items: sourceItems
                },
                [destination.droppableId]: {
                ...destColumn,
                items: destItems
                }
            });
        } else {
            const column = columns[source.droppableId];
            const copiedItems = [...column.items];
            const [removed] = copiedItems.splice(source.index, 1);
            copiedItems.splice(destination.index, 0, removed);
            setColumns({
                ...columns,
                [source.droppableId]: {
                ...column,
                items: copiedItems
                }
            });
        }
    }

    function getAllImagesBase64() {
        return new Promise((resolve, reject) => {
            api.get(`/img/${sessionStorage.getItem('idConta')}`).then((result) => {
                resolve(result.data)
            }).catch((err) => {
                resolve([])
            })
        })
    }

    function getLine() {
        let line =''
        for(let i=0; i<=(orientation == 'portrait' ? 250 : 360); i++) {
            line += '_'
        }
        return line
    }
    
    async function PreparaDocumento(pdfSignatures, hItems, pdfFilter, linkeres) {
    let line = getLine()
    
    
    const corpoDocumento = await CriaCorpoDocumento(hItems, pdfFilter, linkeres);
    //let documento = []
    //for(let i= 0; i < Math.ceil(corpoDocumento.length/1000); i++) {
    //    documento.push(await GerarDocumento(corpoDocumento.slice(i, (i+1) * 1000), line,pdfSignatures, hItems));
    //}
    const documento = await GerarDocumento(corpoDocumento, line,pdfSignatures, hItems)
    return documento;
    }

    async function CriaCorpoDocumento(hItems, pdfFilter, linkeres) {
    const header = await hItems.map(item =>{
        return { text: item, bold: true, fontSize: 9, margin: [0, 0, 0, 0] }
    })
    let body = []
    await pdfFilter.map(async (prod, indexo) => {
        let arr = await prod.map((column, indexc) => {
            return column.map((itemC, index) => {
            if(itemC && itemC.toString().includes(process.env.REACT_APP_API_URL)){
                if(linkeres[`images_${indexo}`].length > 500) {
                    return {image: linkeres[`images_${indexo}`], height: 35, width: 50}
                }else{
                    return { text: 'Foto Err', fontSize: 8 }
                }
            }else {
                return { text: itemC, fontSize: 8 }
            }
            })
        })
        body.push(arr)
        let result = []
        Object.keys(linkeres).filter((link) => (link.includes(`images_compl_${indexo}_`))).map((ftcpl) => {
            if(result.length == 0 || result[result.length - 1].length == hItems.length){
                if(linkeres[ftcpl].length > 500) {
                    result[result.length] = [{image: linkeres[ftcpl], height: 35, width: 50}]
                }else{
                    result[result.length] = [{ text: 'Foto Err', fontSize: 8 }]
                }
            }else{
                if(linkeres[ftcpl].length > 500) {
                    result[result.length - 1] = [...result[result.length - 1],{image: linkeres[ftcpl], height: 35, width: 50}]
                }else{
                    result[result.length - 1] = [...result[result.length - 1],{ text: 'Foto Err', fontSize: 8 }]
                }
            }
        })
        if(result.length > 0 && showFtc == 'true') {
            if(result[result.length - 1].length < hItems.length) {
            result[result.length - 1] = hItems.map((d,i) => {
                if(result[result.length - 1][i]) {
                return result[result.length - 1][i]
                }else{
                return { text: '', fontSize: 8 }
                }
            })
            }
            result.map((linha) => {
                body.push(linha)
            })
        }
    });

    const lineHeader = [
        {
        text: getLine(),
        alignment: 'center',
        fontSize: 5,
        colSpan: hItems.length,
        }
    ];

    let content;
    if(showLines){
        content = [header]
    }else{
        content = [header, lineHeader]
    }
    content = [...content, ...body];
    return content;
    }

    async function GerarDocumento(corpoDocumento, line, pdfSignatures, hItems) {
        let data = ''
        let status = ''
        let locate = [];
        let locateBody = [];
        const bodyText = []

        if(props?.data){
            if(insertDate && props?.data?.date) {
                data = `Data: ${props.data.date}`
            }

            if(insertSelectedfilter && props?.data?.status) {
                status = `Status: ${props.data.status}`
            }

            for (let i = 1; i <= 4; i++) {
                const nivel = props?.data[`nivel${i}`];
                if (eval(`insertNivel${i} && nivel`)) {
                    locate.push(nivel);
                }
            }

            if (locate.length > 0){ 
                locateBody.push({
                    text: `Localização: ${locate[0]}`,
                    fontSize: 9,
                    bold: false,
                    alignment: 'justify',
                    margin: [0, 2, 0, 2],
                })

                for(let i = 1; i < locate.length; i++){
                    locateBody.push({
                        text: `${locate[i]}`,
                        fontSize: 9,
                        bold: false,
                        alignment: 'justify',
                        margin: [54, 2, 0, 2]
                    })
                }
            }

            bodyText.push([
                {
                    text: `${data}`,
                    fontSize: 9,
                    bold: false,
                    alignment: 'justify',
                    margin: [0, 4, 0, 4],
                },
                {
                    text: `${status}`,
                    fontSize: 9,
                    bold: false,
                    alignment: 'justify',
                    margin: [0, 4, 0, 4],
                }
            ])
    
            if(locateBody.length > 0){
                bodyText.push(locateBody)
            }

        }

        

        let widthsItems = hItems.map(() => {
            return "auto"
        })
        let logo = headerFoto != NoImg ? headerFoto : ''
        let marginPage = [14, 36, 14, 48]
        marginPage[1] = (logo == '' && headerDescription == '') ? 36 : 100
        marginPage[3] = pdfSignatures.length ? 100 : 48
        let fSignaturesList = pdfSignatures
        const documento = await {
        pageSize: 'A4',
        pageOrientation: orientation,
        pageMargins: marginPage, // change by desc
        header: function () {
        let bH = []
        if(logo != '') {
            bH.push(
            //{image: 'header_logo', height: 80, width: 80}
            {image: logo, height: 80, width: 80}
            )
        }
        let hT = []
        if(headerTitle != '') {
            hT.push({ text: headerTitle, style: 'reportName' })
        }
        if(headerDescription != '') {
            hT.push({ text: headerDescription, style: 'description',})
        }
        if(hT) {bH.push(hT)}
        //teste
        return {
            margin: [14, 12, 14, 0],
            layout: 'noBorders',
            table: {
                widths: (logo == '' ? ['*'] : [80, '*']),
                body: [bH],
            },
            };
        },
        content: [...bodyText, {
                layout: showLines ? '' : 'noBorders',
                margin: [0,18,0,0],
                table: {              
                headerRows: 1,
                widths: widthsItems,
                body: corpoDocumento
            }
        }],
        footer(currentPage, pageCount) {
        let listFooter = [
            {
            layout: 'noBorders',
            margin: [14, 0, 14, 22],
            table: {
                widths: ['auto'],
                body: [
                [
                    {
                    text: line,
                    alignment: 'center',
                    fontSize: 5,
                    },
                ],
                [
                    [
                    {
                        text: `Página ${currentPage.toString()} de ${pageCount}`,
                        fontSize: 7,
                        alignment: 'right',
                        /* horizontal, vertical */
                        margin: [3, 0],
                    },
                    {
                        text: '',//'Inovacode',
                        fontSize: 7,
                        alignment: 'center',
                    },
                    ],
                ],
                ],
            },
            }
        ]
        if(fSignaturesList.length) {
            listFooter.push(
            {
                layout: 'noBorders',
                margin: [14, 0, 14, 22],
                table: {
                widths: fSignaturesList.map(() => ('*')),
                body : [fSignaturesList.map((item, index) => {
                    let linesignatue = item.signatureName
                    let linename = ''
                    for(let i = 0;i < linesignatue.length; i++) {
                    linename += '__'
                    }
                    return ([
                    [
                        {text: linename, alignment: 'center', fontSize: 7},
                        {text: item.signatureName, alignment: 'center', fontSize: 7},
                        {text: item.signatureExtra, alignment: 'center', fontSize: 7}
                    ]
                    ])
                })],
                },
            }
            )
        }
        return listFooter
        },
    styles: {
        reportName: {
        fontSize: 9,
        bold: true,
        alignment: 'center',
        margin: [0, 4, 0, 0],
        },
        description: {
        fontSize: 9,
        bold: false,
        alignment: 'justify',
        margin: [0, 4, 0, 0],
        }
    },

    };
    return documento;
    }

    pdfMake.vfs = pdfFonts.pdfMake.vfs

    const visualizarImpressao = async () => {
        let pdfFilter = await listItens.map((item, index) => {
            return Object.entries(columns).map(([columnId, column], indexColumn) => {
                let content = []
                if(indexColumn == 0) {
                    content = [index + 1]
                }else {
                    content = column.items.map((itemC, indexItem) => {
                        return item[itemC.index]
                    })
                }
                return content
            })
        })

        let linkeres = {}

        const loadImage = () => {
            return new Promise(async (resolve, reject) => {

                let qtdImages = listItens.length
                if(moduloExport == 'itens' && showFtc == 'true') {
                    await fotosCompl.map((ftl) => {qtdImages += ftl.length})
                }
                setQtdToLoad(qtdImages)

                let countLoaded = 0
                for (let i= 0;i<listItens.length;i++) {
                    countLoaded += 1
                    setQtdLoaded(countLoaded)
                    if(listItens[i][listItens[i].length-1].includes(process.env.REACT_APP_API_URL)) {
                        if(!listItens[i][listItens[i].length -1].includes('data:image')){
                            await api.get(`/img/${listItens[i][listItens[i].length -1].toString().replaceAll((process.env.REACT_APP_API_URL) + '/', '').replace('.jpeg', '')}`).then((result) => {
                                linkeres[`images_${i}`] = 'data:image/jpeg;base64,' + result.data
                            })
                        }
                    }
                }

                if(moduloExport == 'itens' && showFtc == 'true') {
                    for (let i= 0;i<fotosCompl.length;i++) {
                        if(fotosCompl[i].length) {
                            for (let c= 0;c<fotosCompl[i].length;c++) {
                                countLoaded += 1
                                setQtdLoaded(countLoaded)
                                if(!fotosCompl[i][c].includes('data:image')) {
                                    await api.get(`/img/${fotosCompl[i][c].replace('http://3.138.64.4:8000/', '').replace('https://api.inovacode.app.br/', '').replace('.jpeg','')}`).then((result) => {
                                        linkeres[`images_compl_${i}_${c}`] = 'data:image/jpeg;base64,' + result.data
                                    })
                                }
                            }
                        }
                    }
                }

                setQtdToLoad(0)
                setQtdLoaded(0)
                resolve('ok')
            })
        }

        const apiGetRoute = async (i) => {
            try {
                let resultPromise;

                const foto_index = moduloExport === 'inventarios_itens' ? 24 : 0

        
                if (listItens[i][foto_index] && listItens[i][foto_index] !== 'Sem foto') {
                    resultPromise = api.get(`/imgItem/${listItens[i][0]}?indexQuery=${i}`);
                } else {
                    throw new Error('ID index not found in listItens');
                }
        
                const timeoutPromise = new Promise((_, reject) => {
                    setTimeout(() => {
                        reject(new Error('Timeout após 5 segundos'));
                    }, 5000); // 5000 milissegundos = 5 segundos
                });
        
                const result = await Promise.race([resultPromise, timeoutPromise]);
        
                linkeres = { ...linkeres, ...result.data };
                return 'ok';
            } catch (error) {
                console.error(`Erro ao carregar a imagem ${i}: ${error.message}`);
                // Tratar o erro aqui, se necessário
                return 'error'; // Returning 'error' or a specific error message if needed
            }
        };
        
          

        const loadImage2 = (jump = -1) => {
            return new Promise(async (resolve, reject) => {
                if(jump > -1) {
                    resolve('ok')
                }else{
                    let qtdImages = listItens.length
                    setQtdToLoad(qtdImages)
    
                    let countLoaded = 0
                    for (let i= 0;i<listItens.length;i++) {
                        countLoaded += 1
                        setQtdLoaded(countLoaded)
                        await apiGetRoute(i)
                    }
    
                    setQtdToLoad(0)
                    setQtdLoaded(0)
                    resolve('ok')
                }

            })
        }
        const items = Object.entries(columns)[0][1].items;

        // Find the index of the item based on the specified conditions
        let confImage = items.findIndex((item) => {
            const isNotItensModule = moduloExport !== 'itens' && moduloExport !== 'inventarios_itens';
            const isItensOrInventariosItensModuleWithFoto = 
                (moduloExport === 'itens' || moduloExport === 'inventarios_itens') &&
                item.content === 'FOTO' &&
                showFtc === 'false';
            return isNotItensModule || isItensOrInventariosItensModuleWithFoto;
        });

        if(!items.length){
            confImage = 0
        }

        await loadImage2(confImage).then(async() => {
            let pdfSignatures = Object.entries(signatures).map(([signatureId, signature], index) => {
                return {
                    signatureName: signature.signatureName,
                    signatureExtra: signature.signatureExtra
                }
            })

            let hItems = Object.entries(columns).map(([columnId, column], index) => {
                return index == 0 ? '#' : column.collumnName
            })

            const documento = await PreparaDocumento(pdfSignatures, hItems, pdfFilter,linkeres)
            pdfMake.createPdf(documento).open({}, window.open('', '_blank'));
        })

    }

    return (loading ?
        <div style={{width:'100%' }}>
            <div style={{display: 'flex', justifyContent: 'center', marginTop: '150px'}}>
                <ReactLoading type={'bars'} color={'#2488f7'} height={150} width={150}/>
            </div>
        </div>
        :
        <Col className="bg-white border rounded p-3">
            <Col className="d-flex justify-content-between p-3">
                <h3 className="fw-bold">{t('export.header')}</h3>
                <div>
                    <Button
                        className='me-2'
                        variant="light"
                        id="basic-button"
                        aria-controls={openMenu ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={openMenu ? 'true' : undefined}
                        onClick={handleClick}
                        
                    >
                        Modelos
                        <FaChevronDown className="ms-2"/>
                    </Button>
                    <Backdrop
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={openMenu}
                        onClose={handleClose}
                        MenuListProps={{
                        'aria-labelledby': 'basic-button',
                        }}
                    >
                        {modelList.length != 0 ? modelList.map((model, index) => (
                            <MenuItem className='select-info' onClick={e=>{handleSelectModel(index)}}>
                                <ListItemText sx={{ pr: 5}}>{model.titulo != '' ? model.titulo : `Modelo ${index + 1}`}</ListItemText>
                                <Typography variant="overline">
                                    {index === 0 ? 'DEFAULT' : ''}
                                </Typography>
                            </MenuItem>
                        )) : 
                            <MenuItem disabled={'true'}>
                                <ListItemIcon>
                                    <HiOutlineDocumentSearch />
                                </ListItemIcon>
                                <ListItemText> Nenhum modelo salvo </ListItemText>
                            </MenuItem>
                        }
                        <Divider/>
                        <MenuItem onClick={handleCreateModel}>
                            <ListItemIcon>
                                <BiPlus />
                            </ListItemIcon>
                            <ListItemText>Criar novo modelo</ListItemText>
                        </MenuItem>
                    </Backdrop>
                    <Button variant="light" onClick={e=> {handleSaveModel()}}><FiSave className="me-2"/>{t('actions.save')} {t('export.model')}</Button>
                    <Button variant="light" className="mx-2" onClick={e=> {handleremoveProps()}}><FiTrash2 className="me-2"/>{t('actions.delete')} {t('export.model')}</Button>
                    {qtdToLoad == 0?
                    <Button variant="light" onClick={e=> {visualizarImpressao()}}><FaFilePdf className="dIcons"/> {t('actions.generate')} PDF</Button>
                    :null}
                </div>
            </Col>
            <Row>
                <Col className="d-flex flex-column" sm={3}>
                    <input id='itemImage' style={{display: 'none'}} type='file' accept='image/*' onChange={e=> {
                        e.preventDefault()
                        if(e.target.files[0]) {
                            changeImage(e.target.files[0])
                        }
                    }}/>
                    <img 
                        className="object-fit-fill border rounded mx-auto"
                        style={{cursor: 'pointer',
                                width:'200px',
                                height:'230px'}}
                        src={headerFoto}
                        alt="itemImage"
                        onDragOver={e=> {e.preventDefault()}}
                        onDrop={e=> {
                            e.preventDefault()
                            if(e.dataTransfer.files[0].type.includes("image")){}
                                changeImage(e.dataTransfer.files[0])
                            }
                        }
                        onClick={e=> {
                            let getFile = window.document.getElementById('itemImage')
                            getFile.click()
                        }}
                    />
                    <Button
                        variant="light"
                        className="fw-normal mx-auto my-3"
                        onClick={e => {setHeaderFoto(NoImg)}}
                        style={{width:'200px'}}>
                        <FiTrash2 fontSize={20}/> {t('export.delete_image')}
                    </Button>
                </Col>
                <Col>
                    <Row className="g-3 p-3">
                        <Form.Group as={Col}>
                            <Form.Label>{t('export.title')}</Form.Label>
                            <Form.Control value={headerTitle} onChange={e=> {setHeaderTitle(e.target.value)}}/>
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>{t('export.orientation')}</Form.Label>
                            <Form.Select
                                    value={orientation}
                                    onChange={e=>{setOrientation(e.target.value)}}
                                >
                                    <option value={'portrait'}>{t('export.portrait')}</option>
                                    <option value={'landscape'}>{t('export.landscape')}</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>{t('export.adicional_image')}</Form.Label>
                            <Form.Select value={showFtc} onChange={e=>{setShowFtc(e.target.value)}}>
                                <option value={'false'}>{t('export.no')}</option>
                                <option value={'true'}>{t('export.yes')}</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group as={Col} sm={12}>
                            <Form.Label>{t('common.description')}</Form.Label>
                            <Form.Control type="text" as="textarea" rows={3} value={headerDescription} onChange={e=> {setHeaderDescription(e.target.value)}}/>
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Check
                                inline
                                label={t('common.default')}
                                name="group1"
                                type={'checkbox'}
                                checked={modelIndex === 0 ? true : false}
                                disabled={modelIndex === 0 ? true : false}
                                onChange={e=>{handleSelectDefault()}}
                            />
                        </Form.Group>
                        <Form.Group as={Col} sm={12}>
                            <Form.Check
                                inline
                                label={'Remover zeros a esquerda da Tag'}
                                checked={removeZeros}
                                onChange={e=>{setRemoveZeros(!removeZeros)}}
                            />
                        </Form.Group>
                        <Form.Group as={Col} sm={12}>
                            <Form.Check
                                inline
                                label={'Mostrar linhas nas tabelas'}
                                checked={showLines}
                                onChange={e=>{setShowLines(!showLines)}}
                            />
                        </Form.Group>
                    </Row>
                </Col>
            </Row>
            {props.modulo === 'inventarios_itens' ?
                <Col className="px-3">
                    <h3 className="m-0 fw-bold">{t('export.add_info')}</h3>
                    <p className="text-muted">Adicionar informações adicionais ao cabeçalho</p>
                    <Form.Group className="d-flex flex-column">
                        <Form.Check
                            inline
                            label='Data de leitura'
                            checked={insertDate}
                            onChange={e=>{setInsertdate(!insertDate)}}
                        />
                        <Form.Check
                            inline
                            label='Status (filtro)'
                            checked={insertSelectedfilter}
                            onChange={e=>{setinsertSelectedFilter(!insertSelectedfilter)}}
                        />
                        <Form.Check
                            inline
                            label='Nivel 1'
                            checked={insertNivel1}
                            onChange={e=>{setInsertNivel1(!insertNivel1)}}
                        />
                        <Form.Check
                            inline
                            label='Nivel 2'
                            checked={insertNivel2}
                            onChange={e=>{setInsertNivel2(!insertNivel2)}}
                        />
                        <Form.Check
                            inline
                            label='Nivel 3'
                            checked={insertNivel3}
                            onChange={e=>{setInsertNivel3(!insertNivel3)}}
                        />
                        <Form.Check
                            inline
                            label='Nivel 4'
                            checked={insertNivel4}
                            onChange={e=>{setInsertNivel4(!insertNivel4)}}
                        />
                    </Form.Group>
                </Col>
            : ''}
            <div style={{width:'100%'}}>
                <DragDropContext
                    onDragEnd={result => onDragEnd(result, columns, setColumns)}

                >
                    {Object.entries(columns).slice(0,1).map(([columnId, column], index) => {
                        return (
                            <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                width:'auto'
                            }}
                            key={columnId}
                            >
                            <div style={{display:'flex', flexDirection:'row'}}>
                                <h2>{column.name}</h2>
                            </div>
                            <div style={{ margin: 8 ,width: '100%'}}>
                                <Droppable droppableId={columnId} direction='horizontal' key={columnId}>
                                {(provided, snapshot) => {
                                    return (
                                    <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                        style={{
                                        display: 'flex',
                                        padding: 8,
                                        flexWrap: 'wrap',
                                        }}
                                    >
                                        {column.items.map((item, index) => {
                                        return (
                                            <Draggable
                                            key={item.id}
                                            draggableId={item.id}
                                            index={index}
                                            >
                                            {(provided, snapshot) => {
                                                return (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    className="shadow-sm bg-white"
                                                    style={{
                                                    userSelect: "none",
                                                    padding:'12px',
                                                    margin: "4px 2px",
                                                    whiteSpace: 'nowrap',
                                                    borderRadius: '3px',
                                                    border:'1px solid #e9ecf0',
                                                    borderLeft: snapshot.isDragging
                                                        ? "3px solid #2488f7"
                                                        : "1px solid #e9ecf0",
                                                    color: snapshot.isDragging
                                                    ? "var(--primary-color)"
                                                    : "var(--text-primary)",
                                                    ...provided.draggableProps.style
                                                    }}
                                                >
                                                    <FiMoreVertical className="me-3"/>
                                                    {item.content}
                                                </div>
                                                );
                                            }}
                                            </Draggable>
                                        );
                                        })}
                                        {provided.placeholder}
                                    </div>
                                    );
                                }}
                                </Droppable>
                            </div>
                            </div>
                        )
                    })}
                    <Col className='py-3 d-flex'>
                        <h3>{t('export.column')}</h3>
                        <Button variant="custom-primary" className="ms-3" onClick={e=> {addCollumn()}}><FiPlus/>{t('export.add_column')}</Button>
                    </Col>
                    <Row >
                        {Object.entries(columns).slice(1).map(([columnId, column], index) => {
                            return (
                                <Col sm={2} className="m-3 border rounded py-3"
                                key={columnId}
                                >
                                    <h5>{column.name}</h5>
                                    <Col className='d-flex justify-content-between py-1'>
                                        <input value={column.collumnName} className="w-100" style={{height:'36px'}} onChange={e=> {changeColumnById(columnId, e.target.value)}}/>
                                        <Button variant="dark" className="ms-2 h-100" style={{fontSize:'16px'}} onClick={e=> {removeColumn(columnId)}}><FiTrash2/></Button>
                                    </Col>
                                    
                                    <div style={{width: 'auto', overflowX: 'auto'}}>
                                        <Droppable droppableId={columnId} direction='vertical' key={columnId}>
                                        {(provided, snapshot) => {
                                            return (
                                            <div
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                                style={{
                                                display: 'flex',
                                                flexDirection:'column',
                                                overflowX: 'auto',
                                                minHeight: '240px',
                                                background: snapshot.isDraggingOver
                                                    ? "var(--bg-light)"
                                                    : "#fff"
                                                }}
                                            >
                                                {column.items.map((item, index) => {
                                                return (
                                                    <Draggable
                                                    key={item.id}
                                                    draggableId={item.id}
                                                    index={index}
                                                    >
                                                    {(provided, snapshot) => {
                                                        return (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            className="shadow-sm bg-white fw-bold"
                                                            style={{
                                                            userSelect: "none",
                                                            padding: 8,
                                                            whiteSpace: 'nowrap',
                                                            marginBlock:'5px',
                                                            border:'1px solid #e9ecf0',
                                                            borderLeft: "3px solid var(--primary-color)",
                                                            color: 'var(--primary-color)',
                                                            ...provided.draggableProps.style
                                                            }}
                                                        >
                                                            <FiMoreVertical className="me-3"/>
                                                            {item.content}
                                                        </div>
                                                        );
                                                    }}
                                                    </Draggable>
                                                );
                                                })}
                                                {provided.placeholder}
                                            </div>
                                            );
                                        }}
                                    </Droppable>
                                    </div>
                                </Col>
                            )
                        })}
                    </Row>
                </DragDropContext>
            </div>
            <Col sm={8}>
                <Col className="d-flex mt-5">
                    <h3>{t('export.signature')}</h3>
                    <Button variant="custom-primary" className="ms-3" onClick={e=> {addSignature()}}><FiPlus/> {t('export.signature')}</Button>
                </Col>
                <div>
                    <div className="list_account">
                        {Object.entries(signatures).map(([sigId, sig], index) => (
                            <div style={{display:"flex", flexDirection:'row'}}>
                                <div style={{display:"flex", flexDirection:'column'}}>
                                    <label>{t('export.signature_name')}{index + 1}</label>
                                    <input style={{Width: '800 px'}} value={sig.signatureName} onChange={e=> {changeSignatureById(sigId, e.target.value, 'name')}}/>
                                </div>
                                <div style={{display:"flex", flexDirection:'column'}}>
                                    <label>{t('export.signature_info')}{index + 1}</label>
                                    <input style={{Width: '800 px'}} value={sig.signatureExtra} onChange={e=> {changeSignatureById(sigId, e.target.value, 'extra')}}/>
                                </div>
                                <Button variant="outline-dark"  className='border-0' onClick={e=> {removeSignature(sigId)}}><FiTrash2/></Button>
                            </div>
                        ))}
                    </div>
                </div>
            </Col>
            
            {qtdToLoad > 0?
                <div className="export" style={{flexDirection:"column"}}>
                    <p style={{width:'100%', textAlign:'center'}}><b>{qtdLoaded} de {qtdToLoad} itens carregados</b></p>
                    <input type={'range'} value={qtdLoaded} min='0' max={qtdToLoad} readOnly></input>
                </div>
            :null}
        </Col>
    )
}

export default ExportPDF