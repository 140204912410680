import { useLocation, useNavigate } from 'react-router-dom'

import React, {useState, useEffect} from 'react'

import { Container, Grid2, InputLabel, MenuItem, Paper, Select, Stack, Typography, Button, TextField, Table, TableHead, TableRow, TableCell, TableBody, Checkbox, ListItemText, OutlinedInput, Chip, Box } from '@mui/material'

import { Icon } from '@iconify/react/dist/iconify.js'

import { t } from 'i18next'

import Dropdown from '../../../components/ui/Dropdown'

import api from '../../../services/api'

import Loading from '../../../components/ui/Loading'

import Alert from '../../../components/ui/Alert'

const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 48 * 4.5 + 8,
        width: 250,
      },
    },
};

export default function GroupDetails() {
    const navigate = useNavigate();

    const location = useLocation();

    const [loading, setLoading] = useState(false)

    const [message, setMessage] = useState('')

    // Fields
    const [details, setDetails] = useState([])

    const [id, setID] = useState('')

    const [descricao, setDescricao] = useState('')

    const [leitores, setLeitores] = useState([])

    // Lists 
    const [leitoresList, setLeitoresList] = useState([])

    useEffect(()=>{
        function fetchDetails(){
            const reader = location.state?.reader
            if(reader){
                setDetails(reader)

                const { 
                    _id, 
                    descricao, 
                    leitores
                } = reader

                setID(_id)
                setDescricao(descricao)
                const readers = []
                leitores.map((item) => {
                    readers.push(item.id_leitor)
                });
                setLeitores(readers || [])
            }
        }

        fetchDetails()
    }, [])

    useEffect(()=>{
        function getReaders() {
            setLoading(true)
            api.get(`/leitor/${sessionStorage.getItem('idConta')}/*/*/*/*`
            ).then(
                response => {
                    const validReaders = response.data.filter(item=>item?._id)
                    setLeitoresList(validReaders)
                },
                error => {
                }
            ).finally(
                () => {
                    setLoading(false)
                }
            )
        }

        getReaders()
    }, [])

    function handleSave(){
        const uploadLeitores = leitores.map((item) => ({
            id_leitor: item
        }));
        const newOrder = [{
            ...details,
            id_conta: sessionStorage.getItem('idConta'),
            descricao: descricao,
            leitores: uploadLeitores     
        }]
        if(id){
            newOrder._id = id
        }
        let option = {
            headers: { 'Content-Type': ['application/json'] }
        }

        if(!leitores.length){
            console.log('error')
            console.log(uploadLeitores)
            return
        }

        api.post('/agrupamentoLeitor', newOrder, option
        ).then(
            response => {
                setMessage({
                    "type": "success",
                    "message": t('messages.success_save'),
                    "title": t('messages.success')
                })
            },
            response => {
                setMessage({
                    "type": "error",
                    "message": t('messages.errors.500'),
                    "title": t('messages.errors.error')
                })
            }
        )
    }

    function handleDelete(){
        let option = {
            headers: { 'Content-Type': ['application/json'] }
        }
        api.delete(`/agrupamentoLeitor/${id}`, option
        ).then(
            response => {
                setMessage({
                    "type": "success",
                    "message": t('messages.success_delete'),
                    "title": t('messages.success'),
                    "redirect": '/readers'
                })
            },
            error => {
                setMessage({
                    "type": "error",
                    "message": t('messages.errors.500'),
                    "title": t('messages.errors.error')                    
                })
            }
        )
    }

    const handleChange = (event) => {
        const {
          target: { value },
        } = event;
        setLeitores(
          // On autofill we get a stringified value.
          typeof value === 'string' ? value.split(',') : value,
        );
    };

    return (
        <>
            <Container maxWidth="lg">
                <Stack spacing={3}>

                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Typography variant="h1">{t('common.readers')}</Typography>
                        <Stack direction="row" spacing={1}>
                            <Button variant="outlined" color="dark" onClick={()=>{navigate('/readers',{state: { redirect: 'groups' }})}}>{t('actions.exit')}</Button>
                            {id ? <Button variant="outlined" color="dark" onClick={()=>{handleDelete()}} startIcon={<Icon icon='solar:trash-bin-minimalistic-linear'/>}>{t('actions.delete')}</Button> : ""}
                            <Button variant="contained" color="error" onClick={()=>{handleSave()}} startIcon={<Icon icon='mingcute:save-2-line'/>}>{t('actions.save')}</Button>
                        </Stack>
                    </Stack>

                    <Stack spacing={3} as={Paper} sx={{borderRadius: 3, p: 3}}>
                        <Grid2 container spacing={2}>
                            <Grid2 size={{xs: 12}}>
                                <Typography>{t('common.name')}</Typography>
                                <TextField id="name_reader" size="small" fullWidth value={descricao} onChange={e=>{setDescricao(e.target.value)}}/>
                            </Grid2>
                            <Grid2 size={{xs: 12}}>
                                <Typography>{t('common.readers')}</Typography>
                                <Select
                                    multiple
                                    size="small"
                                    fullWidth
                                    value={leitores}
                                    onChange={handleChange}
                                    input={<OutlinedInput label={t('common.readers')} />}
                                    renderValue={(selected) => (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                            {selected.map((value) => (
                                                <Chip key={value} label={leitoresList.find(item => item?._id == value)?.nm_leitor} />
                                            ))}
                                        </Box>
                                    )}
                                    MenuProps={MenuProps}
                                >
                                    {leitoresList.map((name) => (
                                        <MenuItem key={name} value={name._id}>
                                            <Checkbox checked={leitores.includes(name._id)} />
                                            <ListItemText primary={name.nm_leitor} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Grid2>
                        </Grid2>
                    </Stack>
                </Stack>
            </Container>

            {message ? 
                <Alert
                    type={message.type}
                    title={message.title}
                    message={message.message}
                    onClose={e=>{
                        message?.redirect ? navigate('/readers',{state: { redirect: 'groups' }}) : setMessage(e)
                    }}
                />
            : ''}
        </>
    )   
}