import React, {useState, useEffect} from 'react'

import { useNavigate } from 'react-router-dom'

import { Container, Grid2, InputLabel, MenuItem, Paper, Select, Stack, Typography, Button, TextField, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material'

import { Icon } from '@iconify/react/dist/iconify.js'

import { t } from 'i18next'

import Dropdown from '../../components/ui/Dropdown'

import api from '../../services/api'

import Loading from '../../components/ui/Loading'

export default function ReaderList() {
    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)

    //Filtros
    const [filterName, setFilterName] = useState("")

    const [filterSerial, setFilterSerial] = useState("")

    const [filterAtivo, setFilterAtivo] = useState(1)

    //Lista
    const [readers, setReaders] = useState([])

    useEffect(()=>{
        getReaders()
    }, [])

    function getReaders() {
        setLoading(true)
        const filterbyname = filterName && filterName.length ? filterName : '*';
        const filterbyserial = filterSerial && filterSerial.length ? filterSerial : '*';
        api.get(`/leitor/${sessionStorage.getItem('idConta')}/*/${filterbyname}/${filterbyserial}/${filterAtivo || "*"}`
        ).then(
            response => {
                const validReaders = response.data.filter(item=>item?._id)
                setReaders(validReaders)
            },
            error => {
            }
        ).finally(
            () => {
                setLoading(false)
            }
        )
    }

    function handleSelect(reader){
        navigate('/readers/details/' + reader._id, { state: { reader } });
    }

    function handleExport(type){
        const listItens = readers.map((item, index) => {
            return [
                item.nm_leitor ? item.nm_leitor : '',
                item.serial ? item.serial : '',
                item.ativo ? item.ativo : '',
                item.reg_inventario ? item.reg_inventario : ''
            ]
        })
        
        const info = {
            headers: ["NOME", "SERIAL", "ATIVO", "REGISTRO"],
            list: listItens, 
            module: 'leitores', 
            type: type, 
            redirectTo: '/readers'
        }
        navigate('/export', { state: { info } });
    }

    return (
        <>
            
            <Stack direction="row" justifyContent="end" spacing={1}>
                <Dropdown color="dark" text={`${t('actions.generate')} ${t('common.report')}`}>
                    <MenuItem onClick={e=>{handleExport('PDF')}}>PDF</MenuItem>
                    <MenuItem onClick={e=>{handleExport('CSV')}}>CSV</MenuItem>
                </Dropdown>
                <Button variant="contained" color="dark" startIcon={<Icon icon='heroicons:plus-16-solid'/>} onClick={e=>{navigate('/readers/details')}}>{t('actions.create')}</Button>
            </Stack>

            <Stack spacing={1} sx={{borderRadius: 1, p: 3, backgroundColor: "primary.lighter"}}>
                <Grid2 container spacing={3}>
                    <Grid2 item size={{xs: 12, md: 4}}>
                        <Typography>{t('common.name')}</Typography>
                        <TextField
                            size="small"
                            value={filterName}
                            onChange={e=> {setFilterName(e.target.value)}} 
                            fullWidth
                        />
                    </Grid2>
                    <Grid2 item size={{xs: 12, md: 4}}>
                        <Typography>Serial</Typography>
                        <TextField
                            size="small"
                            value={filterSerial}
                            onChange={e=> {setFilterSerial(e.target.value)}} 
                            fullWidth
                        />
                    </Grid2>
                    <Grid2 item size={{xs: 12, md: 4}}>
                        <Typography>{t('common.active')}</Typography>
                        <Select
                            size="small"
                            value={filterAtivo}
                            onChange={e=> {setFilterAtivo(e.target.value)}} 
                            fullWidth
                        >
                            <MenuItem value={""}>{t('common.all')}</MenuItem>
                            <MenuItem value={"1"}>{t('common.yes')}</MenuItem>
                            <MenuItem value={"0"}>{t('common.no')}</MenuItem>
                        </Select>
                    </Grid2>
                </Grid2>
                <Grid2 container justifyContent="end">
                    <Button variant="contained" onClick={()=>{getReaders()}}>{t('actions.search')}</Button>
                </Grid2>
            </Stack>

            <Table>
                <TableHead>
                    <TableRow className="fw-bold text-uppercase">
                        <TableCell></TableCell>
                        <TableCell>{t('common.name')}</TableCell>
                        <TableCell>Serial</TableCell>
                        <TableCell>Registro</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {readers.map((reader, index) => (
                        <TableRow onClick={e=>{handleSelect(reader)}}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>{reader.nm_leitor}</TableCell>
                            <TableCell>{reader.serial}</TableCell>
                            <TableCell>{reader.reg_inventario}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

            <Loading show={loading}/>
        </>
    )   
}