import React, {useState, useEffect} from 'react'

import { Container, Grid2, InputLabel, MenuItem, Paper, Select, Stack, Typography, Button, TextField, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material'

import { Icon } from '@iconify/react/dist/iconify.js'

import { t } from 'i18next'

import Loading from '../../components/ui/Loading'

import ReaderList from './ReaderList'

import GroupList from './GroupReaders/GroupList'

import { useLocation } from 'react-router-dom'

export default function Readers() {
    const location = useLocation();

    const [render, setRender] = useState(location?.state?.redirect || 'readers')

    return (
        <>
            <Container>
                <Stack spacing={3}>

                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Typography variant="h1">{render === 'groups' ? "Grupo de" : ""} {t('common.readers')}</Typography>
                        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
                            <Button 
                                variant="outlined" 
                                color="dark" 
                                startIcon={<Icon icon="material-symbols-light:barcode-reader-outline" />} 
                                onClick={() => {setRender( render === 'readers' ? 'groups' : 'readers')}}
                            > 
                            {render === 'groups' ? "" : "Grupo "} 
                            {t('common.readers')}
                            </Button>
                            <Button variant="outlined" color="dark" startIcon={<Icon icon="lets-icons:search-light" />} onClick={() => {window.open('https://gds.inovacode.app.br/auth/'+sessionStorage.getItem('idConta'))}}>{t('actions.track')} {t('common.readers')}</Button>
                        </Stack>
                    </Stack>

                    <Stack spacing={3} as={Paper} sx={{borderRadius: 3, p: 3}}>
                        {render === "readers" ? 
                            <ReaderList/>
                        : 
                            <GroupList/>
                        }
                    </Stack>
                </Stack>
            </Container>
        </>
    )   
}