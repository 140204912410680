import { BrowserRouter } from 'react-router-dom';

import Router from './routes';

import Theme from './theme';

import AlertDialog from './components/AlertDialog';

function App() {
  return (
      <BrowserRouter>
        <Theme>
          <Router />
          <AlertDialog/>
        </Theme>
      </BrowserRouter>
  );
}

export default App;
