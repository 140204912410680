import React, {useState, useEffect} from 'react'
import ReactLoading from 'react-loading'

import Swal from 'sweetalert2'

import {GoogleMap, useLoadScript, Marker, InfoWindow} from '@react-google-maps/api'
import {FaFileCsv, FaFilePdf, FaCheck, FaMapMarkedAlt} from 'react-icons/fa'
import {FiArrowLeft, FiFilter} from 'react-icons/fi'
import {AiOutlineArrowUp, AiOutlineArrowDown} from 'react-icons/ai'
import {ImCompass2} from 'react-icons/im'
import {GoLocation} from 'react-icons/go'

//
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import {Impressao} from '../../services/pdImage'
import {CSVLink} from 'react-csv'
//

import api from '../../services/api'
import Menu from '../../components/Menu'

import './style.css'
import mapStyle from '../../mapstyle'

import NoImg from "../../assets/sem_foto.png"

import ExportCSV from '../../components/exportCSV'
import ExportPDF from '../../components/exportPDF'
import DashTest from '../../components/dashOnlyValueTest'

import { Container, Row, Col, Table, Button, Dropdown, Form, Modal, FloatingLabel, CloseButton, DropdownButton, ButtonGroup } from 'react-bootstrap'

import { useTranslation } from 'react-i18next'

import { Icon } from '@iconify/react/dist/iconify.js'
import { Autocomplete, TextField, Typography } from '@mui/material'

const libraries = ['places']
const mapContainerStyle = {
    width: '100%',
    height: '600px'
}
const optionsMap = {
    //styles: mapStyle
}

function Collects() {
    const { t } = useTranslation();

    const [vinculadosList, setVinculadosList] = useState([])
    const [items, setItems] = useState([])
    const [dataInicio, setDataInicio] = useState(dates(7, '-'))//(dates(31, '-'))
    const [dataFim, setDataFim] = useState(dates(1, '+'))
    const [identificador, setIdentificador] = useState('*')
    const [parceiroList, setParceiroList] = useState([])
    const [leitorList, setLeitorList] = useState([])
    const [parceiro, setParceiro] = useState('*')
    const [reader, setReader] = useState('*')
    const [showHidden, setShowHidden] = useState(false)
    const [showTestes, setShowTestes] = useState(false)
    const [changeNew, setChangeNew] = useState(0)
    const [idRegistro, setIdRegistro] = useState('0')
    const [tipoRegistro, setTipoRegistro] = useState('')
    const [modoColeta, setModoColeta] = useState('p')
    const [identificadorColeta, setIdentificadorColeta] = useState('')
    const [statusColeta, setStatusColeta] = useState('')
    const [qtdEstimada, setQtdEstimada] = useState(0)
    const [encontradoColeta, setEncontradoColeta] = useState('')
    const [excedenteColeta, setExcedenteColeta] = useState('')
    const [dtRegistro, seDtRegistro] = useState(now())
    const [dtAlteracao, setDtAlteracao] = useState(now())
    const [tempoInventario, setTempoInventario] = useState('')
    const [nivelLoc1List, setNivelLoc1List] = useState([])
    const [nivelLoc2List, setNivelLoc2List] = useState([])
    const [nivelLoc3List, setNivelLoc3List] = useState([])
    const [nivelLoc4List, setNivelLoc4List] = useState([])
    const [nivelLoc1ListColeta, setNivelLoc1ListColeta] = useState([])
    const [nivelLoc2ListColeta, setNivelLoc2ListColeta] = useState([])
    const [nivelLoc3ListColeta, setNivelLoc3ListColeta] = useState([])
    const [nivelLoc4ListColeta, setNivelLoc4ListColeta] = useState([])
    const [nivelLoc1Coleta, setNivelLoc1Coleta] = useState('*')
    const [nivelLoc2Coleta, setNivelLoc2Coleta] = useState('*')
    const [nivelLoc3Coleta, setNivelLoc3Coleta] = useState('*')
    const [nivelLoc4Coleta, setNivelLoc4Coleta] = useState('*')
    const [nivelLoc1, setNivelLoc1] = useState('*')
    const [nivelLoc2, setNivelLoc2] = useState('*')
    const [nivelLoc3, setNivelLoc3] = useState('*')
    const [nivelLoc4, setNivelLoc4] = useState('*')
    const [parceiroColeta, setParceiroColeta] = useState('*')
    const [eanColeta, setEanColeta] = useState('')
    const [observacaoColeta, setObservacaoColeta] = useState('')
    const [latitude, setLatitude] = useState('-23.485230351504622')
    const [longitude, setLongitude] = useState('-46.72570559924746')
    const [foto, setFoto] = useState(NoImg)
    const [pedidoListItens, setPedidoListItens] = useState([])
    const [pedidoFilter, setPedidoFilter] = useState("Todas")
    const [tagListItens, setTagListItens] = useState([])
    const [tagFilter, setTagFilter] = useState("Todas")
    const [alertNew ,setAlertNew] = useState('')
    const [inputNew, setInputNew] = useState('')
    const [change, setChange] = useState(0)
    const [editing, setEditing] = useState(0)
    const [reverse, setReverse] = useState(false)
    const [leitorColeta, setLeitorColeta] = useState('0')
    const [leitorColetaInfo, setLeitorColetaInfo] = useState(null)
    const [usuarioColeta, setUsuarioColeta] = useState('0')
    const [tipoList, setTipoList] = useState('registro')
    const [showMap, setShowMap] = useState(false)
    const [markList, setMarkList] = useState([])
    const [selectedMark, setSelectedMark] = useState(null)
    const [pageNumber, setPageNumber] = useState(0)
    const [exportList, setExportList] = useState([])
    const [exportListSelected, setExportListSelected] = useState([])
    const [itList, setItList] = useState([])
    const [itemsLoaded, setItemsLoaded] = useState(false)
    const [exportLoaded, setExportedLoaded] = useState(true)
    const [details, setDetails] = useState([])
    const [itemDetails, setItemDetails] = useState([])
    const [pedidoLoaded, setPedidoLoaded] = useState(true)
    const [listItemsLoaded, setListItemsLoaded] = useState(true)
    const [listExportLoaded, setListExportLoaded] = useState(true)

    const [ordenationExport, setOrdenationExport] = useState('')
    const [listaFilterExport, setListaFilterExport] = useState([
        'Ausente',
        'Encontrado',
        'Excedente',
        'Outro_Ambiente',
        'Auditado',
        'Incorporado'
    ])

    const [categoriaNew, setCategoriaNew] = useState('')
    const [tagNew, setTagNew] = useState('')
    const [ativoNew, setAtivoNew] = useState('1')
    const [inf1New, setInf1New] = useState('')
    const [inf2New, setInf2New] = useState('')
    const [inf3New, setInf3New] = useState('')
    const [inf4New, setInf4New] = useState('')
    const [inf5New, setInf5New] = useState('')
    const [nivelLoc1New, setNivelLoc1New] = useState('')
    const [nivelLoc2New, setNivelLoc2New] = useState('')
    const [nivelLoc3New, setNivelLoc3New] = useState('')
    const [nivelLoc4New, setNivelLoc4New] = useState('')
    const [nivelLoc1NewList, setNivelLoc1NewList] = useState([])
    const [nivelLoc2NewList, setNivelLoc2NewList] = useState([])
    const [nivelLoc3NewList, setNivelLoc3NewList] = useState([])
    const [nivelLoc4NewList, setNivelLoc4NewList] = useState([])
    const [parceiroNew, setParceiroNew] = useState('')
    const [observacaoNew, setObservacaoNew] = useState('')
    const [codExternoNew, setCodExternoNew] = useState('')
    const [categoriaList, setCategoriaList] = useState([])
    const [fotoUp, setFotoUp] = useState('')
    const [fotoToUp, setFotoToUp] = useState(false)
    const [infoNamesList, setInfoNamesList] = useState([])
    const [nivelLabelList, setNivelLabelList] = useState([
        `${t('common.level')} 1` ,
        `${t('common.level')} 2` ,
        `${t('common.level')} 3` ,
        `${t('common.level')} 4` ,
    ])

    const [statusList, setStatusList] = useState(
        [
            { name: 'Ausentes', value: 0 },
            { name: 'Encontrados', value: 0 },
            { name: 'Excedentes', value: 0 },
            { name: 'Outros Amb.', value: 0 },
            { name: 'Auditados', value: 0 },
            { name: 'Incorporados', value: 0 }
        ]
    )
    const [dashItens, setDashItens] = useState([])
    const colorInfo = [
        '#B40C0C',
        '#4EA90B',
        '#A33BBF',
        '#E3AE1B',
        '#08B08C',
        '#26C6DA'
    ]

    const [exportFilterItem, setExportFilterItem] = useState('Todas')
    
    const [looper, setLooper] = useState(false)
    
    const fotoNone = ['', '/static/media/sem_foto.b83884cf.png', 'assets/imgs/sem_foto.png']
    
    const [exportType, setExportType] = useState(-1)
    const [headersCSV, setHeadersCSV] = useState([])

    function getHeader(){
        const header = [
            [
                "ID",
                "CATEGORIA",
                "TAG",
                "COD_EXTERNO",
                (infoNamesList[0] && infoNamesList[0] != '' ? infoNamesList[0] : 'Inf. Complentar 1').toUpperCase(),
                (infoNamesList[1] && infoNamesList[1] != '' ? infoNamesList[1] : 'Inf. Complentar 2').toUpperCase(),
                (infoNamesList[2] && infoNamesList[2] != '' ? infoNamesList[2] : 'Inf. Complentar 3').toUpperCase(),
                (infoNamesList[3] && infoNamesList[3] != '' ? infoNamesList[3] : 'Inf. Complentar 4').toUpperCase(),
                (infoNamesList[4] && infoNamesList[4] != '' ? infoNamesList[4] : 'Inf. Complentar 5').toUpperCase(),
                "ITEM_NIVEL_1",
                "ITEM_NIVEL_2",
                "ITEM_NIVEL_3",
                "ITEM_NIVEL_4",
                "OBSERVAÇÃO",
                "STATUS",
                "DT_LEITURA",
                "ITEM_LATITUDE",
                "ITEM_LONGITUDE",
                "IDENTIFICADOR",
                "USUÁRIO",
                "LEITOR",
                "REG_NIVEL_1",
                "REG_NIVEL_2",
                "REG_NIVEL_3",
                "REG_NIVEL_4",
                "REG_LATITUDE",
                "REG_LONGITUDE",
                "REG_DT_REGISTRO",
                "REG_DT_ALTERAÇÃO",
                "FOTO"
            ],
            [
                "CATEGORIA",
                "EAN",
                "QUANTIDADE",
                "LIDO",
                "AUDITADO",
                "STATUS",
                "OBSERVAÇÃO"
            ],
            [
                "ID",
                "CATEGORIA",
                "TAG",
                "COD_EXTERNO",
                "INF_COMPL_1",
                "INF_COMPL_2",
                "INF_COMPL_3",
                "INF_COMPL_4",
                "INF_COMPL_5",
                "NIVEL1",
                "NIVEL2",
                "NIVEL3",
                "NIVEL4",
                "OBSERVAÇÃO",
                "STATUS",
                "DT_LEITURA",
                "LATITUDE",
                "LONGITUDE",
                "FOTO"
            ]
        ]
        setHeadersCSV(header)
    }

    useEffect(() => {
        const interval = setInterval(() => {
                getLoop()
            }, 5000)
            return () => clearInterval(interval)
    }, [looper])

    function getLoop() {
        if(looper){
            registroLoopGet()
        }
    }

    useEffect(() => {
        if(change != 0) {
            setLooper(false)
        }
    }, [change])

    useEffect(() => {
        getInfoNames()
    }, [])

    function addOrRemoveExportFilter(status) {
        if(status == 'Todos') {
            setListaFilterExport([
                'Ausente',
                'Encontrado',
                'Excedente',
                'Outro_Ambiente',
                'Auditado',
                'Incorporado'
            ])
        }else{
            let lista = listaFilterExport
            let index = lista.findIndex((filter) => filter == status)
            if(index < 0) {
                lista.push(status)
            }else{
                lista.splice(index,1)
            }
            setListaFilterExport(lista.filter((f) => {return f}))
        }
    }

    function getInfoNames() {
        api.get(`/conta_config/${sessionStorage.getItem('idConta')}`).then(
            response => {
                let data = response.data[0] ? response.data[0] : []
                let lista = [
                    data.info_compl_1 && data.info_compl_1 != '' ? data.info_compl_1 : '' ,
                    data.info_compl_2 && data.info_compl_2 != '' ? data.info_compl_2 : '' ,
                    data.info_compl_3 && data.info_compl_3 != '' ? data.info_compl_3 : '' ,
                    data.info_compl_4 && data.info_compl_4 != '' ? data.info_compl_4 : '' ,
                    data.info_compl_5 && data.info_compl_5 != '' ? data.info_compl_5 : '' ,
                    data.info_compl_6 && data.info_compl_6 != '' ? data.info_compl_6 : '' ,
                    data.info_compl_7 && data.info_compl_7 != '' ? data.info_compl_7 : '' ,
                    data.info_compl_8 && data.info_compl_8 != '' ? data.info_compl_8 : '' ,
                    data.info_compl_9 && data.info_compl_9 != '' ? data.info_compl_9 : '' ,
                    data.info_compl_10 && data.info_compl_10 != '' ? data.info_compl_10 : '' 
                ]
                setInfoNamesList(lista)

                const nivelLabel = [
                    data.nivel_1 && data.nivel_1 != '' ? data.nivel_1 : `${t('common.level')} 1` ,
                    data.nivel_2 && data.nivel_2 != '' ? data.nivel_2 : `${t('common.level')} 2` ,
                    data.nivel_3 && data.nivel_3 != '' ? data.nivel_3 : `${t('common.level')} 3` ,
                    data.nivel_4 && data.nivel_4 != '' ? data.nivel_4 : `${t('common.level')} 4` ,
                ]
                setNivelLabelList(nivelLabel)
            }
        )
        getHeader()
    }

    function ordenateListBy(filterList, data) {
        //console.log(filterList)
        let dataSorted = data
        if(filterList.length > 0 && filterList[0] != '') {
            dataSorted = dataSorted.sort((a,b) => {
                if(filterList.length == 1) {
                    if((a[filterList[0]].length < b[filterList[0]].length)) return -1
                    if((a[filterList[0]].length == b[filterList[0]].length) && (a[filterList[0]] < b[filterList[0]])) return -1
                    if((a[filterList[0]].length == b[filterList[0]].length) && (a[filterList[0]] > b[filterList[0]])) return 1
                    if((a[filterList[0]].length > b[filterList[0]].length)) return 1
                    return 0
                }else if(filterList.length == 4) {
                    if((a[filterList[0]].length < b[filterList[0]].length)) return -1
                    else if((a[filterList[0]].length > b[filterList[0]].length)) return 1
                    else if((a[filterList[0]].length == b[filterList[0]].length) && (a[filterList[0]] < b[filterList[0]])) return -1
                    else if((a[filterList[0]].length == b[filterList[0]].length) && (a[filterList[0]] > b[filterList[0]])) return 1
                    else{
                        if((a[filterList[1]].length < b[filterList[1]].length)) return -1
                        else if((a[filterList[1]].length > b[filterList[1]].length)) return 1
                        else if((a[filterList[1]].length == b[filterList[1]].length) && (a[filterList[1]] < b[filterList[1]])) return -1
                        else if((a[filterList[1]].length == b[filterList[1]].length) && (a[filterList[1]] > b[filterList[1]])) return 1
                        else{
                            if((a[filterList[2]].length < b[filterList[2]].length)) return -1
                            else if((a[filterList[2]].length > b[filterList[2]].length)) return 1
                            else if((a[filterList[2]].length == b[filterList[2]].length) && (a[filterList[2]] < b[filterList[2]])) return -1
                            else if((a[filterList[2]].length == b[filterList[2]].length) && (a[filterList[2]] > b[filterList[2]])) return 1
                            else{
                                if((a[filterList[3]].length < b[filterList[3]].length)) return -1
                                else if((a[filterList[3]].length > b[filterList[3]].length)) return 1
                                else if((a[filterList[3]].length == b[filterList[3]].length) && (a[filterList[3]] < b[filterList[3]])) return -1
                                else if((a[filterList[3]].length == b[filterList[3]].length) && (a[filterList[3]] > b[filterList[3]])) return 1
                                else if((a[filterList[3]].length == b[filterList[3]].length) && (a[filterList[3]] == b[filterList[3]])) return 0
                            }
                        }
                    }
                    return 0
                }
                return 0
            })
        }
        return dataSorted
    }

    function registroLoopGet() {
        if(looper) {
            if(tipoList == 'registro') {
                api.get(`/registroPage/${sessionStorage.getItem('idConta')}/*/coleta/${dataInicio}/${dataFim}/${identificador || '*'}/${parceiro}/${reader}/*/${pageNumber * 30}?excView=${showHidden}&testeCheck=${showTestes}`, {}
                ).then(response => {
                    setItems(response.data)
                }).finally(()=>{
                    if(reverse){
                        setItems(items.reverse())
                    }
                })
            }else if(tipoList == 'item') {
                let tots = {}
                let ids = []
                api.get(`/registroPage/${sessionStorage.getItem('idConta')}/*/coleta/${dataInicio}/${dataFim}/${identificador || '*'}/${parceiro}/*/*/${pageNumber * 30}`, {}
                ).then(response => {
                    tots = response.data[response.data.length - 1]
                    ids = response.data.filter((item) => {
                        if(item._id) {
                            return item
                        }
                    }).map((item) => {
                        return item._id
                    })
                }).finally(
                    () => {
                        api.get(`/registro_tag_date/${sessionStorage.getItem('idConta')}/${dataInicio}/${dataFim}`, {}
                        ).then(subResponse => {
                            setItems([...subResponse.data.filter((item, index) => {
                                if(subResponse.data.length - 1 == index) {
                                }
                                if(ids.includes(item.id_registro)) {
                                    return item
                                }
                            }), tots])
                        })
                    }
                )
            }
        }
    }

    function markersLoader(itensList) {
        window.scroll(0, 0)
        let preList = itensList.filter((item) => {
            if(item.longitude) {
                return item
            }
        })
        if(preList.length == 0) {
            window.alert('Itens não possuem localização')
            
        }else {
            setMarkList(preList)
            setShowMap(true)
        }
    }
    
    function markersUnloader() {
        setMarkList([])
        setShowMap(false)
        setSelectedMark(null)
    }

    useEffect(() => {
        parceiroGet()
    }, [])

    useEffect(() => {
        LeitoresGet()
    }, [])

    useEffect(() => {
        nivel1Get()
    }, [])

    useEffect(() => {
        nivel2Get(nivelLoc1Coleta)
    }, [nivelLoc1Coleta])

    useEffect(() => {
        nivel3Get(nivelLoc2Coleta)
    }, [nivelLoc2Coleta])
    
    useEffect(() => {
        nivel4Get(nivelLoc3Coleta)
    }, [nivelLoc3Coleta])

    useEffect(() => {
        setItems([])
        setReverse(false)
        registroGet()
    }, [dataInicio ,dataFim, parceiro, tipoList, reader, pageNumber, showHidden, showTestes, nivelLoc1, nivelLoc2, nivelLoc3, nivelLoc4, identificador])

    useEffect(() => {
        nivel1GetList()
    }, [])

    useEffect(() => {
        nivel2GetList(nivelLoc1)
    }, [nivelLoc1])

    useEffect(() => {
        nivel3GetList(nivelLoc2)
    }, [nivelLoc2])
    
    useEffect(() => {
        nivel4GetList(nivelLoc3)
    }, [nivelLoc3])
    
    useEffect(() => {
        nivel1GetListNew()
    }, [])
    
    useEffect(() => {
        nivel2GetListNew(nivelLoc1New)
    }, [nivelLoc1New])
    
    useEffect(() => {
        nivel3GetListNew(nivelLoc2New)
    }, [nivelLoc2New])
    
    useEffect(() => {
        nivel4GetListNew(nivelLoc3New)
    }, [nivelLoc3New])
    
    useEffect(() => {
        if(change == 5) {
            categoriaGet()
        }
    }, [change])
    
    const {isLoaded, loadError} = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_API_G_KEY,
        libraries,
    })

    if(loadError) {return (
        <div>
            Error loading maps
        </div>
    )}
    if(!isLoaded) {return (
        <></>
    )}

    function registroGet() {
        setItemsLoaded(false)
        if(tipoList == 'registro') {
            api.get(`/registroPage/${sessionStorage.getItem('idConta')}/*/coleta/${dataInicio}/${dataFim}/${identificador || '*'}/${parceiro}/${reader}/*/${pageNumber * 30}?excView=${showHidden}&testeCheck=${showTestes}${nivelLoc1 && nivelLoc1 != '*' ? '&id_nivel_loc1=' + nivelLoc1 : ''}${nivelLoc2 && nivelLoc2 != '*' ? '&id_nivel_loc2=' + nivelLoc2 : ''}${nivelLoc3 && nivelLoc3 != '*' ? '&id_nivel_loc3=' + nivelLoc3 : ''}${nivelLoc4 && nivelLoc4 != '*' ? '&id_nivel_loc4=' + nivelLoc4 : ''}`, {}
            ).then(response => {
                setItems(response.data)
                setItemsLoaded(true)
            })
        }else if(tipoList == 'item') {
            let tots = {}
            let ids = []
            api.get(`/registroPage/${sessionStorage.getItem('idConta')}/*/coleta/${dataInicio}/${dataFim}/${identificador || '*'}/${parceiro}/*/*/${pageNumber * 30}`, {}
            ).then(response => {
                tots = response.data[response.data.length - 1]
                ids = response.data.filter((item) => {
                    if(item._id) {
                        return item
                    }
                }).map((item) => {
                    return item._id
                })
            }).finally(
                () => {
                    api.get(`/registro_tag_date/${sessionStorage.getItem('idConta')}/${dataInicio}/${dataFim}`, {}
                    ).then(subResponse => {
                        setItems([...subResponse.data.filter((item, index) => {
                            if(subResponse.data.length - 1 == index) {
                                setItemsLoaded(true)
                            }
                            if(ids.includes(item.id_registro)) {
                                return item
                            }
                        }), tots])
                    })
                }
            )
        }
    }

    function getExportList() {
        getHeader()
        setListExportLoaded(false)
        let lista = []
        api.get(`/registro/${sessionStorage.getItem('idConta')}/*/*/${dataInicio}/${dataFim}/*/${parceiro}/*`
        ).then(
            response => {
                response.data.map((item, index) => {
                    if(item.modo == 'p' || item.modo == 'l') {
                        if(
                            (nivelLoc1 == '*' || item.id_nivel_loc_1 && item.id_nivel_loc_1._id == nivelLoc1)
                            && (nivelLoc2 == '*' || item.id_nivel_loc_2 && item.id_nivel_loc_2._id == nivelLoc2)
                            && (nivelLoc3 == '*' || item.id_nivel_loc_3 && item.id_nivel_loc_3._id == nivelLoc3)
                            && (nivelLoc4 == '*' || item.id_nivel_loc_4 && item.id_nivel_loc_4._id == nivelLoc4)
                            && (identificador == '*' || item.identificador.search(identificador) > -1)
                        ){
                            lista.push(item)
                        }
                    }
                    if(response.data.length - 1 == index){
                        setExportList(lista)
                    }
                })
            }
        ).finally(
            ()=> {setListExportLoaded(true)}
        )
        setChange(4)
    }
    
    function addOrRemoveListImport(index) {
        if(exportListSelected.indexOf(index) == -1) {
            setExportListSelected([...exportListSelected, index])
        }else{
            let lindex = exportListSelected.indexOf(index)
            setExportListSelected([...(exportListSelected.slice(0, lindex)), ...(exportListSelected.slice(lindex + 1, exportListSelected.length))])
        }
    }

    function removeAlltoList() {
        setExportListSelected([])
    }

    function addAllToList() {
        setExportListSelected(exportList.map((item) => {
            return item._id
        }))
    }

    function selectedIndex(index) {
        return exportListSelected.includes(index)
    }

    function loadItensToFile() {
        setExportedLoaded(false)
        api.get(`/registro_tag_date/${sessionStorage.getItem('idConta')}/${dataInicio}/${dataFim}`
        ).then(response => {
            setItList([...response.data.filter((item, index) => {
                if(exportListSelected.includes(item.id_registro)) {
                    return item
                }
            })])
        }).finally(() => {
            setExportedLoaded(true)
        })
    }

    function reverseReg() {
        setReverse(!reverse)
        setItems(items.reverse())
    }

    function dates(days, direction) {
        let dt   = new Date()
        if(direction == '+') {
            dt = new Date(dt.getTime() + (days * 24 * 60 * 60 * 1000))
        }
        else if(direction == '-') {
            dt = new Date(dt.getTime() - (days * 24 * 60 * 60 * 1000))
        }
        return (dt.getFullYear() + "-" + ("0" + (dt.getMonth() + 1)).slice(-2) + "-" + ("0" + dt.getDate()).slice(-2))
    }

    function now() {
        let dt = new Date()
        dt.setHours(dt.getHours() - 3)
        let dt_reg = dt.getFullYear() + "-" + ("0" + (dt.getMonth() + 1)).slice(-2) + "-" + ("0" + dt.getDate()).slice(-2)
        dt_reg += " " + ("0" + dt.getHours()).slice(-2) + ":" + ("0" +dt.getMinutes()).slice(-2) + ":" + ("0" +dt.getSeconds()).slice(-2)
        return dt_reg
    }

    function formatDate(data) {
        let dt = new Date(data)
        let dt_reg = ("0" + dt.getDate()).slice(-2) + "-" + ("0" + (dt.getMonth() + 1)).slice(-2) + "-" + dt.getFullYear()
        dt_reg += " " + ("0" + dt.getHours()).slice(-2) + ":" + ("0" +dt.getMinutes()).slice(-2) + ":" + ("0" +dt.getSeconds()).slice(-2)
        return <th><div>{dt_reg.slice(0,10)}</div><div>{dt_reg.slice(11,19)}</div></th>
    }

    function formatDatecsv(data) {
        let dt = new Date(data)
        let dt_reg = ("0" + dt.getDate()).slice(-2) + "-" + ("0" + (dt.getMonth() + 1)).slice(-2) + "-" + dt.getFullYear()
        dt_reg += " " + ("0" + dt.getHours()).slice(-2) + ":" + ("0" +dt.getMinutes()).slice(-2) + ":" + ("0" +dt.getSeconds()).slice(-2)
        return dt_reg
    }

    function nivel1Get() {
        api.get(`/nivel_loc1/${sessionStorage.getItem('idConta')}/*/*/*/*`, {}
        ).then(response => {setNivelLoc1ListColeta(response.data)})
    }
    function nivel2Get(nivel1) {
        if(nivel1 != "*") {
            api.get(`/nivel_loc2/${sessionStorage.getItem('idConta')}/${nivel1}/*/*/*/*`, {}
            ).then(response => {setNivelLoc2ListColeta(response.data)})
        }else {
            setNivelLoc2ListColeta([])
        }
    }
    function nivel3Get(nivel2) {
        if(nivel2 != "*") {
            api.get(`/nivel_loc3/${sessionStorage.getItem('idConta')}/${nivel2}/*/*/*/*`, {}
            ).then(response => {setNivelLoc3ListColeta(response.data)})
        }else {
            setNivelLoc3ListColeta([])
        }
    }
    function nivel4Get(nivel4) {
        if(nivel4 != "*") {
            api.get(`/nivel_loc4/${sessionStorage.getItem('idConta')}/${nivel4}/*/*/*/*`, {}
            ).then(response => {setNivelLoc4ListColeta(response.data)})
        }else {
            setNivelLoc4ListColeta([])
        }
    }
    function parceiroGet() {
        api.get(`/parceiro/${sessionStorage.getItem('idConta')}/*/*/*/*`, {}
        ).then(response => {setParceiroList(response.data)})
    }

    function LeitoresGet() {
        api.get(`/leitor/${sessionStorage.getItem('idConta')}/*/*/*/*`
        ).then(
            response => {
                setLeitorList(response.data)
        })
    }

    function nivel1GetList() {
        api.get(`/nivel_loc1/${sessionStorage.getItem('idConta')}/*/*/*/*`, {}
        ).then(response => {setNivelLoc1List(response.data)})
    }

    function nivel2GetList(nivel1) {
        if(nivel1 != "*" && nivel1 != '') {
            api.get(`/nivel_loc2/${sessionStorage.getItem('idConta')}/${nivel1 != '' ? nivel1 : '*'}/*/*/*/*`, {}
            ).then(response => {setNivelLoc2List(response.data)})
        }else {
            setNivelLoc2List([])
        }
    }

    function nivel3GetList(nivel2) {
        if(nivel2 != "*" && nivel2 != '') {
            api.get(`/nivel_loc3/${sessionStorage.getItem('idConta')}/${nivel2 != '' ? nivel2 : '*'}/*/*/*/*`, {}
            ).then(response => {setNivelLoc3List(response.data)})
        }else {
            setNivelLoc3List([])
        }
    }

    function nivel4GetList(nivel3) {
        if(nivel3 != "*" && nivel3 != '') {
            api.get(`/nivel_loc4/${sessionStorage.getItem('idConta')}/${nivel3 != '' ? nivel3 : '*'}/*/*/*/*`, {}
            ).then(response => {setNivelLoc4List(response.data)})
        }else {
            setNivelLoc4List([])
        }
    }
    
    function nivel1GetListNew() {
        api.get(`/nivel_loc1/${sessionStorage.getItem('idConta')}/*/*/*/*`, {}
        ).then(response => {setNivelLoc1NewList(response.data)})
    }

    function nivel2GetListNew(nivel1) {
        if(nivel1 != "*" && nivel1 != '') {
            api.get(`/nivel_loc2/${sessionStorage.getItem('idConta')}/${nivel1 != '' ? nivel1 : '*'}/*/*/*/*`, {}
            ).then(response => {setNivelLoc2NewList(response.data)})
        }else {
            setNivelLoc2NewList([])
        }
    }

    function nivel3GetListNew(nivel2) {
        if(nivel2 != "*" && nivel2 != '') {
            api.get(`/nivel_loc3/${sessionStorage.getItem('idConta')}/${nivel2 != '' ? nivel2 : '*'}/*/*/*/*`, {}
            ).then(response => {setNivelLoc3NewList(response.data)})
        }else {
            setNivelLoc3NewList([])
        }
    }

    function nivel4GetListNew(nivel3) {
        if(nivel3 != "*" && nivel3 != '') {
            api.get(`/nivel_loc4/${sessionStorage.getItem('idConta')}/${nivel3 != '' ? nivel3 : '*'}/*/*/*/*`, {}
            ).then(response => {setNivelLoc4NewList(response.data)})
        }else {
            setNivelLoc4NewList([])
        }
    }

    function changeImage(file) {
        var reader = new FileReader()
        reader.readAsDataURL(file)

        reader.onloadend = function(e, file) {
            var b64 = this.result;

            let option = {
                headers: { 'Content-Type': ['application/json'] }
            }

            api.post(`/save_jpeg/${sessionStorage.getItem('idConta')}`, [{foto: b64}], option).then(
                async response => {
                    let fotoRes = response.data[0].id_foto
                    setFotoUp(fotoRes)
                    setFoto(fotoRes && fotoRes != '' && fotoRes != '/static/media/sem_foto.b83884cf.png' && fotoRes != 'assets/imgs/sem_foto.png'
                    ? "https://api.inovacode.app.br/" + fotoRes : NoImg)
                }
            ).finally(
                () => {
                    setFotoToUp(true)
                }
            )
        }
    }

    function categoriaGet() {
        api.get(`/categoria/${sessionStorage.getItem('idConta')}/*/*/*/*`, {}
        ).then(response => {setCategoriaList(response.data)})
    }

    function novaCategoria(fechar) {
        if(fechar == 'fechar') {
            setAlertNew('')
            setInputNew('')
            setCategoriaNew('')
        }else {
            let reg = []
            reg.push({
                ativo: 1,
                descricao: inputNew,
                ean: "",
                id_categoria: "0",
                id_conta: sessionStorage.getItem('idConta'),
                id_externo: "",
                inf_compl_1: "",
                inf_compl_2: "",
                inf_compl_3: "",
                inf_compl_4: "",
                inf_compl_5: "",
                observacao: ""
            })
            
            let option = {
                headers: { 'Content-Type': ['application/json'] }
            }

            api.post('/categoria', reg, option).then(
                response=> {
                    setInputNew('')
                    setAlertNew('')
                    categoriaGet()
                    setCategoriaNew(response.data[0]._id)

                },
                response=> {
                    setAlertNew(`Temos um problema...\n${response.data.error}`)
                }
            )
        }
    }

    function conditionAPI(cond1, cond2,where, field) {
        if(!(cond1 == '' || cond1 == '*' || cond1 == null|| cond1 == undefined)) {
            return cond1
        }else if(!(cond2 == null ||cond2 == undefined ||cond2[where] == null ||cond2[where] == undefined || cond2[where][field] == '' || cond2[where][field] == '*' || cond2[where][field] == null)) {
            return cond2[where][field]
        }else {
            return ''
        }
    }

    const noFoto = ['', '/static/media/sem_foto.b83884cf.png', 'assets/imgs/sem_foto.png']

    function csv() {
        let body = itList.map((item, index) => {
            let inv = exportList.filter(it => {
                if(it._id == item.id_registro){
                    return it
                }
            })
            let info = ""
            let dtstatus = ['', null]
            if(item.encontrado == 0 && item.excedente == 0) {
                info = "Ausente"
            }
            if(item.encontrado == 1 && item.excedente == 0) {
                info = "Encontrado"
            }
            if(item.excedente == 1) {
                info = "Excedente"
            }
            if(item.excedente == 1 && item.id_item && (item.id_item != '0' || item.descricao != "SEM DESCRICAO")) {
                info = "Outro_Ambiente"
            }
            if(item.auditado == 1) {
                info = "Auditado"
            }
            if(item.incorporado == 1) {
                info = 'Incorporado'
            }
            if(listaFilterExport.indexOf(info) > -1) {
                return [
                    item.id_item._id,
                    item.descricao,
                    item.tag,
                    (item.id_item && item.id_item.id_externo) ? item.id_item.id_externo : '',
                    threeCondition(item.id_item, item.id_categoria, 'inf_compl_1'),
                    threeCondition(item.id_item, item.id_categoria, 'inf_compl_2'),
                    threeCondition(item.id_item, item.id_categoria, 'inf_compl_3'),
                    threeCondition(item.id_item, item.id_categoria, 'inf_compl_4'),
                    threeCondition(item.id_item, item.id_categoria, 'inf_compl_5'),
                    conditionAPI(item.nivel_loc_1, item.id_item, 'id_nivel_loc_1', 'descricao').replaceAll('"',"''"),
                    conditionAPI(item.nivel_loc_2, item.id_item, 'id_nivel_loc_2', 'descricao').replaceAll('"',"''"),
                    conditionAPI(item.nivel_loc_3, item.id_item, 'id_nivel_loc_3', 'descricao').replaceAll('"',"''"),
                    conditionAPI(item.nivel_loc_4, item.id_item, 'id_nivel_loc_4', 'descricao').replaceAll('"',"''"),
                    conditionAPI(item.observacao, item, 'id_item', 'observacao').replaceAll('"',"''"),
                    info,
                    !dtstatus.includes(item.dt_leitura) ? formatDatecsv(item.dt_leitura): '',
                    item.latitude ? item.latitude : '',
                    item.longitude ? item.longitude : '',
                    inv[0].identificador,
                    inv[0].id_usuario ? inv[0].id_usuario.nome : '',
                    inv[0].id_leitor ? inv[0].id_leitor.nm_leitor : '',
                    (inv[0].id_nivel_loc_1 ? inv[0].id_nivel_loc_1.descricao : ''),
                    (inv[0].id_nivel_loc_2 ? inv[0].id_nivel_loc_2.descricao : ''),
                    (inv[0].id_nivel_loc_3 ? inv[0].id_nivel_loc_3.descricao : ''),
                    (inv[0].id_nivel_loc_4 ? inv[0].id_nivel_loc_4.descricao : ''),
                    inv[0].latitude,
                    inv[0].longitude,
                    !dtstatus.includes(inv[0].dt_registro) ? formatDatecsv(inv[0].dt_registro) : '',
                    !dtstatus.includes(inv[0].dt_alteracao) ? formatDatecsv(inv[0].dt_alteracao) : '',
                    (item.id_item.foto && !noFoto.includes(item.id_item.foto)) ? `${process.env.REACT_APP_API_URL}/${item.id_item.foto}` : 'Sem foto'
                ]
            }
        })
        let body2 = ordenateListBy(ordenationExport.split(','),body).filter((item) => {
            if(item) {
                return item
            }
        })
        return [...body2]
    }
    //

    //pedidos
    function csvPedido() {
        let body = pedidoListItens.map((item, index) => {
            let info = ''
            if(Number(item.quantidade) == Number(item.quantidade_lida)) {
                info = 'Correto'
            }
            else if(Number(item.quantidade) > Number(item.quantidade_lida)) {
                info = 'Faltante'
            }
            else if(Number(item.quantidade) < Number(item.quantidade_lida)) {
                info = 'Excedente'
            }
            return [
                item.descricao,
                item.ean,
                item.quantidade.replace('\r',''),
                item.quantidade_lida,
                item.quantidade_auditada,
                info,
                item.observacao
            ]
        })
        let body2 = body.filter((item) => {
            if(item) {
                return item
            }
        })
        return [...body2]
    }
    //

    //itens
    function csvItens() {
        let body = tagListItens.map((item, index) => {
            let dtstatus = ['', null]
            let info = ""
            if(item.encontrado == 0 && item.excedente == 0) {
                info = "Ausente"
            }
            if(item.encontrado == 1 && item.excedente == 0) {
                info = "Encontrado"
            }
            if(item.excedente == 1) {
                info = "Excedente"
            }
            if(item.excedente == 1 && item.id_item && (item.id_item != '0' || item.descricao != "SEM DESCRICAO")) {
                info = "Outro_Ambiente"
            }
            if(item.auditado == 1) {
                info = "Auditado"
            }
            if(item.incorporado == 1) {
                info = 'Incorporado'
            }
            if(tagFilter == "Todas" || info == tagFilter){
                return [
                    item.id_item?._id,
                    item.descricao,
                    item.tag,
                    (item.id_item && item.id_item.id_externo) ? item.id_item.id_externo : '',
                    threeCondition(item.id_item, item.id_categoria, 'inf_compl_1'),
                    threeCondition(item.id_item, item.id_categoria, 'inf_compl_2'),
                    threeCondition(item.id_item, item.id_categoria, 'inf_compl_3'),
                    threeCondition(item.id_item, item.id_categoria, 'inf_compl_4'),
                    threeCondition(item.id_item, item.id_categoria, 'inf_compl_5'),
                    conditionAPI(item.nivel_loc_1, item.id_item, 'id_nivel_loc_1', 'descricao').replaceAll('"',"''"),
                    conditionAPI(item.nivel_loc_2, item.id_item, 'id_nivel_loc_2', 'descricao').replaceAll('"',"''"),
                    conditionAPI(item.nivel_loc_3, item.id_item, 'id_nivel_loc_3', 'descricao').replaceAll('"',"''"),
                    conditionAPI(item.nivel_loc_4, item.id_item, 'id_nivel_loc_4', 'descricao').replaceAll('"',"''"),
                    conditionAPI(item.observacao, item, 'id_item', 'observacao').replaceAll('"',"''"),
                    info,
                    !dtstatus.includes(item.dt_leitura) ? formatDatecsv(item.dt_leitura): '',
                    item.latitude ? item.latitude : '',
                    item.longitude ? item.longitude : '',
                    (item.id_item?._id && !noFoto.includes(item.id_item?._id)) ? `${process.env.REACT_APP_API_URL}/${item.id_item?._id}` : 'Sem foto'
                ]
            }
        })
        let body2 = body.filter((item) => {
            if(item) {
                return item
            }
        })
        return [...body2]
    }
    //

    function threeCondition(cond1, cond2, target) {
        if(cond1) {
            return cond1[target]
        }else if(cond2) {
            return cond2[target]
        }else {
            return ''
        }
    }

    function numberListGet() {
        let list = []
        let qtdpages = reverse ? Math.ceil((items[0].total_reg / 30)) : Math.ceil((items[items.length - 1].total_reg / 30))
        
        if(qtdpages >= 2 && qtdpages <= 5) {
            for(let i= 1; i <= qtdpages; i++) {
                list.push(i)
            }
        }else if(qtdpages > 5) {
            if(pageNumber <= 1) {
                for(let i= 1; i <= 5; i++) {
                    list.push(i)
                }
            } else if(pageNumber > 1 && pageNumber < (qtdpages - 2)) {
                for(let i= (pageNumber - 1); i <= (pageNumber + 3); i++) {
                    list.push(i)
                }
            } else {
                for(let i= (qtdpages - 4); i <= qtdpages; i++) {
                    list.push(i)
                }
            }
        }
        return list
    }

    function changeFunction(key) {
        switch (key) {
            case 0:
                return (
                    <div >
                        <Container fluid>
                        {showMap ? (
                            <Modal show={true} centered dialogClassName=" modal-xl">
                                <Modal.Header>
                                    <Modal.Title>{t('actions.map')}</Modal.Title>
                                    <Button variant="light" className="bg-transparent border-0" onClick={e=> {markersUnloader()}}>
                                        <CloseButton />
                                    </Button>
                                </Modal.Header>
                                <Modal.Body>
                                    <GoogleMap
                                        className='mapStyles'
                                        mapContainerStyle={mapContainerStyle}
                                        zoom={18}
                                        center={{lat: Number.parseFloat(markList[0].latitude), lng: Number.parseFloat(markList[0].longitude)}}
                                    >
                                        {markList.map((item, index) => {
                                            let info = ""
                                            if(item.encontrado == 0 && item.excedente == 0) {
                                                info = "Ausente"
                                            }
                                            if(item.encontrado == 1 && item.excedente == 0) {
                                                info = "Encontrado"
                                            }
                                            if(item.excedente == 1) {
                                                info = "Excedente"
                                            }
                                            if(item.excedente == 1 && item.id_item && (item.id_item != '0' || item.descricao != "SEM DESCRICAO")) {
                                                info = "Outro_Ambiente"
                                            }
                                            if(item.auditado == 1) {
                                                info = "Auditado"
                                            }
                                            if(item.incorporado == 1) {
                                                info = 'Incorporado'
                                            }
                                            if(item.longitude) {
                                                return (
                                                    <Marker
                                                        key = {index}
                                                        position={{
                                                            lat: Number.parseFloat(item.latitude),
                                                            lng: Number.parseFloat(item.longitude)
                                                        }}
                                                        icon={{
                                                            url: `/iconMap_${info}.png`,
                                                            scaledsize: new window.google.maps.Size(32, 30)
                                                        }}
                                                        onClick= {e => {setSelectedMark(item)}}
                                                    />
                                                    
                                                )
                                            }
                                        })}
                                        {selectedMark && (
                                            <InfoWindow
                                                position={{
                                                    lat: Number.parseFloat(selectedMark.latitude),
                                                    lng: Number.parseFloat(selectedMark.longitude)
                                                }}
                                                onCloseClick={e => {setSelectedMark(null)}}
                                            >
                                                <div style={{textAlign: 'center'}}>
                                                    <h2>{selectedMark.descricao}</h2>
                                                    <p style={{fontWeight:'bold', fontSize: 15}}>{selectedMark.tag}</p>
                                                    <p style={{fontWeight:'bold', fontSize: 15}}>{selectedMark.latitude} {selectedMark.longitude}</p>
                                                </div>
                                            </InfoWindow>
                                        )}
                                    </GoogleMap>
                                </Modal.Body>
                            </Modal>
                        ) : ''}
                        <Row>
                            <Col><h3>{t('common.collections')}</h3></Col>
                            <Col className="d-flex justify-content-end">
                                {false ? <Button variant="light" onClick={e => setChange(1)}>REPOSITÓRIO</Button> : ''}
                                {false && sessionStorage.getItem('perfil') == 'admin' ? <Button variant="light" className="mx-2" onClick={e => setChange(2)}>NOVA</Button> : ''}
                            </Col>
                        </Row>
                        <Col className="border rounded bg-white p-3">
                            <Col className="d-flex justify-content-between border-bottom py-2 align-items-center">
                                {tipoList == 'item' ? <h4>{t('common.items')}</h4> : <h4>{t('common.register')}</h4>}
                                {tipoList == 'registro' ? <Button variant="custom-primary" onClick={e=> {getExportList()}}>{t('actions.export')} {t('common.collections')}</Button>: ''}
                                {tipoList == 'item' ? <Button variant="light" onClick={e => {markersLoader(items)}}>{t('actions.map')}<FaMapMarkedAlt className="ms-3" style={{fontSize:"24px"}}/></Button> : ''}
                            </Col>
                            <Col className="rounded p-3 mt-3" style={{background:'#f5f6fb'}}>
                                    <div className="d-flex align-items-center">
                                        <h5 className="fw-bold text-primary">
                                            <FiFilter/>
                                            <span className="px-2">{t('common.filter')}</span>
                                        </h5>
                                    </div>
                                    <Row className="gy-2 mt-3">
                                <Form.Group as={Col} sm={3}>
                                    <Form.Label>{t('common.type')}</Form.Label>
                                    <Form.Select
                                        value = {tipoList}
                                        onChange = {e=> {setTipoList(e.target.value)}}
                                    >
                                        <option value='registro'>{t('common.register')}</option>
                                        <option value='item'>{t('common.items')}</option>
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group as={Col} sm={3}>
                                    <Form.Label>{t('common.date')}</Form.Label>
                                    <Row className='g-2'>
                                        <Col>
                                            <Form.Control 
                                                type="date"
                                                value={dataInicio}
                                                onChange={e => {setDataInicio(e.target.value)}}
                                            />
                                        </Col>
                                        <Col>
                                            <Form.Control 
                                                type="date"
                                                value={dataFim}
                                                onChange={e => {setDataFim(e.target.value)}}
                                            />
                                        </Col>
                                    </Row>
                                </Form.Group>
                                <Form.Group as={Col} sm={3}>
                                    <Form.Label>{t('common.register')}</Form.Label>
                                    <Form.Control
                                        type="text"
                                        onChange={e => {if(e.target.value != ""){setIdentificador(e.target.value)}
                                        else{setIdentificador('*')}}}/>
                                </Form.Group>
                                <Form.Group as={Col} sm={3}>
                                    <Form.Label>{t('common.partners')}</Form.Label>
                                    <Form.Select
                                        value={parceiro}
                                        onChange={e=>{setParceiro(e.target.value)}}
                                    >
                                        <option value={'*'}></option>
                                        {parceiroList.map((item, index) => (
                                            <option key={index} value={item.nome}>{item.nome}</option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group as={Col} sm={3}>
                                        <Form.Label>{nivelLabelList[0]}</Form.Label>
                                        <Form.Select
                                            value={nivelLoc1}
                                            onChange={e=> {setNivelLoc1(e.target.value)}}
                                        >
                                            <option value='*'></option>
                                            {nivelLoc1List.map((item, index) => (
                                                <option key={index} value={item._id}>{item.descricao}</option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group as={Col} sm={3}>
                                        <Form.Label>{nivelLabelList[1]}</Form.Label>
                                        <Form.Select
                                            value={nivelLoc2}
                                            onChange={e => {setNivelLoc2(e.target.value)}}
                                        >
                                            <option value='*'></option>
                                            {nivelLoc2List.map((item, index) => (
                                                <option key={index} value={item._id}>{item.descricao}</option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group as={Col} sm={3}>
                                        <Form.Label>{nivelLabelList[2]}</Form.Label>
                                        <Form.Select
                                            value={nivelLoc3}
                                            onChange={e=> {setNivelLoc3(e.target.value)}}
                                        >
                                            <option value='*'></option>
                                            {nivelLoc3List.map((item, index) => (
                                                <option key={index} value={item._id}>{item.descricao}</option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group as={Col} sm={3}>
                                        <Form.Label>{nivelLabelList[3]}</Form.Label>
                                        <Form.Select
                                            value={nivelLoc4}
                                            onChange={e=> {setNivelLoc4(e.target.value)}}
                                        >
                                            <option value='*'></option>
                                            {nivelLoc4List.map((item, index) => (
                                                <option key={index} value={item._id}>{item.descricao}</option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group as={Col} sm={3}>
                                        <Form.Label>{t('common.reader')}</Form.Label>
                                        <Autocomplete
                                            size="small"
                                            options={leitorList}
                                            getOptionLabel={(option) => option.nm_leitor}
                                            value={leitorList.find(cat => cat === reader) || null}
                                            onChange={(event, newValue) => {
                                                setReader(newValue ? newValue : null);
                                            }}
                                            renderInput={(params) => <TextField {...params} />}
                                            noOptionsText={'Não encontrado'}
                                        />
                                    </Form.Group>                                    
                                </Row>
                            </Col>
                            <Col className="d-flex justify-content-between">
                                {!reverse && items[items.length - 1] && items[items.length - 1].total_reg > 30 || reverse && items[0] && items[0].total_reg > 30 ? 
                                    <div className="pagesContent">
                                        <div className="numberPages" onClick={e=> {pageNumber > 0 ? setPageNumber(pageNumber - 1) : setPageNumber(pageNumber)}}>Anterior</div>
                                        {numberListGet().map((number, index) => (
                                            <div className="numberPages" key={index} onClick={e => {setPageNumber(number - 1)}}>{number}</div>
                                        ))}
                                        <div className="numberPages" onClick={e=> {pageNumber < Math.ceil(items[items.length - 1].total_reg/ 30) - 1 ? setPageNumber(pageNumber + 1) : setPageNumber(pageNumber)}}>Próximo</div>
                                        <div className="numberPages">{!reverse && items[items.length - 1] || reverse && items[0] ? items[reverse ? 0 : items.length - 1].total_reg : 0} coletas</div>
                                    </div>
                                :
                                <div className="pagesContent">
                                    <div className="numberPages">{!reverse && items[items.length - 1] || reverse && items[0] ? items[reverse ? 0 : items.length - 1].total_reg : 0} coletas</div>
                                </div>
                                }
                                <div className='autoR'>
                                    <p>AUTO REFRESH</p>
                                    <label className="switch" style={{margin:'auto', marginRight: '0'}}>
                                        <input type="checkbox" onChange={e=> {setLooper(!looper)}}/>
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                            </Col>
                        </Col>
                            
                        <Col className="border rounded bg-white mt-3 p-2">
                        <div className='d-flex justify-content-end'>
                            <Dropdown>
                                <Dropdown.Toggle variant="outlined" align="end" className='border'>
                                    <Icon icon="heroicons:eye-solid" width={16}/> Mostrar Ocultos
                                </Dropdown.Toggle>

                                <Dropdown.Menu className='p-2'>
                                    <Form.Check
                                        className='text-nowrap pb-2'
                                        type={'checkbox'}
                                        id={`show-excedente-inventories`}
                                        label={`Ver inventários apenas com excedentes`}
                                        value={showHidden}
                                        onChange={e=>{setShowHidden(e.target.checked)}}
                                    />
                                    <Form.Check
                                        className='text-nowrap'
                                        type={'checkbox'}
                                        id={`show-excedente-inventories`}
                                        label={`Ver testes`}
                                        value={showTestes}
                                        onChange={e=>{setShowTestes(e.target.checked)}}
                                    />
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        {!itemsLoaded ? 
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                                <ReactLoading type={'bars'} color={'#2488f7'} height={150} width={150}/>
                            </div>
                        : 
                            <div>
                            {tipoList == 'registro' ?
                            <Table responsive hover>
                                <thead className='bg-white'>
                                    <tr>
                                        <th onClick={e=> {reverseReg()}} style={{cursor: 'pointer'}}># {reverse ? <AiOutlineArrowUp/> : <AiOutlineArrowDown/>}</th>
                                        <th>{t('common.identify')}</th>
                                        <th>{t('common.data')}</th>
                                        <th>{t('common.locate')}</th>
                                        <th>{t('common.displacement')}</th>
                                        <th>{t('common.user')}</th>
                                        <th>{t('common.resume')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {items.slice( reverse ? 1 : 0, reverse ? items.length : items.length - 1).map((item, index) => {
                                        if(
                                            (nivelLoc1 == '*' || (item.id_nivel_loc_1 && nivelLoc1 == item.id_nivel_loc_1._id))
                                            && (nivelLoc2 == '*' || (item.id_nivel_loc_2 && nivelLoc2 == item.id_nivel_loc_2._id))
                                            && (nivelLoc3 == '*' || (item.id_nivel_loc_3 && nivelLoc3 == item.id_nivel_loc_3._id))
                                            && (nivelLoc4 == '*' || (item.id_nivel_loc_4 && nivelLoc4 == item.id_nivel_loc_4._id))
                                            && (identificador == '*' || item.identificador.search(identificador) > -1)
                                        ) {
                                            return (
                                                <tr
                                                    key={index}
                                                    onClick={e=> {editItem(item)}}
                                                >
                                                    <th>{reverse ? pageNumber * 30 + items.length - (index + 1) : pageNumber * 30 + index + 1}</th>
                                                    <th>{item.identificador}</th>
                                                    {item.dt_registro ? formatDate(item.dt_registro) : <th></th>}
                                                    <th>
                                                    <div>{item && item.id_nivel_loc_1 ? item.id_nivel_loc_1.descricao : 'N / A'}</div>
                                                    <div>
                                                        {item && item.id_leitor ? item.id_leitor.nm_leitor : ' '}
                                                    </div>
                                                        {item && item.id_nivel_loc_1 ? <div>{item.id_nivel_loc_2 ? item.id_nivel_loc_2.descricao : 'N / A'}</div> : ''}
                                                        {item && item.id_nivel_loc_2 ? <div>{item.id_nivel_loc_3 ? item.id_nivel_loc_3.descricao : 'N / A'}</div> : ''}
                                                        {item && item.id_nivel_loc_3 ? <div>{item.id_nivel_loc_4 ? item.id_nivel_loc_4.descricao : 'N / A'}</div> : ''}
                                                    </th>
                                                    <th>
                                                        <div>{item?.def_id_nivel_loc_1?.descricao}</div>
                                                        <div>{item?.def_id_nivel_loc_2?.descricao}</div>
                                                        <div>{item?.def_id_nivel_loc_3?.descricao}</div>
                                                        <div>{item?.def_id_nivel_loc_4?.descricao}</div>
                                                    </th>
                                                    <th>
                                                        <div>{item?.id_usuario?.nome || item?.id_usuario?.login}</div>
                                                        {item?.device_name &&(
                                                            <div><Typography variant="subtitle"><Icon icon="heroicons:device-tablet-solid"/> {item?.device_name}</Typography></div>
                                                        )}
                                                    </th>
                                                    <th>
                                                        <div style={{display:'flex', flexDirection:'row'}}><div className='block-info resume-status' style={{backgroundColor: '#B40C0C'}}/>Ausente: {item.qtd_ausente}</div>
                                                        <div style={{display:'flex', flexDirection:'row'}}><div className='block-info resume-status' style={{backgroundColor: '#4EA90B'}}/>Encontrado: {item.qtd_encontrado}</div>
                                                        <div style={{display:'flex', flexDirection:'row'}}><div className='block-info resume-status' style={{backgroundColor: '#08B08C'}}/>Auditado: {item.qtd_auditado}</div>
                                                        <div style={{display:'flex', flexDirection:'row'}}><div className='block-info resume-status' style={{backgroundColor: '#26C6DA'}}/>Incorporado: {item.qtd_incorporado}</div>
                                                        <div style={{display:'flex', flexDirection:'row'}}><div className='block-info resume-status' style={{backgroundColor: '#E3AE1B'}}/>Outro_Ambiente: {item.qtd_outro_ambiente}</div>
                                                        <div style={{display:'flex', flexDirection:'row'}}><div className='block-info resume-status' style={{backgroundColor: '#A33BBF'}}/>Excedente: {item.qtd_excedente}</div>
                                                    </th>
                                                </tr>
                                            )
                                        }
                                    })}
                                </tbody>
                            </Table>
                            :
                            <Table responsive>
                                <thead className='bg-white'>
                                    <tr>
                                        <th onClick={e=> {reverseReg()}} style={{cursor: 'pointer'}}># {reverse ? <AiOutlineArrowUp/> : <AiOutlineArrowDown/>}</th>
                                        <th>{t('common.items')}</th>
                                        <th>{t('common.locate')}</th>
                                        <th></th>
                                        <th>Status</th>
                                        <th><ImCompass2/></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {items.slice(0, items.length - 1).map((item, index) => {
                                        let info = ""
                                        if(item.encontrado == 0 && item.excedente == 0) {
                                            info = "Ausente"
                                        }
                                        if(item.encontrado == 1 && item.excedente == 0) {
                                            info = "Encontrado"
                                        }
                                        if(item.excedente == 1) {
                                            info = "Excedente"
                                        }
                                        if(item.excedente == 1 && item.id_item && (item.id_item != '0' || item.descricao != "SEM DESCRICAO")) {
                                            info = "Outro_Ambiente"
                                        }
                                        if(item.auditado == 1) {
                                            info = "Auditado"
                                        }
                                        if(item.incorporado == 1) {
                                            info = 'Incorporado'
                                        }
                                        return (
                                            <tr key={index}>
                                                <th>{index + 1}</th>
                                                <th>
                                                    <div>{item.descricao}</div>
                                                    <div>{item.tag}</div>
                                                </th>
                                                <th>
                                                    <div>{item.id_item && item.id_item.id_nivel_loc_1 ? item.id_item.id_nivel_loc_1.descricao : 'N / A'}</div>
                                                    {item.id_item && item.id_item.id_nivel_loc_1 ? <div>{item.id_item.id_nivel_loc_2 ? item.id_item.id_nivel_loc_2.descricao : 'N / A'}</div> : ''}
                                                    {item.id_item && item.id_item.id_nivel_loc_2 ? <div>{item.id_item.id_nivel_loc_3 ? item.id_item.id_nivel_loc_3.descricao : 'N / A'}</div> : ''}
                                                    {item.id_item && item.id_item.id_nivel_loc_3 ? <div>{item.id_item.id_nivel_loc_4 ? item.id_item.id_nivel_loc_4.descricao : 'N / A'}</div> : ''}
                                                </th>
                                                <th>
                                                    {
                                                        item.foto && !fotoNone.includes(item.foto) ?
                                                        <img style={{borderRadius: '10px'}} src={`https://api.inovacode.app.br/${item.foto}`} alt='CollectItemImage'/> : ''
                                                    }
                                                </th>
                                                <th>
                                                    <div style={{padding: '10px', background: colorGet(info), width: '120px', textAlign: 'center', borderRadius: '10px', color: '#F8F8F8', fontWeight: 'bold'}}>{info}</div>
                                                    <div>{item.dt_leitura}</div>
                                                </th>
                                                <th>{item.longitude ? <GoLocation className="table_icons show_map" onClick={e => {markersLoader([item])}}/> : ''}</th>
                                            </tr>
                                        )})}
                                    </tbody>
                                </Table>
                            }
                            </div>
                        }
                        </Col>  
                        </Container>
                    </div>
                )
                break
            /*case 1:
                return (
                    <div >
                        <div className="title" id="colection">
                            <h1>Coletas</h1>
                            <div>
                                <button>IMPORTAR</button>
                                <button onClick={e => setChange(0)}>COLETAS</button>
                            </div>
                        </div>
                        <div className="filters">
                                <div className="file">
                                    <label>Arquivo</label>
                                    <input type="file"/>
                                </div>
                            </div>
                    </div>
                )
                break*/
            case 2:
                return(
                    <div >
                        <Row className="my-3 flex-wrap">
                            <Col><h3>{t('common.collections')}</h3></Col>
                            <Col className="container-btn">
                                {editing == 1 ? (<Button variant="light" className="text-uppercase mx-1" onClick={e=> {remove()}}>{t('actions.delete')}</Button>): ''}
                                {false ? <Button variant="light" className="text-uppercase" onClick={e=> {save()}}>{t('actions.save')}</Button> : ''}
                                <Button variant="light" className="text-uppercase mx-1" onClick={e => cleaner()}>{t('actions.exit')}</Button>
                            </Col>
                        </Row>
                        <Container fluid className="bg-white border rounded p-3">
                        {showMap ? (
                            <Modal show={true} centered dialogClassName=" modal-xl">
                                <Modal.Header>
                                    <Modal.Title>{t('actions.map')}</Modal.Title>
                                    <Button variant="light" className="bg-transparent border-0" onClick={e=> {markersUnloader()}}>
                                        <CloseButton />
                                    </Button>
                                </Modal.Header>
                                <Modal.Body>
                                        <GoogleMap
                                            className='mapStyles'
                                            mapContainerStyle={mapContainerStyle}
                                            zoom={18}
                                            center={{lat: Number.parseFloat(markList[0].latitude), lng: Number.parseFloat(markList[0].longitude)}}
                                        >
                                            {markList.map((item, index) => {
                                                let info = ""
                                                if(item.encontrado == 0 && item.excedente == 0) {
                                                    info = "Ausente"
                                                }
                                                if(item.encontrado == 1 && item.excedente == 0) {
                                                    info = "Encontrado"
                                                }
                                                if(item.excedente == 1) {
                                                    info = "Excedente"
                                                }
                                                if(item.excedente == 1 && item.id_item && (item.id_item != '0' || item.descricao != "SEM DESCRICAO")) {
                                                    info = "Outro_Ambiente"
                                                }
                                                if(item.auditado == 1) {
                                                    info = "Auditado"
                                                }
                                                if(item.incorporado == 1) {
                                                    info = 'Incorporado'
                                                }
                                                if(item.longitude) {
                                                    return (
                                                        <Marker
                                                            key = {index}
                                                            position={{
                                                                lat: Number.parseFloat(item.latitude),
                                                                lng: Number.parseFloat(item.longitude)
                                                            }}
                                                            icon={{
                                                                url: `/iconMap_${info}.png`,
                                                                scaledsize: new window.google.maps.Size(32, 30)
                                                            }}
                                                            onClick= {e => {setSelectedMark(item)}}
                                                        />
                                                    )
                                                }
                                            })}

                                            {selectedMark && (
                                                <InfoWindow
                                                    position={{
                                                        lat: Number.parseFloat(selectedMark.latitude),
                                                        lng: Number.parseFloat(selectedMark.longitude)
                                                    }}
                                                    onCloseClick={e => {setSelectedMark(null)}}
                                                >
                                                    <div style={{textAlign: 'center'}}>
                                                        <h2>{selectedMark.descricao}</h2>
                                                        <p style={{fontWeight:'bold', fontSize: 15}}>{selectedMark.tag}</p>
                                                        <p style={{fontWeight:'bold', fontSize: 15}}>{selectedMark.latitude} {selectedMark.longitude}</p>
                                                    </div>
                                                </InfoWindow>
                                            )}
                                        </GoogleMap>
                                </Modal.Body>
                            </Modal>
                        ) : ''}
                        <ul className="nav custom-nav mx-3 text-secondary">
                            <Button variant="nav" onClick={e=>setChangeNew(0)}>{t('common.collections')}</Button>
                            <Button variant="nav" onClick={e=>setChangeNew(1)}>{t('common.demand')}</Button>
                            <Button variant="nav" onClick={e=>setChangeNew(2)}>{t('common.items')}</Button>
                            <Button variant="nav" onClick={e=>setChangeNew(5)}>{t('common.reader')}</Button>
                            <Button variant="nav" onClick={e=>setChangeNew(3)}>{t('common.map')}</Button>
                            <Button variant="nav" onClick={e=>setChangeNew(4)}>{t('common.linked')}</Button>
                        </ul>
                        {changeNewFunction(changeNew)}
                        </Container>
                    </div>
                )
                break
            case 3:
                return (
                    <div >
                        <div className='d-flex justify-content-between'>
                            <h1>Coletas</h1>
                            <div>
                                {details[1] == 'Excedente' ? <button onClick={e => {openCreateItem()}}>CRIAR_ITEM</button> : ''}
                                <button onClick={e => {setChange(2)}}>VOLTAR</button>
                            </div>
                        </div>
                        <div style={{marginTop: '20px'}}>
                            <div style={{padding: '10px', background: colorGet(details[1]), textAlign: 'center', borderRadius: '10px', color: '#F8F8F8', fontWeight: 'bold'}}>{details[1]}</div>
                            <div style={{textAlign: 'right', fontWeight:'bold'}}>{details[0] ? details[0].dt_leitura : ''}</div>
                        </div>
                        <div className='list_account'>
                            <div className='infs'>
                                <div>
                                    <label>Categoria</label>
                                    <input
                                        type="text"
                                        value={itemDetails[0].id_categoria ? itemDetails[0].id_categoria.descricao : details[0].descricao}
                                        readOnly
                                    />
                                </div>
                                <div>
                                    <label>Tag</label>
                                    <input
                                        type="text"
                                        value={details[0].tag}
                                        readOnly
                                    />
                                </div>
                                <div>
                                    <label>idExterno</label>
                                    <input
                                        type="text"
                                        value={itemDetails[0].id_externo}
                                        readOnly
                                    />
                                </div>
                                <div>
                                    <label>Ativo</label>
                                    <input
                                        type="text"
                                        value={itemDetails[0].ativo}
                                        readOnly
                                    />
                                </div>
                            </div>
                            <div className="infs">
                                <div>
                                    <label>Inf. Complentar 1</label>
                                    <input
                                        type="text"
                                        value={itemDetails[0].inf_compl_1}
                                        readOnly
                                    />
                                </div>
                                <div>
                                    <label>Inf. Complentar 2</label>
                                    <input
                                        type="text"
                                        value={itemDetails[0].inf_compl_2}
                                        readOnly
                                    />
                                </div>
                                <div>
                                    <label>Inf. Complentar 3</label>
                                    <input
                                        type="text"
                                        value={itemDetails[0].inf_compl_3}
                                        readOnly
                                    />
                                </div>
                                <div>
                                    <label>Inf. Complentar 4</label>
                                    <input
                                        type="text"
                                        value={itemDetails[0].inf_compl_4}
                                        readOnly
                                    />
                                </div>
                                <div>
                                    <label>Inf. Complentar 5</label>
                                    <input
                                        type="text"
                                        value={itemDetails[0].inf_compl_5}
                                        readOnly
                                    />
                                </div>
                            </div>
                            <div className='infs'>
                                <div>
                                    <label><b className="focus">Localização</b> Nivel 1</label>
                                    <input
                                        type="text"
                                        value={itemDetails[0].id_nivel_loc_1 ? itemDetails[0].id_nivel_loc_1.descricao : ''}
                                        readOnly
                                    />
                                </div>
                                <div>
                                    <label>Nivel 2</label>
                                    <input
                                        type="text"
                                        value={itemDetails[0].id_nivel_loc_2 ? itemDetails[0].id_nivel_loc_2.descricao : ''}
                                        readOnly
                                    />
                                </div>
                                <div>
                                    <label> Nivel 3</label>
                                    <input
                                        type="text"
                                        value={itemDetails[0].id_nivel_loc_3 ? itemDetails[0].id_nivel_loc_3.descricao : ''}
                                        readOnly
                                    />
                                </div>
                                <div>
                                    <label>Nivel 4</label>
                                    <input
                                        type="text"
                                        value={itemDetails[0].id_nivel_loc_4 ? itemDetails[0].id_nivel_loc_4.descricao : ''}
                                        readOnly
                                    />
                                </div>
                            </div>
                            <div id="flex_1">
                            <div id="flex2">
                                <div>
                                    <div>
                                        <label>Parceiro</label>
                                        <input
                                            type="text"
                                            value={itemDetails[0].parceiro ? itemDetails[0].parceiro : ''}
                                            readOnly
                                        />
                                    </div>
                                    <div>
                                        <label>Registro</label>
                                        <input
                                            type="text"
                                            value={itemDetails[0].id_registro ? itemDetails[0].id_registro.identificador : ''}
                                            readOnly
                                        />
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <label>Observação</label>
                                        <input
                                            type="text"
                                            value={itemDetails[0].observacao}
                                            readOnly
                                        />
                                    </div>
                                </div>
                            </div>
                            <div id="flex3">
                                <div>
                                    <img id="register" src={itemDetails[0].foto && !fotoNone.includes(itemDetails[0].foto) ? `https://api.inovacode.app.br/${itemDetails[0].foto}` : NoImg} alt="itemImage"/>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                )
                break
            case 4:
                return (
                    <div >     
                        <Container fluid>
                            <Col className="d-flex justify-content-between">
                                <h1>{t('common.collections')}</h1>
                                <div>
                                    {exportLoaded ? 
                                        <>
                                            <Button variant="light" onClick={e=> {
                                                setChange(7)
                                                setExportType(0)
                                            }}><FaFilePdf className="me-2 text-uppercase"/>{t('actions.generate')} PDF</Button>
                                            <Button variant="light" className="mx-2" onClick={e=> {
                                                setChange(6)
                                                setExportType(0)
                                            }}><FaFileCsv className="me-2 text-uppercase"/>{t('actions.generate')} CSV</Button>
                                        </>
                                    :
                                        <div class="spinner-border" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>
                                    }
                                    <Button variant="light" onClick={e => cleaner()}><FiArrowLeft className="me-2"/>{t('actions.exit')}</Button>
                                </div>
                            </Col>
                            <Col className="border rounded bg-white p-3">
                                <Row>
                                    <Col><h3>{t('common.items')}</h3></Col>
                                    <Col className="d-flex justify-content-end">
                                        <Button variant="light" onClick={e=> {loadItensToFile()}}>{t('actions.load')} Itens</Button>
                                        <Button variant="light" className="mx-2" onClick={e=> {addAllToList()}}>{t('actions.select_all')}</Button>
                                        <Button variant="light" onClick={e => {removeAlltoList()}}>{t('actions.deselect_all')}</Button>
                                    </Col>
                                </Row>
                                <Row className="align-items-end">
                                    <Col>
                                        <h6>{t('common.filter')}</h6>
                                        <div className="d-flex flex-row me-3">
                                            <div className='custom-checkbox fw-bold me-1' style={{backgroundColor: '#B40C0C', textAlign:'center', cursor: 'pointer'}} onClick={e=>{addOrRemoveExportFilter('Ausente')}}>{listaFilterExport.indexOf('Ausente') > -1 ? <FaCheck/> : ''}</div> Ausentes
                                        </div>
                                        <div className="d-flex flex-row me-3">
                                            <div className='custom-checkbox fw-bold me-1' style={{backgroundColor: '#4EA90B', textAlign:'center', cursor: 'pointer'}} onClick={e=>{addOrRemoveExportFilter('Encontrados')}}>{listaFilterExport.indexOf('Encontrados') > -1 ? <FaCheck/> : ''}</div> Encontrados
                                        </div>
                                        <div className="d-flex flex-row me-3">
                                            <div className='custom-checkbox fw-bold me-1' style={{backgroundColor: '#A33BBF', textAlign:'center', cursor: 'pointer'}} onClick={e=>{addOrRemoveExportFilter('Excedente')}}>{listaFilterExport.indexOf('Excedente') > -1 ? <FaCheck/> : ''}</div> Excedente
                                        </div>
                                        <div className="d-flex flex-row me-3">
                                            <div className='custom-checkbox fw-bold me-1' style={{backgroundColor: '#E3AE1B', textAlign:'center', cursor: 'pointer'}} onClick={e=>{addOrRemoveExportFilter('Outro_Ambiente')}}>{listaFilterExport.indexOf('Outro_Ambiente') > -1 ? <FaCheck/> : ''}</div> Outro_Ambiente
                                        </div>
                                    </Col>
                                    <Col className="d-flex justify-content-end align-items-center">
                                        <label className="fw-bold">{t('actions.sort_by')}</label>
                                        <select
                                            className="border-0 border-bottom rounded-0"
                                            value={ordenationExport}
                                            onChange={e=>{setOrdenationExport(e.target.value)}}
                                        >
                                            <option value={''}>{t('common.sort_none')}</option>
                                            <option value={'0'}>{t('common.description')}</option>
                                            <option value={'1'}>Tag</option>
                                            <option value={'2'}>{t('common.extern_code')}</option>
                                            <option value={'3'}>EAN</option>
                                            <option value={'9,10,11,12'}>Niveis Item</option>
                                            <option value={'14'}>Status</option>
                                            <option value={'15'}>{t('common.read_at')}</option>
                                            <option value={'18'}>{t('common.identify')}</option>
                                            <option value={'19'}>{t('common.user')}</option>
                                            <option value={'20'}>{t('common.reader')}</option>
                                            <option value={'21,22,23,24'}>{t('common.register_nivel')}</option>
                                        </select>
                                    </Col>
                                </Row>   
                            </Col>
                            <Col className="border rounded bg-white mt-3 p-2">
                                {!listExportLoaded ?
                                    <div style={{display: 'flex', justifyContent: 'center'}}>
                                            <ReactLoading type={'bars'} color={'#2488f7'} height={150} width={150}/>
                                        </div>
                                :
                                    <Table responsive>
                                        <thead className='bg-white'>
                                            <tr className="text-uppercase">
                                                <th style={{cursor: 'pointer'}}>#</th>
                                                <th>{t('common.identify')}</th>
                                                <th>{t('common.date')}</th>
                                                <th>{t('common.locate')}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {exportList.map((item, index) => {
                                            return (
                                                <tr
                                                    className='edit'
                                                    style={selectedIndex(item._id) ? {background: '#919191', color: '#F8F8F8'} : {}}
                                                    key={index}
                                                    onClick ={e=> {addOrRemoveListImport(item._id)}}
                                                >
                                                    <th>{index + 1}</th>
                                                    <th>{item.identificador}</th>
                                                    {item.dt_registro ? <th>{item.dt_registro.slice(0,10)} {item.dt_registro.slice(11,19)}</th> : <th></th>}
                                                    <th>{item.id_nivel_loc_1 ? item.id_nivel_loc_1.descricao : 'N / A'}</th>
                                                </tr>
                                            )})}
                                        </tbody>
                                    </Table>
                                }
                            </Col>
                        </Container>
                    </div>
                )
                break
            case 5:
                return (
                    <div >
                        <div className="title" id="colection">
                            <h1>Itens</h1>
                            <div>
                                <button onClick={e => {saveCreateItem()}}>SALVAR</button>
                                <button onClick={e => {closeCreateItem()}}>VOLTAR</button>
                            </div>
                        </div>
                        <div>
                        <div className="list_account">
                            <div className='infs'>
                                {categoriaNew == 'new' ? (
                                    <div className='extra'>
                                    <div className='extracontainer'>
                                        <div className="title">
                                            <h1>Categoria</h1>
                                        </div>
                                        <div>
                                            <input
                                                value={inputNew}
                                                onChange={e=> {setInputNew(e.target.value)}}
                                            />
                                        </div>
                                        <div className='close'>
                                            <button onClick={e=> {novaCategoria()}}>SALVAR</button>
                                            <button onClick={e=> {novaCategoria('fechar')}}>FECHAR</button>
                                        </div>
                                    </div>
                                </div>
                                ) : ''}
                                {nivelLoc1New == 'new' ? (
                                    <div className='extra'>
                                    <div className='extracontainer'>
                                        <div className="title">
                                            <h1>Nivel 1</h1>
                                        </div>
                                        <div>
                                            <input
                                                value={inputNew}
                                                onChange={e=> {setInputNew(e.target.value)}}
                                            />
                                        </div>
                                        <div className='close'>
                                            <button onClick={e=> {novoNivel1()}}>SALVAR</button>
                                            <button onClick={e=> {novoNivel1('fechar')}}>FECHAR</button>
                                        </div>
                                    </div>
                                </div>
                                ) : ''}
                                {nivelLoc2New == 'new' ? (
                                    <div className='extra'>
                                    <div className='extracontainer'>
                                        <div className="title">
                                            <h1>Nivel 2</h1>
                                        </div>
                                        <div>
                                            <input
                                                value={inputNew}
                                                onChange={e=> {setInputNew(e.target.value)}}
                                            />
                                        </div>
                                        <div className='close'>
                                            <button onClick={e=> {novoNivel2()}}>SALVAR</button>
                                            <button onClick={e=> {novoNivel2('fechar')}}>FECHAR</button>
                                        </div>
                                    </div>
                                </div>
                                ) : ''}
                                {nivelLoc3New == 'new' ? (
                                    <div className='extra'>
                                    <div className='extracontainer'>
                                        <div className="title">
                                            <h1>Nivel 3</h1>
                                        </div>
                                        <div>
                                            <input
                                                value={inputNew}
                                                onChange={e=> {setInputNew(e.target.value)}}
                                            />
                                        </div>
                                        <div className='close'>
                                            <button onClick={e=> {novoNivel3()}}>SALVAR</button>
                                            <button onClick={e=> {novoNivel3('fechar')}}>FECHAR</button>
                                        </div>
                                    </div>
                                </div>
                                ) : ''}
                                {nivelLoc4New == 'new' ? (
                                    <div className='extra'>
                                    <div className='extracontainer'>
                                        <div className="title">
                                            <h1>Nivel 4</h1>
                                        </div>
                                        <div>
                                            <input
                                                value={inputNew}
                                                onChange={e=> {setInputNew(e.target.value)}}
                                            />
                                        </div>
                                        <div className='close'>
                                            <button onClick={e=> {novoNivel4()}}>SALVAR</button>
                                            <button onClick={e=> {novoNivel4('fechar')}}>FECHAR</button>
                                        </div>
                                    </div>
                                </div>
                                ) : ''}
                                {parceiroNew == 'new' ? (
                                    <div className='extra'>
                                        <div className='extracontainer'>
                                            <div className="title">
                                                <h1>Parceiro</h1>
                                            </div>
                                            <div>
                                                <input
                                                    value={inputNew}
                                                    onChange={e=> {setInputNew(e.target.value)}}
                                                />
                                            </div>
                                            <div className='close'>
                                                <button onClick={e=> {novoParceiro()}}>SALVAR</button>
                                                <button onClick={e=> {novoParceiro('fechar')}}>FECHAR</button>
                                            </div>
                                        </div>
                                    </div>
                                ) : ''}
                                <div>
                                    <label>Categoria</label>
                                    <select
                                        value={categoriaNew}
                                        onChange={e=> {setCategoriaNew(e.target.value)}}
                                    >
                                        <option value={''}></option>
                                        <option value={'new'}>Nova Categoria</option>
                                        {categoriaList.map((item, index) => (
                                            <option key={index} value={item._id}>{item.descricao}</option>
                                        ))}
                                    </select>
                                </div>
                                <div>
                                    <label>Tag</label>
                                    <input
                                        type="text"
                                        value={tagNew}
                                        onChange={e=> {setTagNew(e.target.value)}}
                                    />
                                </div>
                                <div>
                                    <label>idExterno</label>
                                    <input
                                        className='blocked'
                                        type="text"
                                        value={codExternoNew}
                                        disabled
                                    />
                                </div>
                                <div>
                                    <label>Ativo</label>
                                    <select
                                        value={ativoNew}
                                        onChange={e=> {setAtivoNew(e.target.value)}}
                                    >
                                        <option value={'0'}>Não</option>
                                        <option value={'1'}>Sim</option>
                                    </select>
                                </div>
                            </div>
                            <div className="infs">
                                <div>
                                    <label>Inf. Complentar 1</label>
                                    <input
                                        type="text"
                                        value={inf1New}
                                        onChange={e=> {setInf1New(e.target.value)}}
                                    />
                                </div>
                                <div>
                                    <label>Inf. Complentar 2</label>
                                    <input
                                        type="text"
                                        value={inf2New}
                                        onChange={e=> {setInf2New(e.target.value)}}
                                    />
                                </div>
                                <div>
                                    <label>Inf. Complentar 3</label>
                                    <input
                                        type="text"
                                        value={inf3New}
                                        onChange={e=> {setInf3New(e.target.value)}}
                                    />
                                </div>
                                <div>
                                    <label>Inf. Complentar 4</label>
                                    <input
                                        type="text"
                                        value={inf4New}
                                        onChange={e=> {setInf4New(e.target.value)}}
                                    />
                                </div>
                                <div>
                                    <label>Inf. Complentar 5</label>
                                    <input
                                        type="text"
                                        value={inf5New}
                                        onChange={e=> {setInf5New(e.target.value)}}
                                    />
                                </div>
                            </div>
                            <div className='infs'>
                                <div>
                                    <label><b className="focus">Localização</b> Nivel 1</label>
                                    <select
                                        value={nivelLoc1New}
                                        onChange={e=> {setNivelLoc1New(e.target.value)}}
                                    >
                                        <option value={''}></option>
                                        <option value={'new'}>Novo Nivel</option>
                                        {nivelLoc1NewList.map((item, index) => (
                                            <option key={index} value={item._id}>{item.descricao}</option>
                                        ))}
                                    </select>
                                </div>
                                <div>
                                    <label>Nivel 2</label>
                                    <select
                                        value={nivelLoc2New}
                                        onChange={e=> {setNivelLoc2New(e.target.value)}}
                                    >
                                        <option value={''}></option>
                                        <option value={'new'}>Novo Nivel</option>
                                        {nivelLoc2NewList.map((item, index) => (
                                            <option key={index} value={item._id}>{item.descricao}</option>
                                        ))}
                                    </select>
                                </div>
                                <div>
                                    <label> Nivel 3</label>
                                    <select
                                        value={nivelLoc3New}
                                        onChange={e=> {setNivelLoc3New(e.target.value)}}
                                    >
                                        <option value={''}></option>
                                        <option value={'new'}>Novo Nivel</option>
                                        {nivelLoc3NewList.map((item, index) => (
                                            <option key={index} value={item._id}>{item.descricao}</option>
                                        ))}
                                    </select>
                                </div>
                                <div>
                                    <label>Nivel 4</label>
                                    <select
                                        value={nivelLoc4New}
                                        onChange={e=> {setNivelLoc4New(e.target.value)}}
                                    >
                                        <option value={''}></option>
                                        <option value={'new'}>Novo Nivel</option>
                                        {nivelLoc4NewList.map((item, index) => (
                                            <option key={index} value={item._id}>{item.descricao}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div id="flex_1">
                                <div id="flex2">
                                    <div>
                                        <div>
                                            <label>Parceiro</label>
                                            <select
                                                value={parceiroNew}
                                                onChange={e=> {setParceiroNew(e.target.value)}}
                                            >
                                                <option value={''}></option>
                                                <option value={'new'}>Novo Parceiro</option>
                                                {parceiroList.map((item, index) => (
                                                    <option key={index} value={item._id}>{item.nome}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <label>Observação</label>
                                            <input
                                                type="text"
                                                value={observacaoNew}
                                                onChange={e=> {setObservacaoNew(e.target.value)}}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div id="flex3">
                                    <div>
                                        <img id="register"
                                            src={NoImg}
                                            alt="itemImage"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                )
                break
            case 6:
                return (
                    <div >                  
                        <Container fluid>
                            <Col className="d-flex justify-content-between my-2" >
                                <h3>{t('common.collections')} - {t('actions.generate')} CSV</h3>
                                <Button variant="light" onClick={e => {cleaner()}}><FiArrowLeft/>VOLTAR</Button>
                            </Col>
                            {exportType == 0 ? <ExportCSV headers={headersCSV[0]} listItens={csv()} modulo={'coletas'}/> : ''}
                            {exportType == 1 ? <ExportCSV headers={headersCSV[1]} listItens={csvPedido()} modulo={'coletas_pedidos'}/> : ''}
                            {exportType == 2 ? <ExportCSV headers={headersCSV[2]} listItens={csvItens()} modulo={'coletas_itens'}/> : ''}  
                        </Container>
                    </div>
                )
                break
            case 7:
                return (
                    <div >                  
                        <Container fluid>
                            <Row className="my-3 flex-wrap">
                                <Col><h3>{t('actions.generate')} PDF</h3></Col>
                                <Col className="container-btn">
                                    <Button variant="outline-dark" className="m-1" onClick={e => {cleaner()}}>{t('actions.exit')}</Button>
                                </Col>
                            </Row>
                        </Container>
                        {exportType == 0 ? <ExportPDF headers={headersCSV[0]} listItens={csv()} modulo={'coletas'}/> : ''}
                        {exportType == 1 ? <ExportPDF headers={headersCSV[1]} listItens={csvPedido()} modulo={'coletas_pedidos'}/> : ''}
                        {exportType == 2 ? <ExportPDF headers={headersCSV[2]} listItens={csvItens()} modulo={'coletas_itens'}/> : ''}
                    </div>
                )
                break
            default:
                return (
                    <div >Error</div>
                )
                break
        }
    }

    function openCreateItem(){
        api.get(`/itensPage/${sessionStorage.getItem('idConta')}/*/*/${details[0].tag}/*/*/*/*/*/*/*/*/*/*/0`).then(
            response => {
                if(response.data[response.data.length - 1].total_reg == 0) {
                    setTagNew(details[0].tag)
                    setChange(5)
                }else{
                    Swal.fire({
                        title: "Ops... !",
                        text: 'Esta tag já se encontra registrada na base',
                        icon: 'error',
                        showConfirmButton: false,
                        timerProgressBar: true,
                        timer: '2500'
                    })  
                }
            }
        )
    }

    function saveCreateItem(){
        if(categoriaNew == ''){
            window.alert("Ops...\nSelecione ou cadastre uma nova Categoria")
        }else if(tagNew == '') {
            window.alert("Faltou !\nInformar o valor da TAG")
        }else{
            let tagConf = tagNew
            if(!tagConf.includes(':')) {
                tagConf = "000000000000000000000000" + tagConf
                tagConf = tagConf.substr(tagConf.length - 24);
            }
            tagConf = tagConf.toUpperCase()
            let reg = []
            reg.push({
                ativo: ativoNew,
                foto: fotoUp,
                id_categoria: categoriaNew,
                id_conta: sessionStorage.getItem('idConta'),
                id_externo: codExternoNew,
                id_item_vinculado: '',
                id_nivel_loc_1: nivelLoc1New,
                id_nivel_loc_2: nivelLoc2New,
                id_nivel_loc_3: nivelLoc3New,
                id_nivel_loc_4: nivelLoc4New,
                id_parceiro: parceiroNew,
                id_registro: "null",
                inf_compl_1: inf1New,
                inf_compl_2: inf2New,
                inf_compl_3: inf3New,
                inf_compl_4: inf4New,
                inf_compl_5: inf5New,
                lido_registro: '1',
                observacao: observacaoNew,
                tag: tagConf,
                up_1: '',
                up_2: '',
                up_3: '',
                up_4: '',
                up_5: '',
                _id: '0'
            })

            let option = {
                headers: { 'Content-Type': ['application/json'] }
            }

            api.post('/itemBO', reg, option
            ).then(
                response=> {
                    window.alert("Registrado !\nItem salvo com sucesso")
                    closeCreateItem(true)
                },
                response=> {
                    window.alert(`Temos um problema...`)
                }
            )
        }
    }

    function closeCreateItem(save){
        if(save){
            setChange(2)
        }else{
            setChange(3)
        }
        setCategoriaNew('')
        setTagNew('')
        setAtivoNew('1')
        setInf1New('')
        setInf2New('')
        setInf3New('')
        setInf4New('')
        setInf5New('')
        setNivelLoc1New('')
        setNivelLoc2New('')
        setNivelLoc3New('')
        setNivelLoc4New('')
        setParceiroNew('')
        setObservacaoNew('')
        setCodExternoNew('')
        setCategoriaList([])
    }

    function itemDetail(item, info) {
        setDetails([item, info])
        if(item.tag != '') {
            //api.get(`itemVinculado/${sessionStorage.getItem('idConta')}/*/${item.tag}`).then(
            api.get(`item_tag_inf/${sessionStorage.getItem('idConta')}/${item.tag}`).then(
                response => {
                    if(response.data.length == 0) {
                        setItemDetails([
                            {
                                "ativo": 1,
                                "lido_registro": 1,
                                "_id": "",
                                "id_conta": sessionStorage.getItem('idConta'),
                                "id_externo": "",
                                "id_item_vinculado": "",
                                "tag": "",
                                "id_categoria": null,
                                "inf_compl_1": "",
                                "inf_compl_2": "",
                                "inf_compl_3": "",
                                "inf_compl_4": "",
                                "inf_compl_5": "",
                                "id_nivel_loc_1": null,
                                "id_nivel_loc_2": null,
                                "id_nivel_loc_3": null,
                                "id_nivel_loc_4": null,
                                "foto": "",
                                "observacao": "",
                                "id_parceiro": null,
                                "id_registro": null
                            }
                        ])
                    }else {
                        setItemDetails(response.data)
                    }
                },
                response => {
                    setItemDetails([
                        {
                            "ativo": 1,
                            "lido_registro": 1,
                            "_id": "",
                            "id_conta": sessionStorage.getItem('idConta'),
                            "id_externo": "",
                            "id_item_vinculado": "",
                            "tag": "",
                            "id_categoria": null,
                            "inf_compl_1": "",
                            "inf_compl_2": "",
                            "inf_compl_3": "",
                            "inf_compl_4": "",
                            "inf_compl_5": "",
                            "id_nivel_loc_1": null,
                            "id_nivel_loc_2": null,
                            "id_nivel_loc_3": null,
                            "id_nivel_loc_4": null,
                            "foto": "",
                            "observacao": "",
                            "id_parceiro": null,
                            "id_registro": null
                        }
                    ])
                }
            ).finally(
                () => {
                    setChange(3)
                }
            )
        }else {
            setItemDetails([
                {
                    "ativo": 1,
                    "lido_registro": 1,
                    "_id": "",
                    "id_conta": sessionStorage.getItem('idConta'),
                    "id_externo": "",
                    "id_item_vinculado": "",
                    "tag": "",
                    "id_categoria": null,
                    "inf_compl_1": "",
                    "inf_compl_2": "",
                    "inf_compl_3": "",
                    "inf_compl_4": "",
                    "inf_compl_5": "",
                    "id_nivel_loc_1": null,
                    "id_nivel_loc_2": null,
                    "id_nivel_loc_3": null,
                    "id_nivel_loc_4": null,
                    "foto": "",
                    "observacao": "",
                    "id_parceiro": null,
                    "id_registro": null
                }
            ])
            setChange(3)
        }
    }

    function changeNewFunction(key) {
        switch (key) {
            case 0:
                return (
                    <div>
                        <Row className='my-3'>
                            {nivelLoc1Coleta == 'new' ? (
                                <div className='extra'>
                                <div className='extracontainer'>
                                    <div className="title">
                                        <h1>Nivel 1</h1>
                                    </div>
                                    <div>
                                        <input
                                            value={inputNew}
                                            onChange={e=> {setInputNew(e.target.value)}}
                                        />
                                    </div>
                                    <div className='close'>
                                        <button onClick={e=> {novoNivel1()}}>SALVAR</button>
                                        <button onClick={e=> {novoNivel1('fechar')}}>FECHAR</button>
                                    </div>
                                </div>
                            </div>
                            ) : ''}
                            {nivelLoc2Coleta == 'new' ? (
                                <div className='extra'>
                                <div className='extracontainer'>
                                    <div className="title">
                                        <h1>Nivel 2</h1>
                                    </div>
                                    <div>
                                        <input
                                            value={inputNew}
                                            onChange={e=> {setInputNew(e.target.value)}}
                                        />
                                    </div>
                                    <div className='close'>
                                        <button onClick={e=> {novoNivel2()}}>SALVAR</button>
                                        <button onClick={e=> {novoNivel2('fechar')}}>FECHAR</button>
                                    </div>
                                </div>
                            </div>
                            ) : ''}
                            {nivelLoc3Coleta == 'new' ? (
                                <div className='extra'>
                                <div className='extracontainer'>
                                    <div className="title">
                                        <h1>Nivel 3</h1>
                                    </div>
                                    <div>
                                        <input
                                            value={inputNew}
                                            onChange={e=> {setInputNew(e.target.value)}}
                                        />
                                    </div>
                                    <div className='close'>
                                        <button onClick={e=> {novoNivel3()}}>SALVAR</button>
                                        <button onClick={e=> {novoNivel3('fechar')}}>FECHAR</button>
                                    </div>
                                </div>
                            </div>
                            ) : ''}
                            {nivelLoc4Coleta == 'new' ? (
                                <div className='extra'>
                                <div className='extracontainer'>
                                    <div className="title">
                                        <h1>Nivel 4</h1>
                                    </div>
                                    <div>
                                        <input
                                            value={inputNew}
                                            onChange={e=> {setInputNew(e.target.value)}}
                                        />
                                    </div>
                                    <div className='close'>
                                        <button onClick={e=> {novoNivel4()}}>SALVAR</button>
                                        <button onClick={e=> {novoNivel4('fechar')}}>FECHAR</button>
                                    </div>
                                </div>
                            </div>
                            ) : ''}
                            {parceiroColeta == 'new' ? (
                                <div className='extra'>
                                <div className='extracontainer'>
                                    <div className="title">
                                        <h1>Parceiro</h1>
                                    </div>
                                    <div>
                                        <input
                                            value={inputNew}
                                            onChange={e=> {setInputNew(e.target.value)}}
                                        />
                                    </div>
                                    <div className='close'>
                                        <button onClick={e=> {novoParceiro()}}>SALVAR</button>
                                        <button onClick={e=> {novoParceiro('fechar')}}>FECHAR</button>
                                    </div>
                                </div>
                            </div>
                            ) : ''}
                            <Col className="d-flex align-items-center" md={2}>
                                    <img id="register" src={foto} alt="No Image"/>
                            </Col>
                            <Col>
                                <Row className="g-3">
                                    <Form.Group as={Col} md={3}>
                                        <Form.Label>Modo</Form.Label>
                                        <Form.Select
                                            value={modoColeta}
                                            disabled
                                        >
                                            <option value={'p'}>Pedido</option>
                                            <option value={'l'}>Livre</option>
                                            <option value={'es'}>Estoque</option>
                                            <option value={"il"}>Localização</option>
                                            <option value={"ic"}>Categorias</option>
                                            <option value={"ip"}>Parceiro</option>
                                            <option value={"ifl"}>Filtragem Localizada</option>
                                            <option value={"ilc"}>Localização por Tag</option>
                                            <option value={"ico"}>Coleta</option>
                                            <option value={"ir"}>Remessa</option>
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group as={Col} md={3}>
                                        <Form.Label>Identificador</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={identificadorColeta}
                                            onChange={e=> {setIdentificadorColeta(e.target.value)}}
                                            disabled
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md={3}>
                                        <Form.Label>Usuário</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={usuarioColeta}
                                            onChange={e=> {setUsuarioColeta(e.target.value)}}
                                            disabled
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md={3}>
                                        <Form.Label>Leitor</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={leitorColeta}
                                            onChange={e=> {setLeitorColeta(e.target.value)}}
                                            disabled
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md={3}>
                                        <Form.Label>Status</Form.Label>
                                        <Form.Select
                                            value={statusColeta}
                                            onChange={e=> {setStatusColeta(e.target.value)}}
                                        >
                                            <option value={'1'}>Ativa</option>
                                            <option value={'0'}>Cancelada</option>
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group as={Col} md={3}>
                                        <Form.Label>Quantidade Estimada</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={qtdEstimada}
                                            onChange={e=> {setQtdEstimada(e.target.value)}}
                                            disabled
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md={3}>
                                        <Form.Label>Encontrado</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={encontradoColeta}
                                            disabled
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md={3}>
                                        <Form.Label>Excedente</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={excedenteColeta}
                                            disabled
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md={3}>
                                        <Form.Label>Data Registro</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={formatDatecsv(dtRegistro)}
                                            disabled
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md={3}>
                                        <Form.Label>Data Alteração</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={formatDatecsv(dtAlteracao)}
                                            disabled
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} sm={3}>
                                        <Form.Label>Tempo de Leitura</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={tempoInventario}
                                            disabled />
                                    </Form.Group>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="g-3">
                            <Form.Group as={Col} md={3}>
                                <Form.Label><b className="focus">Itens do</b> Nivel 1</Form.Label>
                                <Form.Select
                                    value={nivelLoc1Coleta}
                                    onChange={e=> {setNivelLoc1Coleta(e.target.value)}}
                                    disabled
                                >
                                    <option value={'*'}></option>
                                    <option value={'new'}>Novo Nivel</option>
                                    {nivelLoc1ListColeta.map((item, index) => (
                                        <option key={index} value={item._id}>{item.descricao}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                            <Form.Group as={Col} md={3}>
                                <Form.Label>Nivel 2</Form.Label>
                                <Form.Select
                                    value={nivelLoc2Coleta}
                                    onChange={e=> {setNivelLoc2Coleta(e.target.value)}}
                                    disabled
                                >
                                    <option value={'*'}></option>
                                    <option value={'new'}>Novo Nivel</option>
                                    {nivelLoc2ListColeta.map((item, index) => (
                                        <option key={index} value={item._id}>{item.descricao}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                            <Form.Group as={Col} md={3}>
                                <Form.Label> Nivel 3</Form.Label>
                                <Form.Select
                                    value={nivelLoc3Coleta}
                                    onChange={e=> {setNivelLoc3Coleta(e.target.value)}}
                                    disabled
                                >
                                    <option value={'*'}></option>
                                    <option value={'new'}>Novo Nivel</option>
                                    {nivelLoc3ListColeta.map((item, index) => (
                                        <option key={index} value={item._id}>{item.descricao}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                            <Form.Group as={Col} md={3}>
                                <Form.Label>Niverl 4</Form.Label>
                                <Form.Select
                                    value={nivelLoc4Coleta}
                                    onChange={e=> {setNivelLoc4Coleta(e.target.value)}}
                                    disabled
                                >
                                    <option value={'*'}></option>
                                    <option value={'new'}>Novo Nivel</option>
                                    {nivelLoc4ListColeta.map((item, index) => (
                                        <option key={index} value={item._id}>{item.descricao}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                            <Form.Group as={Col} md={3}>
                                <Form.Label>Parceiro</Form.Label>
                                <Form.Select
                                    value={parceiroColeta}
                                    onChange={e=> {setParceiroColeta(e.target.value)}}
                                    disabled
                                >
                                    <option value={'*'}></option>
                                    <option value={'new'}>Novo Parceiro</option>
                                    {parceiroList.map((item, index) => (
                                        <option key={index} value={item._id}>{item.nome}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                            <Form.Group as={Col} md={3}>
                                <Form.Label>EAN</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={eanColeta}
                                    onChange={e=> {setEanColeta(e.target.value)}}
                                    disabled
                                />
                            </Form.Group>
                            <Form.Group as={Col} md={3}>
                                <Form.Label>Observação</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={observacaoColeta}
                                    onChange={e=> {setObservacaoColeta(e.target.value)}}
                                />
                            </Form.Group>
                        </Row>
                    </div>
                )
                break
            case 1:
                return (
                    <div>
                        <Row className="my-3 flex-wrap">
                            <Col><h3>{t('common.demand')}</h3></Col>
                            <Col className="container-btn">
                                <Button variant="custom-primary" onClick={e=> {
                                    setChange(7)
                                    setExportType(1)
                                }}><FaFilePdf className="dIcons"/>{t('actions.export')} PDF</Button>
                                <Button variant="custom-primary" onClick={e=> {
                                    setChange(6)
                                    setExportType(1)
                                }}><FaFileCsv className="dIcons"/>{t('actions.export')} CSV</Button>
                            </Col>
                        </Row>
                        {!pedidoLoaded ?
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                                <ReactLoading type={'bars'} color={'#2488f7'} height={150} width={150}/>
                            </div>
                        :
                            <div className="list">
                                <Table responsive hover>
                                    <thead>    
                                        <tr id="header">
                                            <th>#</th>
                                            <th>{t('common.categories')}</th>
                                            <th>EAN</th>
                                            <th>{t('common.count')}</th>
                                            <th>{t('common.readed')}</th>
                                            <th>{t('common.audited')}</th>
                                            <th>
                                                <Form.Group>
                                                    <Form.Select
                                                        value={pedidoFilter}
                                                        onChange={e=> {setPedidoFilter(e.target.value)}}
                                                    >
                                                        <option value="Todas">{t('common.all')}</option>
                                                        <option value="Correto">{t('common.correct')}</option>
                                                        <option value="Faltante">{t('common.missing')}</option>
                                                        <option value="Excedente">{t('common.excess')}</option>
                                                    </Form.Select>
                                                </Form.Group>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {pedidoListItens.map((item, index) => {
                                            let info = ''
                                            if(Number(item.quantidade) == Number(item.quantidade_lida)) {
                                                info = 'Correto'
                                            }
                                            else if(Number(item.quantidade) > Number(item.quantidade_lida)) {
                                                info = 'Faltante'
                                            }
                                            else if(Number(item.quantidade) < Number(item.quantidade_lida)) {
                                                info = 'Excedente'
                                            }
                                            if(pedidoFilter == "Todas" || pedidoFilter == info) {
                                                return (
                                                    <tr key={index}>
                                                        <th>{index + 1}</th>
                                                        <th>{item.descricao}</th>
                                                        <th>{item.ean}</th>
                                                        <th>{item.quantidade}</th>
                                                        <th>{item.quantidade_lida}</th>
                                                        <th>{item.quantidade_auditada}</th>
                                                        <th>{info}</th>
                                                    </tr>
                                                )
                                            }
                                        })}
                                    </tbody>
                                </Table>
                            </div>
                        }
                    </div>
                )
                break
            case 2:
                return (
                    <div>
                        <Row className="my-3 flex-wrap">
                            <Col><h3>{t('common.items')}</h3></Col>
                            {listItemsLoaded ?
                                <Col className="container-btn">
                                    <Button 
                                        variant="outline-dark"
                                        className='dicons' 
                                        onClick={e => {markersLoader(tagListItens)}}
                                    ><FaMapMarkedAlt className="me-2"/>{t('actions.map')}</Button>
                                    <Button onClick={e=> {
                                        setChange(7)
                                        setExportType(2)
                                    }}><FaFilePdf className="me-2"/>{t('actions.export')} PDF</Button>
                                    <Button onClick={e=> {
                                        setChange(6)
                                        setExportType(2)
                                    }}><FaFileCsv className="me-2"/> {t('actions.export')} CSV</Button>
                                </Col>
                            :''}
                        </Row>
                        {listItemsLoaded && !showMap?
                            <DashTest
                                data={statusList}
                                colors={colorInfo}
                                dataItens={dashItens}
                            />
                        : ''}
                        {listItemsLoaded ?
                        <div className='counter'>
                            <div className='import-info' style={{display: 'flex', flexDirection: 'row', width:'100%', margin: 'auto', flexWrap: 'wrap', justifyContent: 'center'}}>
                                <div className='content-info' style={{display: 'flex', flexDirection: 'row', whiteSpace: 'nowrap', width:'auto', margin: '5px 0'}}>
                                    <div className='block-info' style={{backgroundColor: '#B40C0C', width:20, height:20}} /> Ausentes: {statusList[0]?.value}
                                </div>
                                <div className='content-info' style={{display: 'flex', flexDirection: 'row', whiteSpace: 'nowrap', width:'auto', margin: '5px 0'}}>
                                    <div className='block-info' style={{backgroundColor: '#4EA90B', width:20, height:20}} /> Encontrados: {statusList[1]?.value}
                                </div>
                                <div className='content-info' style={{display: 'flex', flexDirection: 'row', whiteSpace: 'nowrap', width:'auto', margin: '5px 0'}}>
                                    <div className='block-info' style={{backgroundColor: '#A33BBF', width:20, height:20}} /> Excedentes: {statusList[2]?.value}
                                </div>
                                <div className='content-info' style={{display: 'flex', flexDirection: 'row', whiteSpace: 'nowrap', width:'auto', margin: '5px 0'}}>
                                    <div className='block-info' style={{backgroundColor: '#E3AE1B', width:20, height:20}} /> Outros Ambientes: {statusList[3]?.value}
                                </div>
                                <div className='content-info' style={{display: 'flex', flexDirection: 'row', whiteSpace: 'nowrap', width:'auto', margin: '5px 0'}}>
                                    <div className='block-info' style={{backgroundColor: '#08B08C', width:20, height:20}} /> Auditados: {statusList[4]?.value}
                                </div>
                                <div className='content-info' style={{display: 'flex', flexDirection: 'row', whiteSpace: 'nowrap', width:'auto', margin: '5px 0'}}>
                                    <div className='block-info' style={{backgroundColor: '#26C6DA', width:20, height:20}} /> Incorporados: {statusList[5]?.value}
                                </div>
                            </div>
                        </div> : ''}
                        {!listItemsLoaded ?
                            <div style={{display: 'flex', justifyContent: 'center', marginTop:'80px'}}>
                                <ReactLoading type={'bars'} color={'#2488f7'} height={150} width={150}/>
                            </div>
                        :
                            <div className="list">
                                <Table responsive hover>
                                    <thead>
                                        <tr id="header">
                                            <th>#</th>
                                            <th>{t('common.items')}</th>
                                            <th>{t('common.locate')}</th>
                                            <th></th>
                                            <th>
                                                <select
                                                    value={tagFilter}
                                                    onChange={e=> {setTagFilter(e.target.value)}}
                                                >
                                                    <option value="Todas">{t('common.all')}</option>
                                                    <option value="Encontrado">{t('common.found')}</option>
                                                    <option value="Ausente">{t('common.absent')}</option>
                                                    <option value="Auditado">{t('common.audited')}</option>
                                                    <option value="Excedente">{t('common.excess')}</option>
                                                    <option value="Outro_Ambiente">{t('common.other_locate')}</option>
                                                    <option value="Incorporado">{t('common.incorporated')}</option>
                                                </select>
                                            </th>
                                            <th><ImCompass2 className="table_icons"/></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tagListItens.map((item, index) => {
                                            let info = ""
                                            if(item.encontrado == 0 && item.excedente == 0) {
                                                info = "Ausente"
                                            }
                                            if(item.encontrado == 1 && item.excedente == 0) {
                                                info = "Encontrado"
                                            }
                                            if(item.excedente == 1) {
                                                info = "Excedente"
                                            }
                                            if(item.excedente == 1 && item.id_item && (item.id_item != '0' || item.descricao != "SEM DESCRICAO")) {
                                                info = "Outro_Ambiente"
                                            }
                                            if(item.auditado == 1) {
                                                info = "Auditado"
                                            }
                                            if(item.incorporado == 1) {
                                                info = 'Incorporado'
                                            }
                                            if(tagFilter == "Todas" || info == tagFilter) {
                                                return (
                                                    <tr
                                                        className='edit'
                                                        key={index}
                                                    >
                                                        <th onClick={e=> {itemDetail(item, info)}}>{index + 1}</th>
                                                        <th onClick={e=> {itemDetail(item, info)}}>
                                                            <div>{item.descricao}</div>
                                                            <div>{item.tag}</div>
                                                        </th>
                                                        <th onClick={e=> {itemDetail(item, info)}}>
                                                            <div>{item?.nivel_loc_1 ?? ''}</div>
                                                            <div>{item?.nivel_loc_2 ?? ''}</div>
                                                            <div>{item?.nivel_loc_3 ?? ''}</div>
                                                            <div>{item?.nivel_loc_4 ?? ''}</div>
                                                        </th>
                                                        <th onClick={e=> {itemDetail(item, info)}}>
                                                            {
                                                                item.foto && !fotoNone.includes(item.foto) ?
                                                                <img style={{borderRadius: '10px'}} src={`https://api.inovacode.app.br/${item.foto}`} alt='CollectItemImage'/> : ''
                                                            }
                                                        </th>
                                                        <th onClick={e=> {itemDetail(item, info)}}>
                                                            <div style={{padding: '10px', background: colorGet(info), width: '120px', textAlign: 'center', borderRadius: '10px', color: '#F8F8F8', fontWeight: 'bold'}}>{info}</div>
                                                            <div>{item.dt_leitura}</div>
                                                        </th>
                                                        <th>{item.longitude ? <GoLocation className="table_icons show_map" onClick={e => {markersLoader([item])}}/> : ''}</th>
                                                    </tr>
                                                )
                                            }
                                        })}
                                    </tbody>
                                </Table>
                            </div>
                        }
                    </div>
                )
                break
            case 3:
                return (
                    <div style={{marginTop: '20px'}}>
                        <GoogleMap
                            className='mapStyles'
                            mapContainerStyle={mapContainerStyle}
                            zoom={18}
                            center={{lat: Number.parseFloat(latitude), lng: Number.parseFloat(longitude)}}
                        >
                            <Marker
                                position={{
                                    lat: Number.parseFloat(latitude),
                                    lng: Number.parseFloat(longitude)
                                }}
                                icon={{
                                    url: '/iconMap.png',
                                    scaledsize: new window.google.maps.Size(32, 30)
                                }}
                            />
                        </GoogleMap>
                    </div>
                )
                break
            case 4:
                return (
                    <div className="list_account"></div>
                )
                break
            case 5:
                return (
                    <div>
                        {leitorColetaInfo ?
                        <div className="list_account">
                            <div className='infs'>
                                <div>
                                    <label>Nome Leitor</label>
                                    <input
                                        type="text"
                                        value={leitorColetaInfo.nm_leitor}
                                        className='blocked'
                                        disabled
                                        />
                                </div>
                                <div>
                                    <label>Serial</label>
                                    <input
                                        type="text"
                                        value={leitorColetaInfo.serial}
                                        className='blocked'
                                        disabled
                                        />
                                </div>
                                <div>
                                    <label>Ativo</label>
                                    <select
                                        value={leitorColetaInfo.ativo}
                                        className='blocked'
                                        disabled
                                        >
                                        <option value="0">Não</option>
                                        <option value="1">Sim</option>
                                    </select>
                                </div>
                            </div>
                            <div className='infs'>
                                <div>
                                    <label>Periodo - Dias</label>
                                    <input
                                        type="number"
                                        min='0'
                                        value={leitorColetaInfo.periodo}
                                        className='blocked'
                                        disabled
                                        />
                                </div>
                                <div>
                                    <label>Inf. Compl 1</label>
                                    <input
                                        type="text"
                                        value={leitorColetaInfo.inf_compl_1}
                                        className='blocked'
                                        disabled
                                        />
                                </div>
                                <div>
                                    <label>Inf. Compl 2</label>
                                    <input
                                        type="text"
                                        value={leitorColetaInfo.inf_compl_2}
                                        className='blocked'
                                        disabled
                                        />
                                </div>
                            </div>
                            <div className='infs'>
                                <div>
                                    <label>Modelo</label>
                                    <select
                                        value={leitorColetaInfo.leitor_modelo}
                                        className='blocked'
                                        disabled
                                        >
                                        <option value=""></option>
                                        <option value="blu">Bluebird</option>
                                        <option value="dot">Dot 900</option>
                                        <option value="301">i300</option>
                                        <option value="bu1">Clock B1</option>
                                        <option value="tsl">Tsl 1128</option>
                                        <option value="imp">Impinj</option>
                                        <option value="inn">InovaCode</option>
                                        <option value="mdl">Midleware</option>
                                        <option value="nfc">NFC</option>  
                                        <option value="ebc">eBeacon</option> 
                                        <option value="ter">Terminal</option> 
                                        <option value="csv">CSV</option>
                                    </select>
                                </div>
                                <div>
                                    <label>Potência</label>
                                    <select
                                        value={leitorColetaInfo.leitor_potencia}
                                        className='blocked'
                                        disabled
                                        >
                                        <option value=""></option>
                                        <option value="5">Baixa</option>
                                        <option value="20">Média</option>
                                        <option value="30">Alta</option>
                                    </select>
                                </div>
                            </div>
                            <div className='infs'>
                                <div>
                                    <label>Seção</label>
                                    <select
                                        value={leitorColetaInfo.leitor_secao}
                                        className='blocked'
                                        disabled
                                        >
                                        <option value=""></option>
                                        <option value="0">Seção 0</option>
                                        <option value="1">Seção 1</option>
                                        <option value="2">Seção 2</option>
                                        <option value="3">Seção 3</option>
                                    </select>
                                </div>
                                <div>
                                    <label>Estado</label>
                                    <select
                                        value={leitorColetaInfo.leitor_estado}
                                        className='blocked'
                                        disabled
                                        >
                                        <option value=""></option>
                                        <option value="0">A</option>
                                        <option value="1">A~B</option>
                                        <option value="2">B</option>
                                    </select>
                                </div>
                            </div>
                            <div className='infs'>
                                <div>
                                    <label>Impressora</label>
                                    <input
                                        type="text"
                                        value={leitorColetaInfo.impressora_serial}
                                        className='blocked'
                                        disabled
                                        />
                                </div>
                            </div>
                            <div className='infs'>
                                <div>
                                    <label>Padrão</label>
                                    <select
                                        value={leitorColetaInfo.item_padrao}
                                        className='blocked'
                                        disabled
                                        >
                                        <option value=""></option>
                                        <option value="manual">Manual</option>
                                        <option value="gtin">GTIN</option>
                                        <option value="sgtin">sGTIN</option>
                                        <option value="one">InovaOne</option>
                                        <option value="epc">EPC API</option>
                                    </select>
                                </div>
                                <div>
                                    <label>Verificador Inicial</label>
                                    <input
                                        type="text"
                                        value={leitorColetaInfo.item_verif_inicial}
                                        className='blocked'
                                        disabled
                                    />
                                </div>
                                <div>
                                    <label>Posição</label>
                                    <input
                                        type="text"
                                        value={leitorColetaInfo.item_posicao}
                                        className='blocked'
                                        disabled
                                    />
                                </div>
                                <div>
                                    <label>Comprimento</label>
                                    <input
                                        type="text"
                                        value={leitorColetaInfo.item_comprimento}
                                        className='blocked'
                                        disabled
                                        />
                                </div>
                            </div>
                            <div className='infs'>
                                <div>
                                    <label>Finalidade</label>
                                    <select
                                        value={leitorColetaInfo.reg_coleta}
                                        className='blocked'
                                        disabled
                                    >
                                        <option value='1'>Coletas</option>
                                        <option value='0'>Inventários</option>
                                    </select>
                                </div>
                            </div>
                            <div className='infs'>
                                <div>
                                    <label>Observação</label>
                                    <input
                                        type="text"
                                        value={leitorColetaInfo.observacao}
                                        className='blocked'
                                        disabled
                                    />
                                </div>
                            </div>
                        </div>
                        :
                        <div style={{textAlign: 'center', marginTop: '150px', fontWeight:'bold', fontSize: '40px'}}>Leitor Não Especificado</div>
                    }
                    </div>
                )
                break
            default:
                return (
                    <div >Error</div>
                )
                break
        }
    }

    function colorGet(stat) {
        let color = ''
        if(stat == "Excedente") {
            color = '#A33BBF'
        }else if(stat == "Outro_Ambiente") {
            color = '#E3AE1B'
        }else if(stat == "Auditado") {
            color = '#08B08C'
        }else if(stat == "Encontrado") {
            color = '#4EA90B'
        }else if(stat == "Ausente") {
            color = '#B40C0C'
        }else if(stat == "Incorporado") {
            color = '#26C6DA'
        }

        return color
    }

    function novoNivel1(fechar) {
        if(fechar == 'fechar') {
            setAlertNew('')
            setInputNew('')
            setNivelLoc1Coleta('')
        }else {
            let reg = []
            reg.push({
                ativo: 1,
                descricao: inputNew,
                id_conta: sessionStorage.getItem('idConta'),
                id_externo: "",
                id_nivel_loc1: "0",
                observacao: "",
                tag: "",
            })
            
            let option = {
                headers: { 'Content-Type': ['application/json'] }
            }
    
            api.post('/nivel_loc1', reg, option).then(
                response=> {
                    setInputNew('')
                    setAlertNew('')
                    nivel1Get()
                    nivel1GetList()
                    nivel1GetListNew()
                    setNivelLoc1Coleta(response.data[0]._id)
    
                },
                response=> {
                    setAlertNew(`Temos um problema...\n${response.data.error}`)
                }
            )
        }
    }
    
    function novoNivel2(fechar) {
        if(fechar == 'fechar') {
            setAlertNew('')
            setInputNew('')
            setNivelLoc2Coleta('')
        }else {
            let reg = []
            reg.push({
                ativo: 1,
                descricao: inputNew,
                id_conta: sessionStorage.getItem('idConta'),
                id_externo: "",
                id_nivel_loc1: nivelLoc1Coleta,
                id_nivel_loc2: "0",
                observacao: "",
                tag: "",
            })
            
            let option = {
                headers: { 'Content-Type': ['application/json'] }
            }
    
            api.post('/nivel_loc2', reg, option).then(
                response=> {
                    setInputNew('')
                    setAlertNew('')
                    nivel2Get(nivelLoc1Coleta)
                    setNivelLoc2Coleta(response.data[0]._id)
    
                },
                response=> {
                    setAlertNew(`Temos um problema...\n${response.data.error}`)
                }
            )
        }
    }
    
    function novoNivel3(fechar) {
        if(fechar == 'fechar') {
            setAlertNew('')
            setInputNew('')
            setNivelLoc3Coleta('')
        }else {
            let reg = []
            reg.push({
                ativo: 1,
                descricao: inputNew,
                id_conta: sessionStorage.getItem('idConta'),
                id_externo: "",
                id_nivel_loc2: nivelLoc2Coleta,
                id_nivel_loc3: "0",
                observacao: "",
                tag: ""
            })
            
            let option = {
                headers: { 'Content-Type': ['application/json'] }
            }
    
            api.post('/nivel_loc3', reg, option).then(
                response=> {
                    setInputNew('')
                    setAlertNew('')
                    nivel3Get(nivelLoc2Coleta)
                    setNivelLoc3Coleta(response.data[0]._id)
    
                },
                response=> {
                    setAlertNew(`Temos um problema...\n${response.data.error}`)
                }
            )
        }
    }
    
    function novoNivel4(fechar) {
        if(fechar == 'fechar') {
            setAlertNew('')
            setInputNew('')
            setNivelLoc4Coleta('')
        }else {
            let reg = []
            reg.push({
                ativo: 1,
                descricao: inputNew,
                id_conta: sessionStorage.getItem('idConta'),
                id_externo: "",
                id_nivel_loc3: nivelLoc3Coleta,
                id_nivel_loc4: "0",
                observacao: "",
                tag: ""
            })
            
            let option = {
                headers: { 'Content-Type': ['application/json'] }
            }
    
            api.post('/nivel_loc4', reg, option).then(
                response=> {
                    setInputNew('')
                    setAlertNew('')
                    nivel4Get(nivelLoc3Coleta)
                    setNivelLoc4Coleta(response.data[0]._id)
    
                },
                response=> {
                    setAlertNew(`Temos um problema...\n${response.data.error}`)
                }
            )
        }
    }
    
    function novoParceiro(fechar) {
        if(fechar == 'fechar') {
            setAlertNew('')
            setInputNew('')
            setParceiroColeta('')
        }else {
            let reg = []
            reg.push({
                ativo: 1,
                endereco: "",
                id_conta: sessionStorage('idConta'),
                id_externo: "",
                id_parceiro: "0",
                nome: inputNew,
                observacao: "",
                telefone: "",
                uuid: ""
            })
            
            let option = {
                headers: { 'Content-Type': ['application/json'] }
            }
    
            api.post('/parceiro', reg, option).then(
                response=> {
                    setInputNew('')
                    setAlertNew('')
                    parceiroGet()
                    setParceiroColeta(response.data[0]._id)
    
                },
                response=> {
                    setAlertNew(`Temos um problema...\n${response.data.error}`)
                }
            )
        }
    }

    function editItem(item) {
        setListItemsLoaded(false)
        setPedidoLoaded(false)
        setChange(2)
        setChangeNew(2) //teste
        setEditing(1)
        setUsuarioColeta(item.id_usuario ? item.id_usuario.nome : '')
        setLeitorColeta(item.id_leitor ? item.id_leitor.nm_leitor : '')
        setLeitorColetaInfo(item.id_leitor ? item.id_leitor : null)
        setIdRegistro(item._id)
        setTipoRegistro(item.id_tipo_registro)
        setModoColeta(item.modo)
        setIdentificadorColeta(item.identificador ? item.identificador : '')
        setStatusColeta(item.status)
        setQtdEstimada(item.quantidade)
        setEncontradoColeta(item.encontrado)
        setExcedenteColeta(item.excedente)
        seDtRegistro(item.dt_registro)
        setDtAlteracao(item.dt_alteracao)
        setTempoInventario(item.tempo_inventario)
        setLatitude(!item.latitude || item.latitude == '0' ? '-23.485230351504622' : item.latitude)
        setLongitude(!item.longitude || item.longitude == '0' ? '-46.72570559924746' : item.longitude)
        setNivelLoc1Coleta(item.id_nivel_loc_1 ? item.id_nivel_loc_1._id : '*')
        if(item.id_nivel_loc_1) {
            nivel2Get(item.id_nivel_loc_1._id)
        }
        setNivelLoc2Coleta(item.id_nivel_loc_2 ? item.id_nivel_loc_2._id : '*')
        if(item.id_nivel_loc_2) {
            nivel3Get(item.id_nivel_loc_2._id)
        }
        setNivelLoc3Coleta(item.id_nivel_loc_3 ? item.id_nivel_loc_3._id : '*')
        if(item.id_nivel_loc_3) {
            nivel4Get(item.id_nivel_loc_3._id)
        }
        setNivelLoc4Coleta(item.id_nivel_loc_4 ? item.id_nivel_loc_4._id : '*')
        setParceiroColeta(item.id_parceiro ? item.id_parceiro._id : '*')
        setEanColeta(item.ean)
        setObservacaoColeta(item.observacao != '' ? item.observacao : '')
        setFoto(item.foto && !fotoNone.includes(item.foto)
        ? "https://api.inovacode.app.br/" + item.foto : NoImg)
        api.get(`/registro_pedido/*/${item._id}`, {}
        ).then(response => {setPedidoListItens(response.data)}).finally(
            ()=>{setPedidoLoaded(true)}
        )
        api.get(`/registro_tag/*/${item._id}/0`, {}
        ).then(response => {
            setTagListItens(response.data)
            let dashItemList = []
            let preListStatus = [
                { name: 'Ausentes', value: 0 },
                { name: 'Encontrados', value: 0 },
                { name: 'Excedentes', value: 0 },
                { name: 'Outros Amb.', value: 0 },
                { name: 'Auditados', value: 0 },
                { name: 'Incorporados', value: 0 }
            ]
            response.data.map((itemToStatus) => {
                let pos = -1
                if(itemToStatus.encontrado == 0 && itemToStatus.excedente == 0) {
                    pos = 0
                }
                if(itemToStatus.encontrado == 1 && itemToStatus.excedente == 0) {
                    pos = 1
                }
                if(itemToStatus.excedente == 1) {
                    pos = 2
                }
                if(itemToStatus.excedente == 1 && itemToStatus.id_item && (itemToStatus.id_item != '0' || itemToStatus.descricao != "SEM DESCRICAO")) {
                    pos = 3
                }
                if(itemToStatus.auditado == 1) {
                    pos = 4
                }
                if(itemToStatus.incorporado == 1) {
                    pos = 5
                }

                dashItemList.push({
                    'status': preListStatus[pos].name,
                    'Categorias': itemToStatus.descricao,
                    'Niveis 1': itemToStatus.id_item && itemToStatus.id_item.id_nivel_loc_1 ? itemToStatus.id_item.id_nivel_loc_1.descricao : 'N/A',
                    'Niveis 2': itemToStatus.id_item && itemToStatus.id_item.id_nivel_loc_2 ? itemToStatus.id_item.id_nivel_loc_2.descricao : 'N/A',
                    'Niveis 3': itemToStatus.id_item && itemToStatus.id_item.id_nivel_loc_3 ? itemToStatus.id_item.id_nivel_loc_3.descricao : 'N/A',
                    'Niveis 4': itemToStatus.id_item && itemToStatus.id_item.id_nivel_loc_4 ? itemToStatus.id_item.id_nivel_loc_4.descricao : 'N/A'
                })
                preListStatus[pos].value += 1
            })
            setDashItens(dashItemList)
            setStatusList(preListStatus)
        }).finally(
            ()=>{setListItemsLoaded(true)}
        )
        api.get(`/itemVinculado/${sessionStorage.getItem('idConta')}/${item._id}/*`, {}
        ).then(response => {setVinculadosList(response.data)})
    }

    function cleaner() {
        setExportType(-1)
        addOrRemoveExportFilter('Todos')
        setChange(0)
        setChangeNew(0)
        setEditing(0)
        setUsuarioColeta('')
        setLeitorColeta('')
        setLeitorColetaInfo(null)
        setModoColeta('p')
        setIdRegistro('0')
        setTipoRegistro('')
        setIdentificadorColeta('')
        setStatusColeta('')
        setQtdEstimada('0')
        setEncontradoColeta('')
        setExcedenteColeta('')
        seDtRegistro(now())
        setDtAlteracao(now())
        setLatitude('-23.485230351504622')
        setLongitude('-46.72570559924746')
        setNivelLoc1Coleta('*')
        setNivelLoc2Coleta('*')
        setNivelLoc2ListColeta([])
        setNivelLoc3Coleta('*')
        setNivelLoc3ListColeta([])
        setNivelLoc4Coleta('*')
        setNivelLoc4ListColeta([])
        setParceiroColeta('')
        setEanColeta('')
        setObservacaoColeta('')
        setFoto(NoImg)
        setTagListItens([])
        setPedidoListItens([])
        setVinculadosList([])
        setExportList([])
        setExportListSelected([])
        setItList([])
        setDashItens([])
        setStatusList([])
        setOrdenationExport('')
        setListaFilterExport([
            'Ausente',
            'Encontrado',
            'Excedente',
            'Outro_Ambiente',
            'Auditado',
            'Incorporado'
        ])
    }

    function save() {
        if(identificadorColeta == ''){
            window.alert("Ops...\nInforme um identificador")
        }else{
            let reg = []
            reg.push({
                "id_registro": idRegistro,
                "id_conta": sessionStorage.getItem('idConta'),
                "modo": modoColeta,
                "id_tipo_registro": tipoRegistro,
                "identificador": identificadorColeta,
                "dt_registro": dtRegistro,
                "id_parceiro": parceiroColeta,
                "id_nivel_loc_1": nivelLoc1Coleta,
                "id_nivel_loc_2": nivelLoc2Coleta,
                "id_nivel_loc_3": nivelLoc3Coleta,
                "id_nivel_loc_4": nivelLoc4Coleta,
                "ean": eanColeta,
                "quantidade": qtdEstimada,
                "encontrado": encontradoColeta,
                "excedente": excedenteColeta,
                "foto": foto,
                "latitude": latitude,
                "longitude": longitude,
                "status": statusColeta,
                "dt_alteracao": dtAlteracao,
                "observacao": observacaoColeta
            })
    
            let option = {
                headers: { 'Content-Type': ['application/json'] }
            }
    
            api.post('/registro?tr=icd3vatgr@23!', reg, option
            ).then(
                response => {
                    registroGet()
                    window.alert("Registrado !\nColeta salva com sucesso")
                    cleaner()
                },
                response => {
                    window.alert(`Temos um problema...\n${response.data.error}`)
                }
            )
        }
    }

    function remove() {
        Swal.fire({
            title: "Atenção !",
            html: `Identidificador: ${identificadorColeta}<br/>Deseja realmente excluir esta Coleta ?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Sim",
            cancelButtonText: "Não"
        }).then(
            result => {
                if(result.isConfirmed) {
                    
                    let option = {
                        headers: { 'Content-Type': ['application/json'] }
                    }
            
                    api.delete(`/registro/${idRegistro}`, option
                    ).then(
                        response => {
                            registroGet()
                            Swal.fire({
                                title: "Sucesso !",
                                text: 'Coleta foi excluida de sua base.',
                                icon: 'success',
                                showConfirmButton: false,
                                timerProgressBar: true,
                                timer: '2500'
                            })
                            cleaner()
                        },
                        response => {
                            Swal.fire({
                                title: "Ops... !",
                                text: 'Algo deu errado, por favor tente novamente mais tarde',
                                icon: 'error',
                                showConfirmButton: false,
                                timerProgressBar: true,
                                timer: '2500'
                            })
                        }
                    )
                }
            }
        )
    }
    
    return (
        <>
            {changeFunction(change)}
        </>
    )
}

export default Collects