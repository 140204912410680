export default function Button(theme) {
    return {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontFamily: 'Poppins, sans-serif',
                    boxShadow: 'none',
                    '&:hover': {
                        boxShadow: 'none'
                    }
                },
                sizeLarge: {
                    height: '36px',
                },
                containedPrimary: {
                    '&:hover': {
                        color: theme.palette.primary.main,
                        border: `1px solid ${theme.palette.primary.main}`,
                        backgroundColor: 'transparent',
                    },
                },
                containedDark: {
                    backgroundColor: theme.palette.dark.main,
                    '&:hover': {
                        color: theme.palette.dark.main,
                        border: `1px solid ${theme.palette.dark.main}`,
                        backgroundColor: 'transparent',
                    },
                },
                gradient: {
                    backgroundImage: 'linear-gradient(to left, #044dbb, #0758cc, #0863dd, #066fee, #007bff)',
                    color: '#fff',
                    boxShadow: theme.shadows[2],
                    '&:hover': {
                        boxShadow: theme.shadows[2],
                        color: theme.palette.primary.contrastText,
                        background: theme.palette.primary.main,
                    },
                },

                // Botôes tipo Text //

                textPrimary:{
                    color: theme.palette.primary.main,
                    backgroundColor: theme.palette.primary.lighter,
                    '&:hover': {
                        backgroundColor: theme.palette.primary.light,
                    },
                },

                // Botôes tipo Outline //
                outlinedInherit: {
                    color: theme.palette.common.white,
                    borderColor: theme.palette.common.white,
                    '&:hover': {
                        color: theme.palette.common.black,
                        borderColor: theme.palette.common.white,
                    },
                },
                outlinedDark: {
                    borderColor: theme.palette.grey[300]
                }
            },
        },
    };
}
