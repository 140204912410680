import { useEffect, useState } from "react"

import { Button } from "react-bootstrap"

import CustomModal from "../../../../components/ui/CustomModal"

import { TbTruckDelivery } from "react-icons/tb"

import { t } from "i18next"

import { Checkbox, FormControlLabel, FormGroup, Grid, MenuItem, Select, Stack, Typography } from "@mui/material"

import api from "../../../../services/api"

import Swal from "sweetalert2"

export default function MovimentationButton({locations, idRegistro}){
    const [loading, setLoading] = useState(false)

    const {propsNivel1, propsNivel2, propsNivel3, propsNivel4} = locations

    const [showModal, setShowModal] = useState(false)

    const [tags, setTags] = useState()

    const [encontrados, setEncontrados] = useState(false)

    const [ausentes, setAusentes] = useState(false)

    const [outrosAmbientes, setOutrosAmbientes] = useState(false)

    const [auditado, setAuditado] = useState(false)

    const [incorporado, setIncorporado] = useState(false)

    const [nivel1, setNivel1] = useState(propsNivel1?._id || "")

    const [nivel2, setNivel2] = useState(propsNivel2?._id || "")

    const [nivel3, setNivel3] = useState(propsNivel3?._id || "")

    const [nivel4, setNivel4] = useState(propsNivel4?._id || "")

    const [nivel1List, setNivel1List] = useState([])

    const [nivel2List, setNivel2List] = useState([])

    const [nivel3List, setNivel3List] = useState([])

    const [nivel4List, setNivel4List] = useState([])

    useEffect(() => {
        nivel1Get()
    }, [])

    useEffect(() => {
        nivel2Get(nivel1)
    }, [nivel1])

    useEffect(() => {
        nivel3Get(nivel2)
    }, [nivel2])
    
    useEffect(() => {
        nivel4Get(nivel3)
    }, [nivel3])

    function nivel1Get() {
        api.get(`/nivel_loc1/${sessionStorage.getItem('idConta')}/*/*/*/*`, {}
        ).then(
            response => {
                setNivel1List(response.data)
            }
        )
    }

    function nivel2Get(nivel1) {
        if (nivel1 && nivel1 !== "*") {
            const idConta = sessionStorage.getItem('idConta');
            const nivel1Path = nivel1 || '*';
    
            api.get(`/nivel_loc2/${idConta}/${nivel1Path}/*/*/*/*`)
            .then(
                response => {
                    setNivel2List(response.data)
                    if(!response?.data.find(item => item._id === nivel2)){
                        setNivel2()
                        setNivel3()
                        setNivel4()
                    }
                }
            )
        } else {
            setNivel2List([]);
        }
    } 

    function nivel3Get(nivel2) {
        if (nivel2 && nivel2 !== "*") {
            const idConta = sessionStorage.getItem('idConta');
            const nivel2Path = nivel2 || '*';
    
            api.get(`/nivel_loc3/${idConta}/${nivel2Path}/*/*/*/*`)
                .then(response => {
                    setNivel3List(response.data);
                    if(!response?.data.find(item => item._id === nivel3)){
                        setNivel3()
                        setNivel4()
                    }
                })
                .catch(error => {
                    console.error("Error fetching data:", error);
                    // Optional: handle error state, show user feedback, etc.
                });
        } else {
            setNivel3List([]);
        }
    }
    
    function nivel4Get(nivel3) {
        if (nivel3 && nivel3 !== "*") {
            const idConta = sessionStorage.getItem('idConta');
            const nivel3Path = nivel3 || '*';
    
            api.get(`/nivel_loc4/${idConta}/${nivel3Path}/*/*/*/*`)
                .then(response => {
                    setNivel4List(response.data);
                })
        } else {
            setNivel4List([]);
        }
    }

    function getStatus(item){
        let status
        if(item.encontrado == 0 && item.excedente == 0) {
            status = "Ausente"
        }
        if(item.encontrado == 1 && item.excedente == 0) {
            status = "Encontrado"
        }
        if(item.excedente == 1) {
            status = "Excedente"
        }
        if(item.excedente == 1 && ((item.id_item != '0' && item.id_item != null) || item.descricao != "SEM DESCRICAO" && item.descricao != "SEM DESCRIÇÃO")) {
            status = "Outro_Ambiente"
        }
        if(item.auditado == 1) {
            status = "Auditado"
        }
        if(item.incorporado == 1) {
            status = 'Incorporado'
        }
        return status
    }

    async function getTags(){
        if(!idRegistro){
            return
        }
        setLoading(true)

        await api.get(`/registro_tag/*/${idRegistro}/0`).then(
            response =>{
                const data = response.data.filter(item => item.tag)
                const tagsData = data.map((item) => {
                    return {
                        ...item,
                        status: getStatus(item)
                    };
                });
                setTags(tagsData)
                handleUpload(tagsData)
            },
            error=>{
                Swal.fire({
                    title: "Ops... !",
                    text: 'Algo deu errado, por favor tente novamente mais tarde',
                    icon: 'error',
                    showConfirmButton: false,
                    timerProgressBar: true,
                    timer: '2500'
                })
            }
        ).finally(()=>{
            setLoading(false)
            setShowModal(false)
        })
    }

    async function handleUpload(tagList){
        setLoading(true)
        let option = {
            headers: { 
                'Content-Type': ['application/json']
            }
        }

        const statusFiltrados = [
            encontrados ? "Encontrado" : "",
            ausentes ? "Ausente" : "",
            outrosAmbientes ? "Outro_Ambiente" : "",
            auditado ? "Auditado" : "",
            incorporado ? "Incorporado" : ""
        ];

        const itensFiltrados = tagList.filter(item => statusFiltrados.includes(item.status));

        const rows = itensFiltrados.map((item, index) => ({
            ...item?.id_item,
            id_nivel_loc_1: nivel1?._id || '',
            id_nivel_loc_2: nivel2?._id || '',
            id_nivel_loc_3: nivel3?._id || '',
            id_nivel_loc_4: nivel4?._id || ''
        }));

        const itens = rows.filter(item=>item?._id)

        await api.post(`/itensDef/${sessionStorage.getItem('idConta')}`,[{itens: itens}], option).then(
            response =>{
                Swal.fire({
                    title: "Sucesso !",
                    text: 'Itens atualizados com sucesso',
                    icon: 'success',
                    showConfirmButton: false,
                    timerProgressBar: true,
                    timer: '2500'
                })
            },
            error=>{
                Swal.fire({
                    title: "Ops... !",
                    text: 'Algo deu errado, por favor tente novamente mais tarde',
                    icon: 'error',
                    showConfirmButton: false,
                    timerProgressBar: true,
                    timer: '2500'
                })
            }
        ).finally(()=>{
            setLoading(false)
            setShowModal(false)
        })
    }

    function handleMovimentation(){
        if(tags){
            handleUpload(tags)
        }else{
            getTags()
        }
        setShowModal(false)
    }

    return (
        <>
            <Button variant="light" className="me-2" onClick={e=> {setShowModal(!showModal)}}><TbTruckDelivery className="me-2"/>{t('actions.moviment')}</Button>

            {showModal ? 
                <CustomModal
                    title={t('actions.moviment') + ' ' + t('common.items')}
                    onClose={e=>{setShowModal(false)}}
                    onConfirm={e=>{handleMovimentation()}}
                >
                    <Stack spacing={3}>
                        <div>
                            <Typography>Esses itens serão alterados para a localização do inventario.</Typography>
                            <Typography>Deseja continuar ?</Typography>
                        </div>

                        <div>
                            <Grid item container spacing={1}>
                                <Grid item xs={12} md={6}>
                                    <FormGroup>
                                        <Typography>{t('common.level')} 1</Typography>
                                        <Select size="small" value={nivel1} onChange={e=>{setNivel1(e.target.value)}}>
                                            {nivel1List.map((nivel)=>(
                                                <MenuItem value={nivel._id}>{nivel.descricao}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormGroup>
                                        <Typography>{t('common.level')} 2</Typography>
                                        <Select size="small" value={nivel2} onChange={e=>{setNivel2(e.target.value)}}>
                                            {nivel2List.map((nivel)=>(
                                                <MenuItem value={nivel._id}>{nivel.descricao}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormGroup>
                                        <Typography>{t('common.level')} 3</Typography>
                                        <Select size="small" value={nivel3} onChange={e=>{setNivel3(e.target.value)}}>
                                            {nivel3List.map((nivel)=>(
                                                <MenuItem value={nivel._id}>{nivel.descricao}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormGroup>
                                        <Typography>{t('common.level')} 4</Typography>
                                        <Select size="small" value={nivel4} onChange={e=>{setNivel4(e.target.value)}}>
                                            {nivel4List.map((nivel)=>(
                                                <MenuItem value={nivel._id}>{nivel.descricao}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormGroup>
                                </Grid>
                            </Grid>
                        </div>

                        <div>
                            <Typography>Status que serão movimentados:</Typography>
                            <FormControlLabel control={<Checkbox checked={encontrados} onChange={e=>{setEncontrados(e.target.checked)}} />} label="Encontrados" />
                            <FormControlLabel control={<Checkbox checked={ausentes} onChange={e=>{setAusentes(e.target.checked)}} />} label="Ausentes" />
                            <FormControlLabel control={<Checkbox checked={outrosAmbientes} onChange={e=>{setOutrosAmbientes(e.target.checked)}} />} label="Outros Ambientes" />
                            <FormControlLabel control={<Checkbox checked={auditado} onChange={e=>{setAuditado(e.target.checked)}} />} label="Auditado" />
                            <FormControlLabel control={<Checkbox checked={incorporado} onChange={e=>{setIncorporado(e.target.checked)}} />} label="Incorporado" />
                        </div>
                    </Stack>
                </CustomModal>
            : ""}
        </>
    )
}