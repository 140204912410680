import { Button, Stack, Typography } from "@mui/material"

import ExportPDF from "../exportPDF"

import ExportCSV from "../exportCSV"

import { useNavigate } from "react-router-dom"

import { useLocation } from "react-router-dom";

import { t } from "i18next"

export default function Export(){
    const location = useLocation();

    const navigate = useNavigate()

    const { headers = [], list = [], module = "", type = "", redirectTo = "" } = location.state?.info || {};

    return (
        <>
            <Stack spacing={3}>
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant="h1">{t('actions.generate')} {type}</Typography>
                    <Button variant="outlined" color="dark" onClick={e => {navigate(redirectTo)}}>
                        {t('actions.exit')}
                    </Button>
                </Stack>
                {type === 'CSV' ? 
                    <ExportCSV headers={headers} listItens={list} modulo={module}/>
                : 
                    <ExportPDF headers={headers} listItens={list} modulo={module}/>
                }
            </Stack>
        </>
    )
}