import React, {useState, useEffect} from 'react'

import { useNavigate } from 'react-router-dom'

import { Container, Grid2, InputLabel, MenuItem, Paper, Select, Stack, Typography, Button, TextField, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material'

import { Icon } from '@iconify/react/dist/iconify.js'

import { t } from 'i18next'

import Dropdown from '../../../components/ui/Dropdown'

import api from '../../../services/api'

import Loading from '../../../components/ui/Loading'

export default function GroupList() {
    const navigate = useNavigate()

    const id_conta = sessionStorage.getItem('idConta')

    const [loading, setLoading] = useState(false)

    //Filtros
    const [filterDescricao, setFilterDescricao] = useState("")

    const [filterLeitor, setfilterLeitor] = useState("")

    //Lista
    const [groups, setGroups] = useState([])

    const [readers, setReaders] = useState([])

    useEffect(()=>{
        function getReaders() {
            setLoading(true)
            api.get(`/leitor/${id_conta}/*/*/*/*`
            ).then(
                response => {
                    const validReaders = response.data.filter(item=>item?._id)
                    setReaders(validReaders)
                }
            ).finally(
                () => {
                    setLoading(false)
                }
            )
        }

        getReaders()
    }, [])

    useEffect(()=>{
        getGroups()
    }, [])

    function getGroups() {
        setLoading(true)
        api.get(`/agrupamentoLeitor/*/${id_conta}/${filterDescricao || "*"}/${filterLeitor || "*"}`
        ).then(
            response => {
                const validGroups = response.data.filter(item=>item?._id)
                setGroups(validGroups)
            },
            error => {
            }
        ).finally(
            () => {
                setLoading(false)
            }
        )
    }

    function handleSelect(reader){
        navigate('/readers/groups/details/' + reader._id, { state: { reader } });
    }

    return (
        <>
            
            <Stack direction="row" justifyContent="end" spacing={1}>
                <Button variant="contained" color="dark" startIcon={<Icon icon='heroicons:plus-16-solid'/>} onClick={e=>{navigate('/readers/groups/details')}}>{t('actions.create')}</Button>
            </Stack>

            <Stack spacing={1} sx={{borderRadius: 1, p: 3, backgroundColor: "primary.lighter"}}>
                <Grid2 container spacing={3}>
                    <Grid2 item size={{xs: 12, md: 4}}>
                        <Typography>{t('common.name')}</Typography>
                        <TextField
                            size="small"
                            value={filterDescricao}
                            onChange={e=> {setFilterDescricao(e.target.value)}} 
                            fullWidth
                        />
                    </Grid2>
                    <Grid2 item size={{xs: 12, md: 4}}>
                        <Typography>Leitor</Typography>
                        <Select
                            size="small"
                            value={filterLeitor}
                            onChange={e=> {setfilterLeitor(e.target.value)}} 
                            fullWidth
                        >
                            <MenuItem key="0" value="">--</MenuItem>
                            {readers.map((item)=>(
                                <MenuItem key={item._id} value={item._id}>{item.nm_leitor}</MenuItem>
                            ))}
                        </Select>
                    </Grid2>
                </Grid2>
                <Grid2 container justifyContent="end">
                    <Button variant="contained" onClick={()=>{getGroups()}}>{t('actions.search')}</Button>
                </Grid2>
            </Stack>

            <Table>
                <TableHead>
                    <TableRow className="fw-bold text-uppercase">
                        <TableCell></TableCell>
                        <TableCell>{t('common.name')}</TableCell>
                        <TableCell>{t('common.readers')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {groups.map((reader, index) => (
                        <TableRow onClick={e=>{handleSelect(reader)}}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>{reader?.descricao}</TableCell>
                            <TableCell>{reader?.leitores?.length}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

            <Loading show={loading}/>
        </>
    )   
}