import React, { useState, useCallback } from 'react';

import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Typography, TextField, Stack, IconButton, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel } from '@mui/material';

import { styled } from '@mui/material/styles';
import { t } from 'i18next';
import { Icon } from '@iconify/react/dist/iconify.js';

const StyledDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
      minWidth: 400,
      borderRadius: 24
    },
    '& .MuiDialogContent-root': {
      padding: theme.spacing(3),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
}));

const AlertDialog = () => {
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState({});
    const [fields, setFields] = useState({}); // Para armazenar os valores dos campos

    const handleFieldChange = (name, value) => {
        setFields((prevFields) => ({ ...prevFields, [name]: value }));
    };

    const handleConfirm = () => {
        setOpen(false);
        if (options.onConfirm) {
            options.onConfirm(fields); // Envia os valores dos campos no callback
        }
    };
  
    const fire = useCallback((opts) => {
        setOptions(opts);
        // Inicializa os campos com os valores padrão (defaultValue)
        const initialFields = opts.fields 
            ? opts.fields.reduce((acc, field) => ({
                ...acc,
                [field.name]: field.defaultValue || '', // Usa defaultValue se disponível
              }), {}) 
            : {};
        setFields(initialFields);
        setOpen(true);
    }, []);
  
    const handleClose = () => {
        setOpen(false);
        if (options.onCancel) {
            options.onCancel();
        }
    };

    React.useEffect(() => {
        const handleEvent = (event) => {
            fire(event.detail);
        };

        window.addEventListener('alert-dialog-fire', handleEvent);

        return () => {
            window.removeEventListener('alert-dialog-fire', handleEvent);
        };
    }, [fire]);

    return (
        <StyledDialog open={open} onClose={handleClose}>
            {options.title && (
                <Stack direction="row" justifyContent="space-between">
                    <DialogTitle>{options.title}</DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <Icon icon="line-md:close" />
                    </IconButton>
                </Stack>
            )}
            <DialogContent dividers>
                {options.children && options.children}
                <Typography variant="subtitle">{options.message}</Typography>
                {options.fields && options.fields.map((field) => (
                    field.type === 'radio' ? (
                        <FormControl key={field.name} margin="normal" fullWidth>
                            <FormLabel>{field.label}</FormLabel>
                            <RadioGroup
                                row={field.row || false}
                                value={fields[field.name] || ''} // Usa defaultValue ou vazio
                                onChange={(e) => handleFieldChange(field.name, e.target.value)}
                            >
                                {field.options.map((option) => (
                                    <FormControlLabel
                                        key={option.value}
                                        value={option.value}
                                        control={<Radio />}
                                        label={option.label}
                                    />
                                ))}
                            </RadioGroup>
                        </FormControl>
                    ) : (
                        <FormControl key={field.name} margin="normal" fullWidth>
                            {field.formLabel && <FormLabel>{field.formLabel}</FormLabel>}
                            <TextField
                                label={field.label}
                                type={field.type || 'text'}
                                value={fields[field.name] || ''} // Usa defaultValue ou vazio
                                onChange={(e) => handleFieldChange(field.name, e.target.value)}
                                fullWidth
                                margin="normal"
                            />
                        </FormControl>
                    )
                ))}
            </DialogContent>
            <DialogActions>
                {options.cancelText && (
                    <Button variant="outlined" color="dark" onClick={handleClose}>
                        {options.cancelText}
                    </Button>
                )}
                {options.confirmText && (
                    <Button variant="contained" color="error" onClick={handleConfirm}>
                        {options.confirmText}
                    </Button>
                )}
            </DialogActions>
        </StyledDialog>
    );
};

// Expondo o método fire
AlertDialog.fire = (options) => {
    const event = new CustomEvent('alert-dialog-fire', { detail: options });
    window.dispatchEvent(event);
};

export default AlertDialog;
