
import { useEffect, useState } from "react"

import secureLocalStorage from "react-secure-storage"

import { t } from "i18next"

import api from "../../services/api"

import Alert from "../../components/ui/Alert"

import { Backdrop, Button, Checkbox, Chip, CircularProgress, FormControl, FormGroup, FormLabel, Grid, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, TextField, Typography } from "@mui/material"

//----------------------------------------------------------------

export default function ConfigItems({userData}){
    const [loading, setLoading] = useState(false)

    const [message, setMessage] = useState(false)

    const [data, setData] = useState([])

    const [prefixText, setPrefixText] = useState('')

    const [prefixType, setPrefixType] = useState('')

    const [template, setTemplate] = useState('')

    const [verify, setVerify] = useState('')

    const [position, setPosition] = useState('')

    const [length, setLength] = useState('')

    const [cutTag, setCutTag] = useState('')

    const [tokenRotas, setTokenRotas] = useState('')

    const [tokenERP, setTokenERP] = useState('')

    const [infCompl1, setInfCompl1] = useState('')

    const [infCompl2, setInfCompl2] = useState('')

    const [infCompl3, setInfCompl3] = useState('')

    const [infCompl4, setInfCompl4] = useState('')

    const [infCompl5, setInfCompl5] = useState('')

    const [infCompl6, setInfCompl6] = useState('')

    const [infCompl7, setInfCompl7] = useState('')

    const [infCompl8, setInfCompl8] = useState('')

    const [infCompl9, setInfCompl9] = useState('')

    const [infCompl10, setInfCompl10] = useState('')

    const [nivel1, setNivel1] = useState('')

    const [nivel2, setNivel2] = useState('')

    const [nivel3, setNivel3] = useState('')

    const [nivel4, setNivel4] = useState('')

    const [requiredFields, setRequiredFields] = useState([]);


    useEffect(()=>{
        getData()
    },[])

    function getData(){
        setData(userData)
        setPrefixType(userData?.prefix_type ?? '')
        setPrefixText(userData?.prefix_text ?? '')
        setTemplate(userData?.item_padrao)
        setVerify(userData?.item_verif_inicial)
        setPosition(userData?.item_posicao)
        setLength(userData?.item_comprimento)
        setCutTag(userData?.corte_tag ?? '0')
        setInfCompl1(userData?.info_compl_1)
        setInfCompl2(userData?.info_compl_2)
        setInfCompl3(userData?.info_compl_3)
        setInfCompl4(userData?.info_compl_4)
        setInfCompl5(userData?.info_compl_5)
        setInfCompl6(userData?.info_compl_6 ?? '')
        setInfCompl7(userData?.info_compl_7 ?? '')
        setInfCompl8(userData?.info_compl_8 ?? '')
        setInfCompl9(userData?.info_compl_9 ?? '')
        setInfCompl10(userData?.info_compl_10 ?? '')
        setRequiredFields(userData?.obrigatorio ? JSON?.parse(userData?.obrigatorio) : [])
        setTokenRotas(userData?.token_id_rotas)
        setTokenERP(userData?.token_erp)
        setNivel1(userData?.nivel_1)
        setNivel2(userData?.nivel_2)
        setNivel3(userData?.nivel_3)
        setNivel4(userData?.nivel_4)
    }

    function handleSave(){
        const update = { ...data }; // Clonando os dados existentes para evitar mutações indesejadas
        update.id_conta = sessionStorage.getItem('idConta');
        update.prefix_type = prefixType || "";
        update.prefix_text = prefixText || "";
        update.item_padrao = template || "";
        update.item_verif_inicial = verify || "";
        update.item_posicao = position || "";
        update.item_comprimento = length || "";
        update.corte_tag = cutTag || "";
        update.info_compl_1 = infCompl1 || "";
        update.info_compl_2 = infCompl2 || "";
        update.info_compl_3 = infCompl3 || "";
        update.info_compl_4 = infCompl4 || "";
        update.info_compl_5 = infCompl5 || "";
        update.info_compl_6 = infCompl6 || "";
        update.info_compl_7 = infCompl7 || "";
        update.info_compl_8 = infCompl8 || "";
        update.info_compl_9 = infCompl9 || "";
        update.info_compl_10 = infCompl10 || "";
        update.obrigatorio = JSON.stringify(requiredFields);
        update.token_id_rotas = tokenRotas || "";
        update.token_erp = tokenERP || "";
        update.nivel_1 = nivel1 || "";
        update.nivel_2 = nivel2 || "";
        update.nivel_3 = nivel3 || "";
        update.nivel_4 = nivel4 || "";
        
        let option = {
            headers: { 'Content-Type': ['application/json'] }
        }

        setLoading(true)

        api.post('/conta_config', [update], option
        ).then(
            response => {
                setLoading(false)
                setMessage({
                    "type": "success",
                    "message": t('messages.success_save'),
                    "title": t('messages.success')
                })
            },
            error => {
                setLoading(false)
                setMessage({
                    "type": "error",
                    "message": t('messages.errors.500'),
                    "title": t('messages.errors.error')
                })
            }
        )
    }

    const names = [
        'info_compl_1',
        'info_compl_2',
        'info_compl_3',
        'info_compl_4',
        'info_compl_5',
        'info_compl_6',
        'info_compl_7',
        'info_compl_8',
        'info_compl_9',
        'info_compl_10',
        'marca',
        'modelo',
        'parceiro',
        'status',
        'valor',
        'observacao',
        'nivel_1',
        'nivel_2',
        'nivel_3',
        'nivel_4',
        'latitude',
        'longitude'
    ];

    const MenuProps = {
        PaperProps: {
            style: {
            maxHeight: 48 * 4.5 + 8,
            width: 250,
            },
        },
    };

    const handleChangeRequiredFields = (event) => {
        const { target: { value } } = event;
        setRequiredFields(typeof value === 'string' ? value.split(',') : value);
    };

    function handleDeleteRequiredField(e){
        let rf = [...requiredFields];
    
        let index = rf.indexOf(e);

        if (index !== -1) {
            rf.splice(index, 1);
            setRequiredFields(rf);
        }
    };

    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <Typography variant="h2">{t('settings.config_items')}</Typography>

            <Typography variant="subtitle">{t('settings.config_items_subtitle')}</Typography>

            <Grid container spacing={3} xs={12} md={10} sx={{mt:1}}>
                <Grid item xs={12} md={4}>
                    <FormGroup>
                        <FormLabel sx={{wordWrap: 'none'}}>{t('settings.items.conv_prefix')}</FormLabel>
                        <Select size="small" value={prefixType} onChange={e=>{setPrefixType(e.target.value)}} fullWidth>
                            <MenuItem value="">Sem conversão</MenuItem>
                            <MenuItem value="hexa">Hexadecimal</MenuItem>
                        </Select>
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={4}>
                    <FormGroup>
                        <FormLabel>{t('settings.items.prefix')}</FormLabel>
                        <TextField size="small" value={prefixText} onChange={e=>{setPrefixText(e.target.value)}} fullWidth/>
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={4}>
                    <FormGroup>
                        <FormLabel>{t('settings.items.template')}</FormLabel>
                        <Select size="small" value={template} onChange={e=>{setTemplate(e.target.value)}} fullWidth>
                            <MenuItem value="0">Nenhuma</MenuItem>
                            <MenuItem value="manual">Manual</MenuItem>
                            <MenuItem value="gtin">GTIN</MenuItem>
                            <MenuItem value="sgtin">sGTIN</MenuItem>
                            <MenuItem value="one">InovaOne</MenuItem>
                            <MenuItem value="epc">EPC API</MenuItem>
                        </Select>
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={4}>
                    <FormGroup>
                        <FormLabel>{t('settings.items.verify')}</FormLabel>
                        <TextField size="small" value={verify} onChange={e=>{setVerify(e.target.value)}} fullWidth/>
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={4}>
                    <FormGroup>
                        <FormLabel>{t('settings.items.position')}</FormLabel>
                        <TextField size="small" value={position} onChange={e=>{setPosition(e.target.value)}} fullWidth/>
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={4}>
                    <FormGroup>
                        <FormLabel>{t('settings.items.length')}</FormLabel>
                        <TextField size="small" value={length} onChange={e=>{setLength(e.target.value)}} fullWidth/>
                    </FormGroup>
                </Grid>
                <Grid item xs={12}>
                    <FormGroup>
                        <FormLabel>{t('settings.items.tag_cut')}</FormLabel>
                        <TextField size="small" value={cutTag} onChange={e=>{setCutTag(e.target.value)}} fullWidth/>
                    </FormGroup>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h3">Integração api Rotas</Typography>
                    <Typography variant="subtitle">Integração sistema InovaOne com o sistema Rotas</Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormGroup>
                        <FormLabel>Token rotas</FormLabel>
                        <TextField size="small" value={tokenRotas} onChange={e=>{setTokenRotas(e.target.value)}} fullWidth/>
                    </FormGroup>
                </Grid>
                <Grid item xs={12}>
                    <FormGroup>
                        <FormLabel>Token ERP</FormLabel>
                        <TextField size="small" value={tokenERP} onChange={e=>{setTokenERP(e.target.value)}} fullWidth/>
                    </FormGroup>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h3">{t('common.info')}</Typography>
                    <Typography variant="subtitle">Altere o nome da exibição das informações complementares</Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                    <FormGroup>
                        <FormLabel>{t('common.info')} 1</FormLabel>
                        <TextField size="small" value={infCompl1} onChange={e=>{setInfCompl1(e.target.value)}} fullWidth/>
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={4}>
                    <FormGroup>
                        <FormLabel>{t('common.info')} 2</FormLabel>
                        <TextField size="small" value={infCompl2} onChange={e=>{setInfCompl2(e.target.value)}} fullWidth/>
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={4}>
                    <FormGroup>
                        <FormLabel>{t('common.info')} 3</FormLabel>
                        <TextField size="small" value={infCompl3} onChange={e=>{setInfCompl3(e.target.value)}} fullWidth/>
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={4}>
                    <FormGroup>
                        <FormLabel>{t('common.info')} 4</FormLabel>
                        <TextField size="small" value={infCompl4} onChange={e=>{setInfCompl4(e.target.value)}} fullWidth/>
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={4}>
                    <FormGroup>
                        <FormLabel>{t('common.info')} 5</FormLabel>
                        <TextField size="small" value={infCompl5} onChange={e=>{setInfCompl5(e.target.value)}} fullWidth/>
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={4}>
                    <FormGroup>
                        <FormLabel>{t('common.info')} 6</FormLabel>
                        <TextField size="small" value={infCompl6} onChange={e=>{setInfCompl6(e.target.value)}} fullWidth/>
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={4}>
                    <FormGroup>
                        <FormLabel>{t('common.info')} 7</FormLabel>
                        <TextField size="small" value={infCompl7} onChange={e=>{setInfCompl7(e.target.value)}} fullWidth/>
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={4}>
                    <FormGroup>
                        <FormLabel>{t('common.info')} 8</FormLabel>
                        <TextField size="small" value={infCompl8} onChange={e=>{setInfCompl8(e.target.value)}} fullWidth/>
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={4}>
                    <FormGroup>
                        <FormLabel>{t('common.info')} 9</FormLabel>
                        <TextField size="small" value={infCompl9} onChange={e=>{setInfCompl9(e.target.value)}} fullWidth/>
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={4}>
                    <FormGroup>
                        <FormLabel>{t('common.info')} 10</FormLabel>
                        <TextField size="small" value={infCompl10} onChange={e=>{setInfCompl10(e.target.value)}} fullWidth/>
                    </FormGroup>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h3">{t('common.levels')}</Typography>
                    <Typography variant="subtitle">Altere o nome da exibição das localizações</Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                    <FormGroup>
                        <FormLabel>{t('common.level')} 1</FormLabel>
                        <TextField size="small" value={nivel1} onChange={e=>{setNivel1(e.target.value)}} fullWidth/>
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={3}>
                    <FormGroup>
                        <FormLabel>{t('common.level')} 2</FormLabel>
                        <TextField size="small" value={nivel2} onChange={e=>{setNivel2(e.target.value)}} fullWidth/>
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={3}>
                    <FormGroup>
                        <FormLabel>{t('common.level')} 3</FormLabel>
                        <TextField size="small" value={nivel3} onChange={e=>{setNivel3(e.target.value)}} fullWidth/>
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={3}>
                    <FormGroup>
                        <FormLabel>{t('common.level')} 4</FormLabel>
                        <TextField size="small" value={nivel4} onChange={e=>{setNivel4(e.target.value)}} fullWidth/>
                    </FormGroup>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h3">{t('settings.items.required_fields')}</Typography>
                    <Typography variant="subtitle">Defina os campos obrigatórios</Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                    <FormGroup>
                        <FormLabel>{t('settings.items.required_fields')}</FormLabel>
                        <FormControl>
                            <Select
                                className="text-capitalize"
                                multiple
                                value={requiredFields}
                                onChange={handleChangeRequiredFields}
                                input={<OutlinedInput />}
                                renderValue={(selected) => selected.join(', ').replace(/_/g, ". ")}
                                MenuProps={MenuProps}
                            >
                                {names.map((name, index) => (
                                    <MenuItem key={name} value={name}>
                                        <Checkbox checked={requiredFields.indexOf(name) > -1} />
                                        <ListItemText className="text-capitalize" primary={name.replace(/_/g, ". ")} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </FormGroup>
                    {requiredFields.map((field)=>(
                        <Chip sx={{m: 1}} label={field} onDelete={e=>{handleDeleteRequiredField(field)}} />
                    ))}
                </Grid>
                <Grid item xs={12}>
                    <Button variant="contained" color="error" onClick={e=>{handleSave()}} sx={{borderRadius: 5}}>{t('actions.save')}</Button>
                </Grid>
            </Grid>

            {message ? 
                <Alert
                    type={message.type}
                    title={message.title}
                    message={message.message}
                    onClose={e=>{setMessage(e)}}
                />
            : ''}
        </>
    )
}