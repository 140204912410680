import { useEffect, useState } from "react";

import {useNavigate } from 'react-router-dom'

import Menu from "../../../components/Menu";

import { Box, Checkbox, Chip, FormControl, FormControlLabel, FormGroup, FormHelperText, Grid, InputLabel, ListItemText, ListSubheader, MenuItem, Paper, Select, Stack, Switch, TextField, Typography } from "@mui/material";

import api from "../../../services/api";

import { Button } from "react-bootstrap";

import secureLocalStorage from "react-secure-storage";

import { Icon } from "@iconify/react/dist/iconify.js";

import FormChecagem from '../CreateInventory/Forms/FormChecagem'

import FormTagPicking from "./Forms/FormTagPicking";

import FormAlmoxarifado from "./Forms/FormAlmoxarifado";

import InventoryModel from "./InventoryModel";

//----------------------------------------------------------------

export default function CreateInventory(){
    const [loading, setLoading] = useState()

    const history = useNavigate()

    const [mode, setMode] = useState('')

    const [readers, setReaders] = useState([])

    const [readerError, setReaderError] = useState(false)

    const [readerList, setReaderList] = useState([])

    const [uploadModel, setUploadModel] = useState([])

    const [config, setConfig] = useState('')

    const [groups, setGroups] = useState([])

    useEffect(() => {
        leitoresGet()
    },[])

    useEffect(() => {
        if(readerList.length){
            getGroups()
        }
    },[readerList])

    function leitoresGet() {
        api.get(`/leitor/${sessionStorage.getItem('idConta')}/*/*/*/*`
        ).then(
            response => {
                setReaderList(response.data)
            }
        )
    }

    function generateID() {
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        var id = '';
        for (var i = 0; i < 6; i++) {
          var randomIndex = Math.floor(Math.random() * characters.length);
          id += characters[randomIndex];
        }
        return id;
    }

    function handleStart(saveConfig){

        if(!readers.length){
            setReaderError(true)
            return
        }
        const id = generateID()

        let startConfig = {
            ...saveConfig,
            readers: readers,
            multiple: readers.length > 1,  
            _id: id,
            dt_inicio: new Date()
        }

        for (let key in startConfig) {
            if (!startConfig[key]) {
                delete startConfig[key];
            }
        }

        const running_inventories = secureLocalStorage.getItem('running_inventories') || []
        
        running_inventories.push(id)

        secureLocalStorage.setItem('running_inventories', running_inventories)

        secureLocalStorage.setItem(id, startConfig)

        history(`/collections/create/${mode}/${id}`)
    }

    function handleModelSave(saveConfig){
        if(!readers.length){
            setReaderError(true)
            return
        }

        const id = generateID()

        let startConfig = {
            ...saveConfig,
            mode,
            readers,
            multiple: readers.length > 1,
            _id: id,
        }

        for (let key in startConfig) {
            if (!startConfig[key]) {
                delete startConfig[key];
            }
        }

        setUploadModel(startConfig)
    }

    function changeModel(model){
        setLoading(true)
        setMode(model?.mode)
        setReaders(model.readers)
        setConfig(model)
        setLoading(false)
    }

    function getGroups() {
        setLoading(true)
        api.get(`/agrupamentoLeitor/*/${sessionStorage.getItem('idConta')}/*/*`
        ).then(
            response => {
                const readersLists = [...readerList]
                const validGroups = response.data.map(item => ({
                    descricao: item.descricao,
                    leitores: item.leitores.map((item, index) => ({
                        ...readersLists.find(l => l._id === item.id_leitor)
                    }))
                }));
                setGroups(validGroups)
            }
        ).finally(
            () => {
                setLoading(false)
            }
        )
    }

    useEffect(() => {
        leitoresGet()
    },[])

    function handleGroup(newLeitor){
        const newList = [...readers, ...newLeitor]
        setReaders(newList)
    }

    function handleReader(newLeitor) {
        let updatedReaders;
        const existingReader = readers.find(item => item._id === newLeitor._id);
    
        if (existingReader) {
            updatedReaders = readers.filter(item => item._id !== newLeitor._id);
        } else {
            updatedReaders = [...readers]
            updatedReaders.push(newLeitor)
        }
    
        setReaders(updatedReaders);
    }
    
    return (
        <>            
            <Stack component={Paper} className="shadow" sx={{m: 5, p: 3}} spacing={3}>

                <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
                    <div>
                        <Typography variant="h1">Preparar leitura</Typography>
                        <Typography variant="subtitle">Configure as opções para iniciar inventário</Typography>
                    </div>
                    <InventoryModel saveModel={uploadModel} onSave={e=>{setUploadModel()}} onSelectModel={(e)=>{changeModel(e)}}/>
                </Stack>

                <Grid item md={12}>
                    <FormControl fullWidth error={readerError}>
                        <Typography>Leitor</Typography>
                        <Select 
                            multiple 
                            size="small" 
                            value={readers} 
                            onChange={e=>{setReaders(e.target.value)}}
                            renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                    {selected.map((value) => (
                                        <Chip key={value} label={value.nm_leitor} />
                                    ))}
                                </Box>
                            )}
                        >
                            {groups.map((option)=>(
                                <>
                                    <ListSubheader onClick={e => {handleGroup(option.leitores)}}>{option.descricao}</ListSubheader>
                                    {option.leitores.map((groupreader)=>(
                                        <MenuItem key={groupreader.serial} value={groupreader} onClick={e => {handleReader(groupreader)}}>
                                            <Checkbox checked={readers.includes(groupreader)} />
                                            <ListItemText>{groupreader.nm_leitor}</ListItemText>
                                            <Typography variant="body2" color="text.secondary">
                                                {groupreader.serial}
                                            </Typography>
                                        </MenuItem>
                                    ))}
                                </>
                            ))}
                            {groups.length ? <ListSubheader> Outros leitores</ListSubheader> :""}
                            {readerList.map((option)=>(
                                <MenuItem key={option.serial} value={option}>
                                    <Checkbox checked={readers.includes(option)} />
                                    <ListItemText>{option.nm_leitor}</ListItemText>
                                    <Typography variant="body2" color="text.secondary">
                                        {option.serial}
                                    </Typography>
                                </MenuItem>
                            ))}
                        </Select>
                        {readerError ?<FormHelperText sx={{color: 'error.main'}}>Campo obrigatório</FormHelperText>:''}
                    </FormControl>
                </Grid>
                <Grid item md>
                    <FormControl fullWidth>
                        <Typography>Modo</Typography>
                        <Select size="small" value={mode} onChange={e=>{setMode(e.target.value)}}>
                            <MenuItem key="1" value="tag_picking">Tag Picking</MenuItem>
                            <MenuItem key="2" value="checagem">Checagem</MenuItem>
                            <MenuItem key="3" value="almoxarifado">Almoxarifado</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                {mode ? 
                    <>
                        {mode === 'tag_picking' ? 
                            <FormTagPicking reader={readers} onSave={e=>{handleStart(e)}} onCreateModel={e=>{handleModelSave(e)}} config={config}/>
                        : mode === 'checagem' ?
                            <FormChecagem reader={readers} onSave={e=>{handleStart(e)}} onCreateModel={e=>{handleModelSave(e)}} {...config}/>
                        : mode === 'almoxarifado' ?
                            <FormAlmoxarifado onSave={e=>{handleStart(e)}}/>
                        : ""}
                    </>
                : ''}
                
            </Stack>
        </>
    )
}