import { useEffect, useState } from "react";

import { Button, Checkbox, Container, FormControl, FormControlLabel, FormLabel, Grid2, IconButton, ListItemIcon, ListItemText, MenuItem, Paper, Radio, RadioGroup, Select, Stack, TextField, Typography } from "@mui/material";

import { Icon } from "@iconify/react/dist/iconify.js";

import { t } from "i18next";

import api from "../../services/api";

import Alert from "../ui/Alert";

const itemsField = [
    { label: 'Tag', value: 'tag' },
    { label: 'Categoria', value: 'id_categoria' },
    { label: 'EAN', value: 'ean' },
    { label: 'Nível Loc 1', value: 'id_nivel_loc_1' },
    { label: 'Nível Loc 2', value: 'id_nivel_loc_2' },
    { label: 'Nível Loc 3', value: 'id_nivel_loc_3' },
    { label: 'Nível Loc 4', value: 'id_nivel_loc_4' },
    { label: 'Informação Complementar 1', value: 'inf_compl_1' },
    { label: 'Informação Complementar 2', value: 'inf_compl_2' },
    { label: 'Informação Complementar 3', value: 'inf_compl_3' },
    { label: 'Informação Complementar 4', value: 'inf_compl_4' },
    { label: 'Informação Complementar 5', value: 'inf_compl_5' },
    { label: 'Informação Complementar 6', value: 'inf_compl_6' },
    { label: 'Informação Complementar 7', value: 'inf_compl_7' },
    { label: 'Informação Complementar 8', value: 'inf_compl_8' },
    { label: 'Informação Complementar 9', value: 'inf_compl_9' },
    { label: 'Informação Complementar 10', value: 'inf_compl_10' },
    { label: 'Valor', value: 'valor' },
    { label: 'Parceiro', value: 'id_parceiro' },
    { label: 'Observação', value: 'observacao' },
    { label: 'Ativo', value: 'ativo' },
    { label: 'Foto', value: 'foto' },
    { label: 'ID Externo', value: 'id_externo' },
    { label: 'Item Vinculado', value: 'id_item_vinculado' },
    { label: 'UP 1', value: 'up_1' },
    { label: 'UP 2', value: 'up_2' },
    { label: 'UP 3', value: 'up_3' },
    { label: 'UP 4', value: 'up_4' },
    { label: 'UP 5', value: 'up_5' }
]

const columns = [
    { 
        field: 'id', 
        headerName: 'ID', 
        width: 90
    },
    {
      field: 'id_categoria',
      headerName: 'Categoria',
      width: 200  
    },
    {
        field: 'ean',
        headerName: 'EAN',
        width: 300
    }
];

export default function ConfigImport({ format = "xml", module, onChangeConfig, optionsFields = itemsField, modelImport = [] , selectedModel, xmlFieldOptions }){
    const [modelo, setModelo] = useState('')

    const [configModels, setConfigModels] = useState([])

    const [loading, setLoading] = useState(false)

    const [message, setMessage] = useState()

    const [contaID, setContaID] = useState('')

    const [modelName, setModelName] = useState('')

    const [infoPosition, setInfoPosition] = useState('cell')

    const [header, setHeader] = useState(false)

    const [upcomingFields, setUpcomingFields]= useState([''])

    const [fixedField, setFixedField]= useState([])

    const [XMLFields, setXMLFields] = useState([])

    const [countField, setCountField] = useState(1)

    const [keyTag, setKeyTag] = useState()

    const [modelos, setModelos] = useState(modelImport || [])

    const [uploadOptions, setUploadOptions] = useState()

    useEffect(()=>{
        if(selectedModel){
            handleModelo(selectedModel)
        }
    },[])

    useEffect(()=>{
        if(header && xmlFieldOptions ){
            setUploadOptions(xmlFieldOptions)
        }
    },[header])

    useEffect(()=>{
        function getModels() {
            api.get(`/conta_config/${sessionStorage.getItem('idConta')}`).then(
                response => {
                    let data = response.data[0] ? response.data[0] : []
                    setContaID(data?._id)

                    const modules = data?.import?.[module]     
                    
                    setConfigModels(data?.import)
                    
                    if(modules){
                        setModelos((prevModelos) => [...prevModelos, ...modules]);
                        if(!selectedModel){
                            handleModelo(modules[0])
                        }
                    }                    
                }
            )
        }
        getModels()
    },  [])

    useEffect(()=>{
        onChangeConfig({
            infoPosition, 
            upcomingFields,
            XMLFields,
            countField, 
            keyTag,
            fixedField,
            header
        })
    },  [infoPosition, upcomingFields, XMLFields, header, countField, keyTag, fixedField])

    function handleChange(type, value, index) {
        if(type == 'upcomming'){
            setUpcomingFields(prevFields => {
                const newFields = [...prevFields];
                newFields[index] = value;
                return newFields;
            });
        }else{
            setXMLFields(prevFields => {
                const newFields = [...prevFields];
                newFields[index] = value;
                return newFields;
            });
        }
    }

    function handleModelo(value) {
        const {position = "cell", keyTag, fields, xmlUploadFields, name, count} = value
        setModelo(value)
        setInfoPosition(position || "cell")
        setHeader( value?.header || false)
        setKeyTag(keyTag || "")
        setUpcomingFields(fields || [])
        setXMLFields(xmlUploadFields || [])
        setCountField(count || fields?.length || 1)   
        setModelName(name || "")
    }

    function handleSave() {
        const saveModel = {
            position: infoPosition,
            keyTag,
            header: header,
            fields: upcomingFields,
            fixedField: fixedField,
            xmlUploadFields: XMLFields,
            name: modelName || "Modelo sem nome"
        };

        let reg = {
            _id: contaID,
            id_conta: sessionStorage.getItem('idConta'),
            import: configModels || [] // Default to an empty array if no models are provided
        };

        if (!Array.isArray(reg.import[module])) {
            reg.import[module] = [];
        }

        reg.import[module].push(saveModel);

        setModelos(reg.import[module])
        
        api.post(`/conta_config`, [reg]).then(
            response=> {
                setMessage({
                    "type": "success",
                    "message": t('messages.success_save'),
                    "title": t('messages.success')
                })
            },
            error =>{
                setMessage({
                    "type": "error",
                    "message": t('messages.errors.500'),
                    "title": t('messages.errors.error')
                })
            }
        ).finally(
            () => {
                setLoading(false)
                handleModelo(saveModel)
            }
        )
        
    }

    function handleDelete() {
        let reg = {
            _id: contaID,
            id_conta: sessionStorage.getItem('idConta'),
            import: configModels || [] // Default to an empty array if no models are provided
        };

        if (!Array.isArray(reg.import[module])) {
            reg.import[module] = [];
        }

        reg.import[module] = reg.import[module].filter(item => item._id !== modelo._id)

        setModelos(reg.import[module])
        
        api.post(`/conta_config`, [reg]).then(
            response=> {
                setMessage({
                    "type": "success",
                    "message": t('messages.success_save'),
                    "title": t('messages.success')
                })
            },
            error =>{
                setMessage({
                    "type": "error",
                    "message": t('messages.errors.500'),
                    "title": t('messages.errors.error')
                })
            }
        ).finally(
            () => {
                setLoading(false)
                handleModelo()
            }
        )
        
    }

    function handleDefault() {
        let reg = {
            _id: contaID,
            id_conta: sessionStorage.getItem('idConta'),
            import: configModels || [] // Default to an empty array if no models are provided
        };

        if (!Array.isArray(reg.import[module])) {
            reg.import[module] = [];
        }

        reg.import[module] = reg.import[module].filter(item => item._id !== modelo._id)

        reg.import[module].unshift(modelo)

        setModelos(reg.import[module])
        
        api.post(`/conta_config`, [reg]).then(
            response=> {
                setMessage({
                    "type": "success",
                    "message": t('messages.success_save'),
                    "title": t('messages.success')
                })
            },
            error =>{
                setMessage({
                    "type": "error",
                    "message": t('messages.errors.500'),
                    "title": t('messages.errors.error')
                })
            }
        ).finally(
            () => {
                setLoading(false)
                handleModelo()
            }
        )
        
    }

    return (
        <>
            <Stack spacing={3} sx={{p: 1}}>
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant="h3">Configurar Importação</Typography>
                    <Stack direction="row" justifyContent="end" spacing={1}>
                        {modelo ? 
                            <>
                                <Button 
                                    variant="outlined" 
                                    color="dark" 
                                    onClick={e=>{handleDelete()}}
                                >
                                    {t('actions.delete')} {t('settings.model')}
                                </Button>
                                <Button 
                                    variant="outlined" 
                                    color="dark" 
                                    onClick={e=>{handleDefault()}}
                                    startIcon={<Icon icon="material-symbols-light:kid-star-outline"/>}
                                >
                                    Tornar padrão
                                </Button>
                            </> 
                        : ''}
                        <Button 
                            variant="outlined" 
                            color="dark" 
                            onClick={e=>{handleSave()}}
                            
                        >
                            {t('actions.create')} {t('settings.model')}
                        </Button>
                    </Stack>
                </Stack>

                <FormControl>
                    <FormLabel>Modelo</FormLabel>
                    <Select size="small" value={modelo} onChange={e=>{handleModelo(e.target.value)}} renderValue={(selected) => selected?.name}>
                        <MenuItem key="0" value=''>--</MenuItem>
                        {modelos.map((model, index)=>(
                            <MenuItem key={index} value={model}>
                                {index === 0 && (
                                    <ListItemIcon>
                                        <Icon icon="material-symbols-light:kid-star-outline"/>
                                    </ListItemIcon>
                                )}
                                {model.name} 
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl>
                    <FormLabel>Nome do Modelo (opcional)</FormLabel>
                    <TextField size="small" value={modelName} onChange={e=>{setModelName(e.target.value)}} fullWidth/>
                </FormControl> 

                {format === 'csv' && (
                    <FormControl>
                        <FormLabel>Possui cabeçalho?</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={header}
                            onChange={e=>{setHeader(e.target.value)}}
                        >
                            <FormControlLabel value={true} control={<Radio />} label="Sim" />
                            <FormControlLabel value={false} control={<Radio />} label="Não" />
                        </RadioGroup>
                    </FormControl>
                )}

                {format === 'xml' && (
                    <>
                        <FormControl>
                            <FormLabel>Posição da Informação</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                value={infoPosition}
                                onChange={e=>{setInfoPosition(e.target.value)}}
                            >
                                <FormControlLabel value="cell" control={<Radio />} label="Célula" />
                                <FormControlLabel value="row" control={<Radio />} label="Linha" />
                            </RadioGroup>
                        </FormControl>

                        <FormControl>
                            <FormLabel>Tag {infoPosition === 'row' ? 'da Linha' : 'do Conjunto'}(opcional)</FormLabel>
                            <TextField size="small" value={keyTag} onChange={e=>{setKeyTag(e.target.value)}} sx={{minWidth: 300}}/>
                        </FormControl>
                    </>
                )}

                <Grid2 container spacing={2}>
                    <Grid2 item size={5}>
                        <FormLabel>InovaOne</FormLabel>
                    </Grid2>
                    <Grid2 item size={1}></Grid2>
                    <Grid2 item size={5}>
                        <FormLabel sx={{textTransform: "capitalize"}}>Campos</FormLabel>
                    </Grid2>
                    <Grid2 item size={1}>
                        <FormLabel>Texto fixo</FormLabel>
                    </Grid2>
                </Grid2>
                
                    {[...Array(Math.min(countField, optionsFields.length)).keys()].map((field, i)=>(
                        <Grid2 container spacing={2}>
                            <Grid2 item size={5}>
                                <Select fullWidth size="small" value={upcomingFields[i]} onChange={e=>{handleChange('upcomming', e.target.value, i)}} sx={{minWidth: 300}}>
                                    <MenuItem key="0" value="">--</MenuItem>
                                    {optionsFields.map((option)=>(
                                        <MenuItem key={option.value} value={option.value} disabled={upcomingFields.find(item=>item == option.value)}>{option.label}</MenuItem>
                                    ))}
                                </Select>
                            </Grid2>
                            <Grid2 item size={1}>
                                <Button sx={{width: '100%', height: '100%'}}>
                                    <Icon icon="radix-icons:pin-left"/>
                                </Button>
                            </Grid2>
                            <Grid2 item size={5}>
                                {uploadOptions && header ? 
                                    <>
                                        <Select fullWidth size="small" value={XMLFields[i]} onChange={e=>{handleChange('xmlField', e.target.value, i)}} sx={{minWidth: 300}}>
                                            <MenuItem key="0" value="">--</MenuItem>
                                            {uploadOptions.map((option)=>(
                                                <MenuItem key={option} value={option}>{option}</MenuItem>
                                            ))}
                                        </Select>
                                    </>
                                : 
                                    <TextField fullWidth size="small" value={XMLFields[i]} onChange={e=>{handleChange('xmlField', e.target.value, i)}} sx={{minWidth: 300}}/>
                                }
                            </Grid2>
                            <Grid2 item size={1}>
                                <Checkbox
                                    checked={fixedField[i]}
                                    onChange={(e) => {
                                        const updatedFixedField = [...fixedField]; // Create a shallow copy of the array
                                        updatedFixedField[i] = e.target.checked; // Update the specific index
                                        setFixedField(updatedFixedField); // Set the updated array in state
                                    }}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            </Grid2>
                        </Grid2>
                    ))}
                <Button sx={{pt: 3}} disabled={countField === optionsFields.length} fullWidth variant="contained" onClick={e=>{setCountField(countField + 1)}} startIcon={<Icon icon="mage:plus"/>}>
                    Campo
                </Button>                         
            </Stack>

            { message ? 
                <Alert
                    type={message.type}
                    title={message.title}
                    message={message.message}
                    onClose={e=>{setMessage(null)}}
                />
            : ''}
        </>
    )
}