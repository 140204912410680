import { useEffect, useState } from 'react';

import { Icon } from '@iconify/react';

import { Box, Checkbox, CircularProgress, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, Skeleton, Stack, Typography } from '@mui/material';

import { blue, cyan, green, grey, lightBlue, purple, red, yellow } from '@mui/material/colors';

import { t } from 'i18next';

import api from '../../../services/api';

import BarChart from '../../../components/ReCharts/BarChart';

//----------------------------------------------------------------

const options = [
    'Ausente',
    'Encontrado',
    'Excedente',
    'Outros Ambientes',
    'Auditado',
    'Incorporado'
]

const customOptionCBA = [
  'Ausente',
  'Encontrado',
  'Peso',
  'Excedente',
  'Outros Ambientes',
  'Auditado',
  'Incorporado'
]

const colors = {
  'Ausente': red[400],
  'Encontrado': green[400],
  'Excedente': purple[400],
  'Outros Ambientes':yellow[600],
  'Auditado': cyan[400],
  'Incorporado': lightBlue[600],
  'Peso': blue[500]
}

export default function CategoryBarChart({ IDRegistro }) {

  const [data, setData] = useState([]);

  const [filterBy, setFilterBy] = useState("id_categoria");

  const [itemsList, setItemsList] = useState([]);

  const [title, setTitle] = useState(t('common.categories'));

  const [selectedOptions, setSelectedOptions] = useState( sessionStorage.getItem('idConta') === 'Mrz2ue7F2' ? [customOptionCBA[0], customOptionCBA[1], customOptionCBA[2]] : [options[0], options[1]]);

  const [show, setShow] = useState(false);

  useEffect(() => {
    fetchData();
  }, [IDRegistro]);

  const fetchData = async () => {
    try {
      const response = await api.get(`/registro_tag/*/${IDRegistro}/0`);
      setItemsList(response.data);
      handleSelect("id_categoria", response.data);
      setShow(true)
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  async function handleSelect(filter, items = itemsList){
    setShow(false)
    const getTitle = (filter) => {
        if (filter.includes('categoria')) {
            return t('common.categories');
        } else {
            return `${t('common.levels')} ${filter.slice(-1)}`;
        }
    };

    const getDescricao = (item, filter) => {
        if (filter.includes('categoria')) {
            return item[filter]?.descricao ||  item?.descricao || 'Não Cadastrado';
        } else if (filter.includes('nivel')) {
            return item?.id_item?.[filter]?.descricao ?? 'Não Cadastrado';
        }
        return 'Não Cadastrado';
    };

    const updateDataset = (acc, descricao, item) => {
        if (!descricao) return acc;

        if (!acc[descricao]) {
            acc[descricao] = { name: descricao, count: 0 };
        }

        acc[descricao][item.status] = (acc[descricao][item.status] || 0) + 1;

        acc[descricao].count++;

        if (sessionStorage.getItem('idConta') === 'Mrz2ue7F2') {
          const peso = item?.id_item?.inf_compl_4?.match(/\d+/g)?.join('') || '0';
          const pesoNumber = Number(peso);
          if (!isNaN(pesoNumber)) {
            acc[descricao].Peso = (acc[descricao]?.Peso || 0) + pesoNumber;
          }
        }        

        return acc;
    };

    setTitle(getTitle(filter));
    setFilterBy(filter);

    const tagsData = items.map((item) => {
        return {
            ...item,
            status: getStatus(item)
        };
    });

    const dataset = tagsData.reduce((acc, item) => {
        const descricao = getDescricao(item, filter);
        return updateDataset(acc, descricao, item);
    }, {});

    const sortedData = Object.values(dataset).sort((a, b) => b.count - a.count);

    setData(sortedData);

    setShow(true)
};

  const sortSelectedOptions = (selected) => {
    return selected.sort((a, b) => options.indexOf(a) - options.indexOf(b));
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    
    const sortedOptions = sortSelectedOptions(
      typeof value === 'string' ? value.split(',') : value,
    );

    setSelectedOptions(sortedOptions);
  };

  function getStatus(item){
    let status
    if(item.encontrado == 0 && item.excedente == 0) {
        status = "Ausente"
    }
    if(item.encontrado == 1 && item.excedente == 0) {
        status = "Encontrado"
    }
    if(item.excedente == 1) {
        status = "Excedente"
    }
    if(item.excedente == 1 && ((item.id_item != '0' && item.id_item != null) || item.descricao != "SEM DESCRICAO" && item.descricao != "SEM DESCRIÇÃO")) {
        status = "Outro_Ambiente"
    }
    if(item.auditado == 1) {
        status = "Auditado"
    }
    if(item.incorporado == 1) {
        status = "Incorporado"
    }
    return status
  }

  return (
    <Stack sx={{ padding: 1, borderRadius: 3 }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" alignItems="center" spacing={1}>
          <Icon icon="solar:chart-square-broken" />
          <Typography variant="h6">{title}</Typography>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={1}>
          <FormControl>
            <InputLabel id="demo-multiple-checkbox-label">Filtro</InputLabel>
            <Select
                input={<OutlinedInput label="Filtro" />}
                value={filterBy}
                onChange={(e) => handleSelect(e.target.value)}
                size="small"
                sx={{borderRadius: 5}}
            >
              <MenuItem value="id_categoria">{t('common.categories')}</MenuItem>
              {[...Array(4)].map((_, index) => (
                  <MenuItem key={index} value={`nivel_loc_${index + 1}`}>
                  {`${t('common.levels')} ${index + 1}`}
                  </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{maxWidth: 300 }}>
            <InputLabel id="demo-multiple-checkbox-label">Status</InputLabel>
            <Select
              multiple
              value={selectedOptions}
              onChange={handleChange}
              input={<OutlinedInput label="Status" />}
              renderValue={(selected) => selected.join(', ')}
              size="small"
              sx={{borderRadius: 5}}
            >
              {options.map((name) => (
                <MenuItem key={name} value={name}>
                  <Checkbox checked={selectedOptions.indexOf(name) > -1} />
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>        
        </Stack>
      </Stack>
      <Stack sx={{ marginBlock: 3 }}>
        {data.length > 0 && show ? 
          <BarChart propsColors={colors} propsData={data} series={selectedOptions}/> 
          : 
          <Stack justifyContent="center" alignItems="center" sx={{padding: 5, color: 'primary.main'}}>
            <Icon icon="eos-icons:bubble-loading" width="64"/>
          </Stack>
        }
      </Stack>
    </Stack>
  );
}